<template>
    <div class="dashboard-page">
        <BasePageTitle :title="'Dashboard'" />
        <BasePanel>
            <template #content>
                <div class="dashboard-top">
                    <DashboardAccounts class="dashboard-accounts" />
                    <AccountInfoList class="account-info" />
                    <div class=success-rate>
                        <div class="title label__large">Success Rate</div>
                        <BasePieChart />
                    </div>
                </div>
            </template>
        </BasePanel>
        <BasePanel>
            <template #content>
                <BaseLineChart />
            </template>
        </BasePanel>
    </div>
</template>

<script>
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import AccountInfoList from "@/new-design/not-sorted/new/DashboardPage/AccountInfoList.vue"
import DashboardAccounts from "@/new-design/not-sorted/new/DashboardPage/DashboardAccounts.vue"
import BasePieChart from "@/new-design/shared/ui/charts/BasePieChart.vue"
import BaseLineChart from "@/new-design/shared/ui/charts/BaseLineChart.vue"

export default {
    name: "DashboardPage",
    components: { BasePageTitle, BasePanel, AccountInfoList, DashboardAccounts, BasePieChart, BaseLineChart },
}
</script>

<style scoped>
.panel {
    padding: 0;
    border: none !important;
    margin-bottom: 12px;
}

.dashboard-top {
    display: grid;
    grid-template-columns: 1fr 1fr 220px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 12px 16px;
}

.dashboard-accounts {
    grid-column: 3/4;
    grid-row: 1/4;
}

.account-info {
    grid-column: 1/3;
    grid-row: 1/2;
}

.success-rate {
    grid-column: 1/3;
    grid-row: 2/4;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
}

.success-rate .title {
    padding: 16px 12px;
    color: var(--System-OnSurfaceVariant);
}

@media(max-width: 1023px) {
    .dashboard-top {
        grid-template-columns: 1fr;
        grid-template-rows: auto
    }

    .dashboard-accounts,
    .account-info,
    .success-rate {
        grid-column: 1/3;
        grid-row: auto;
    }
}
</style>
