export const baseLightTheme = {
    "--Custom-GreenContainer": "#67B199",
    "--Custom-GreenFixed": "#15A98B",
    "--Custom-OnGreenContainer": "#003028",
    "--Custom-OnGreenFixed": "#85D5C5",
    "--Custom-OnRedContainer": "#550B15",
    "--Custom-OnRedFixed": "#FFEFEF",
    "--Custom-RedContainer": "#E68F9C",
    "--Custom-RedFixed": "#F14C59",
    "--Monohrome-Black": "#000000",
    "--Monohrome-White": "#FFFFFF",
    "--Statelayers-background-opacity08": "rgba(250,248,255,0.08)",
    "--Statelayers-background-opacity12": "rgba(250,248,255,0.12)",
    "--Statelayers-background-opacity16": "rgba(250,248,255,0.16)",
    "--Statelayers-error-opacity08": "rgba(186,26,26,0.08)",
    "--Statelayers-error-opacity12": "rgba(186,26,26,0.12)",
    "--Statelayers-error-opacity16": "rgba(186,26,26,0.16)",
    "--Statelayers-errorcontainer-opacity08": "rgba(255,218,214,0.08)",
    "--Statelayers-errorcontainer-opacity12": "rgba(255,218,214,0.12)",
    "--Statelayers-errorcontainer-opacity16": "rgba(255,218,214,0.16)",
    "--Statelayers-greencontainer-opacity08": "rgba(103,177,153,0.08)",
    "--Statelayers-greencontainer-opacity12": "rgba(103,177,153,0.12)",
    "--Statelayers-greencontainer-opacity16": "rgba(103,177,153,0.16)",
    "--Statelayers-greenfixed-opacity08": "rgba(21,169,139,0.08)",
    "--Statelayers-greenfixed-opacity12": "rgba(21,169,139,0.12)",
    "--Statelayers-greenfixed-opacity16": "rgba(21,169,139,0.16)",
    "--Statelayers-inverseonsurface-opacity08": "rgba(241,240,247,0.08)",
    "--Statelayers-inverseonsurface-opacity12": "rgba(241,240,247,0.12)",
    "--Statelayers-inverseonsurface-opacity16": "rgba(241,240,247,0.16)",
    "--Statelayers-inverseprimary-opacity08": "rgba(175,198,255,0.08)",
    "--Statelayers-inverseprimary-opacity12": "rgba(175,198,255,0.12)",
    "--Statelayers-inverseprimary-opacity16": "rgba(175,198,255,0.16)",
    "--Statelayers-inversesurface-opacity08": "rgba(47,48,54,0.08)",
    "--Statelayers-inversesurface-opacity12": "rgba(47,48,54,0.12)",
    "--Statelayers-inversesurface-opacity16": "rgba(47,48,54,0.16)",
    "--Statelayers-onbackground-opacity08": "rgba(26,27,32,0.08)",
    "--Statelayers-onbackground-opacity12": "rgba(26,27,32,0.12)",
    "--Statelayers-onbackground-opacity16": "rgba(26,27,32,0.16)",
    "--Statelayers-onerror-opacity08": "rgba(255,255,255,0.08)",
    "--Statelayers-onerror-opacity12": "rgba(255,255,255,0.12)",
    "--Statelayers-onerror-opacity16": "rgba(255,255,255,0.16)",
    "--Statelayers-onerrorcontainer-opacity08": "rgba(65,0,2,0.08)",
    "--Statelayers-onerrorcontainer-opacity12": "rgba(65,0,2,0.12)",
    "--Statelayers-onerrorcontainer-opacity16": "rgba(65,0,2,0.16)",
    "--Statelayers-ongreencontainer-opacity08": "rgba(0,48,40,0.08)",
    "--Statelayers-ongreencontainer-opacity12": "rgba(0,48,40,0.12)",
    "--Statelayers-ongreencontainer-opacity16": "rgba(0,48,40,0.16)",
    "--Statelayers-ongreenfixed-opacity08": "rgba(205,255,245,0.08)",
    "--Statelayers-ongreenfixed-opacity12": "rgba(205,255,245,0.12)",
    "--Statelayers-ongreenfixed-opacity16": "rgba(205,255,245,0.16)",
    "--Statelayers-onprimary-opacity08": "rgba(255,255,255,0.08)",
    "--Statelayers-onprimary-opacity12": "rgba(255,255,255,0.12)",
    "--Statelayers-onprimary-opacity16": "rgba(255,255,255,0.16)",
    "--Statelayers-onprimarycontainer-opacity08": "rgba(0,25,68,0.08)",
    "--Statelayers-onprimarycontainer-opacity12": "rgba(0,25,68,0.12)",
    "--Statelayers-onprimarycontainer-opacity16": "rgba(0,25,68,0.16)",
    "--Statelayers-onprimaryfixed-opacity08": "rgba(0,25,68,0.08)",
    "--Statelayers-onprimaryfixed-opacity12": "rgba(0,25,68,0.12)",
    "--Statelayers-onprimaryfixed-opacity16": "rgba(0,25,68,0.16)",
    "--Statelayers-onprimaryfixedvariant-opacity08": "rgba(45,69,120,0.08)",
    "--Statelayers-onprimaryfixedvariant-opacity12": "rgba(45,69,120,0.12)",
    "--Statelayers-onprimaryfixedvariant-opacity16": "rgba(45,69,120,0.16)",
    "--Statelayers-onredcontainer-opacity08": "rgba(85,11,21,0.08)",
    "--Statelayers-onredcontainer-opacity12": "rgba(85,11,21,0.12)",
    "--Statelayers-onredcontainer-opacity16": "rgba(85,11,21,0.16)",
    "--Statelayers-onredfixed-opacity08": "rgba(255,239,239,0.08)",
    "--Statelayers-onredfixed-opacity12": "rgba(255,239,239,0.12)",
    "--Statelayers-onredfixed-opacity16": "rgba(255,239,239,0.16)",
    "--Statelayers-onsecondary-opacity08": "rgba(173,176,182,0.08)",
    "--Statelayers-onsecondary-opacity12": "rgba(173,176,182,0.12)",
    "--Statelayers-onsecondary-opacity16": "rgba(173,176,182,0.16)",
    "--Statelayers-onsecondarycontainer-opacity08": "rgba(20,27,44,0.08)",
    "--Statelayers-onsecondarycontainer-opacity12": "rgba(20,27,44,0.12)",
    "--Statelayers-onsecondarycontainer-opacity16": "rgba(20,27,44,0.16)",
    "--Statelayers-onsecondaryfixed-opacity08": "rgba(20,27,44,0.08)",
    "--Statelayers-onsecondaryfixed-opacity12": "rgba(20,27,44,0.12)",
    "--Statelayers-onsecondaryfixed-opacity16": "rgba(20,27,44,0.16)",
    "--Statelayers-onsecondaryfixedvariant-opacity08": "rgba(64,70,89,0.08)",
    "--Statelayers-onsecondaryfixedvariant-opacity12": "rgba(64,70,89,0.12)",
    "--Statelayers-onsecondaryfixedvariant-opacity16": "rgba(64,70,89,0.16)",
    "--Statelayers-onsurface-opacity08": "rgba(26,27,33,0.08)",
    "--Statelayers-onsurface-opacity12": "rgba(26,27,33,0.12)",
    "--Statelayers-onsurface-opacity16": "rgba(26,27,33,0.16)",
    "--Statelayers-onsurfacevariant-opacity08": "rgba(93,98,109,0.08)",
    "--Statelayers-onsurfacevariant-opacity12": "rgba(93,98,109,0.12)",
    "--Statelayers-onsurfacevariant-opacity16": "rgba(93,98,109,0.16)",
    "--Statelayers-ontertiary-opacity08": "rgba(255,255,255,0.08)",
    "--Statelayers-ontertiary-opacity12": "rgba(255,255,255,0.12)",
    "--Statelayers-ontertiary-opacity16": "rgba(255,255,255,0.16)",
    "--Statelayers-ontertiarycontainer-opacity08": "rgba(0,33,24,0.08)",
    "--Statelayers-ontertiarycontainer-opacity12": "rgba(0,33,24,0.12)",
    "--Statelayers-ontertiarycontainer-opacity16": "rgba(0,33,24,0.16)",
    "--Statelayers-ontertiaryfixed-opacity08": "rgba(0,33,16,0.08)",
    "--Statelayers-ontertiaryfixed-opacity12": "rgba(0,33,16,0.12)",
    "--Statelayers-ontertiaryfixed-opacity16": "rgba(0,33,16,0.16)",
    "--Statelayers-ontertiaryfixedvariant-opacity08": "rgba(9,81,49,0.08)",
    "--Statelayers-ontertiaryfixedvariant-opacity12": "rgba(9,81,49,0.12)",
    "--Statelayers-ontertiaryfixedvariant-opacity16": "rgba(9,81,49,0.16)",
    "--Statelayers-outline-opacity08": "rgba(117,119,128,0.08)",
    "--Statelayers-outline-opacity12": "rgba(117,119,128,0.12)",
    "--Statelayers-outline-opacity16": "rgba(117,119,128,0.16)",
    "--Statelayers-outlinevariant-opacity08": "rgba(179,186,199,0.08)",
    "--Statelayers-outlinevariant-opacity12": "rgba(179,186,199,0.12)",
    "--Statelayers-outlinevariant-opacity16": "rgba(179,186,199,0.16)",
    "--Statelayers-primary-opacity08": "rgba(64,86,198,0.08)",
    "--Statelayers-primary-opacity12": "rgba(64,86,198,0.12)",
    "--Statelayers-primary-opacity16": "rgba(64,86,198,0.16)",
    "--Statelayers-primarycontainer-opacity08": "rgba(204,216,255,0.08)",
    "--Statelayers-primarycontainer-opacity12": "rgba(204,216,255,0.12)",
    "--Statelayers-primarycontainer-opacity16": "rgba(204,216,255,0.16)",
    "--Statelayers-primaryfixed-opacity08": "rgba(217,226,255,0.08)",
    "--Statelayers-primaryfixed-opacity12": "rgba(217,226,255,0.12)",
    "--Statelayers-primaryfixed-opacity16": "rgba(217,226,255,0.16)",
    "--Statelayers-primaryfixeddim-opacity08": "rgba(175,198,255,0.08)",
    "--Statelayers-primaryfixeddim-opacity12": "rgba(175,198,255,0.12)",
    "--Statelayers-primaryfixeddim-opacity16": "rgba(175,198,255,0.16)",
    "--Statelayers-redcontainer-opacity08": "rgba(230,143,156,0.08)",
    "--Statelayers-redcontainer-opacity12": "rgba(230,143,156,0.12)",
    "--Statelayers-redcontainer-opacity16": "rgba(230,143,156,0.16)",
    "--Statelayers-redfixed-opacity08": "rgba(241,76,89,0.08)",
    "--Statelayers-redfixed-opacity12": "rgba(241,76,89,0.12)",
    "--Statelayers-redfixed-opacity16": "rgba(241,76,89,0.16)",
    "--Statelayers-scrim-opacity08": "rgba(0,0,0,0.08)",
    "--Statelayers-scrim-opacity12": "rgba(0,0,0,0.12)",
    "--Statelayers-scrim-opacity16": "rgba(0,0,0,0.16)",
    "--Statelayers-secondary-opacity08": "rgba(86,95,120,0.08)",
    "--Statelayers-secondary-opacity12": "rgba(86,95,120,0.12)",
    "--Statelayers-secondary-opacity16": "rgba(86,95,120,0.16)",
    "--Statelayers-secondarycontainer-opacity08": "rgba(204,212,240,0.08)",
    "--Statelayers-secondarycontainer-opacity12": "rgba(204,212,240,0.12)",
    "--Statelayers-secondarycontainer-opacity16": "rgba(204,212,240,0.16)",
    "--Statelayers-secondaryfixed-opacity08": "rgba(219,226,249,0.08)",
    "--Statelayers-secondaryfixed-opacity12": "rgba(219,226,249,0.12)",
    "--Statelayers-secondaryfixed-opacity16": "rgba(219,226,249,0.16)",
    "--Statelayers-secondaryfixeddim-opacity08": "rgba(191,198,220,0.08)",
    "--Statelayers-secondaryfixeddim-opacity12": "rgba(191,198,220,0.12)",
    "--Statelayers-secondaryfixeddim-opacity16": "rgba(191,198,220,0.16)",
    "--Statelayers-shadow-opacity08": "rgba(0,0,0,0.08)",
    "--Statelayers-shadow-opacity12": "rgba(0,0,0,0.12)",
    "--Statelayers-shadow-opacity16": "rgba(0,0,0,0.16)",
    "--Statelayers-surface-opacity08": "rgba(232,238,250,0.08)",
    "--Statelayers-surface-opacity12": "rgba(232,238,250,0.12)",
    "--Statelayers-surface-opacity16": "rgba(232,238,250,0.16)",
    "--Statelayers-surface1-opacity08": "rgba(223,231,245,0.08)",
    "--Statelayers-surface1-opacity12": "rgba(223,231,245,0.12)",
    "--Statelayers-surface1-opacity16": "rgba(223,231,245,0.16)",
    "--Statelayers-surface2-opacity08": "rgba(218,227,245,0.08)",
    "--Statelayers-surface2-opacity12": "rgba(218,227,245,0.12)",
    "--Statelayers-surface2-opacity16": "rgba(218,227,245,0.16)",
    "--Statelayers-surface3-opacity08": "rgba(213,222,240,0.08)",
    "--Statelayers-surface3-opacity12": "rgba(213,222,240,0.12)",
    "--Statelayers-surface3-opacity16": "rgba(213,222,240,0.16)",
    "--Statelayers-surface4-opacity08": "rgba(206,217,237,0.08)",
    "--Statelayers-surface4-opacity12": "rgba(206,217,237,0.12)",
    "--Statelayers-surface4-opacity16": "rgba(206,217,237,0.16)",
    "--Statelayers-surface5-opacity08": "rgba(202,213,235,0.08)",
    "--Statelayers-surface5-opacity12": "rgba(202,213,235,0.12)",
    "--Statelayers-surface5-opacity16": "rgba(202,213,235,0.16)",
    "--Statelayers-surfacebright-opacity08": "rgba(250,248,255,0.08)",
    "--Statelayers-surfacebright-opacity12": "rgba(250,248,255,0.12)",
    "--Statelayers-surfacebright-opacity16": "rgba(250,248,255,0.16)",
    "--Statelayers-surfacedim-opacity08": "rgba(218,217,224,0.08)",
    "--Statelayers-surfacedim-opacity12": "rgba(218,217,224,0.12)",
    "--Statelayers-surfacedim-opacity16": "rgba(218,217,224,0.16)",
    "--Statelayers-surfacetint-opacity08": "rgba(52,91,179,0.08)",
    "--Statelayers-surfacetint-opacity12": "rgba(52,91,179,0.12)",
    "--Statelayers-surfacetint-opacity16": "rgba(52,91,179,0.16)",
    "--Statelayers-surfacevariant-opacity08": "rgba(225,226,236,0.08)",
    "--Statelayers-surfacevariant-opacity12": "rgba(225,226,236,0.12)",
    "--Statelayers-surfacevariant-opacity16": "rgba(225,226,236,0.16)",
    "--Statelayers-tertiary-opacity08": "rgba(42,106,100,0.08)",
    "--Statelayers-tertiary-opacity12": "rgba(42,106,100,0.12)",
    "--Statelayers-tertiary-opacity16": "rgba(42,106,100,0.16)",
    "--Statelayers-tertiarycontainer-opacity08": "rgba(151,223,204,0.08)",
    "--Statelayers-tertiarycontainer-opacity12": "rgba(151,223,204,0.12)",
    "--Statelayers-tertiarycontainer-opacity16": "rgba(151,223,204,0.16)",
    "--Statelayers-tertiaryfixed-opacity08": "rgba(174,242,197,0.08)",
    "--Statelayers-tertiaryfixed-opacity12": "rgba(174,242,197,0.12)",
    "--Statelayers-tertiaryfixed-opacity16": "rgba(174,242,197,0.16)",
    "--Statelayers-tertiaryfixeddim-opacity08": "rgba(147,213,170,0.08)",
    "--Statelayers-tertiaryfixeddim-opacity12": "rgba(147,213,170,0.12)",
    "--Statelayers-tertiaryfixeddim-opacity16": "rgba(147,213,170,0.16)",
    "--System-Background": "#FAF8FF",
    "--System-Error": "#BA1A1A",
    "--System-ErrorContainer": "#FFDAD6",
    "--System-InverseOnSurface": "#F1F0F7",
    "--System-InversePrimary": "#AFC6FF",
    "--System-InverseSurface": "#2F3036",
    "--System-OnBackground": "#1A1B20",
    "--System-OnError": "#FFFFFF",
    "--System-OnErrorContainer": "#410002",
    "--System-OnPrimary": "#FFFFFF",
    "--System-OnPrimaryContainer": "#001944",
    "--System-OnPrimaryFixed": "#001944",
    "--System-OnPrimaryFixedVariant": "#2D4578",
    "--System-OnSecondary": "#ADB0B6",
    "--System-OnSecondaryContainer": "#141B2C",
    "--System-OnSecondaryFixed": "#141B2C",
    "--System-OnSecondaryFixedVariant": "#404659",
    "--System-OnSurface": "#1A1B21",
    "--System-OnSurfaceVariant": "#5D626D",
    "--System-OnTertiary": "#FFFFFF",
    "--System-OnTertiaryContainer": "#002118",
    "--System-OnTertiaryFixed": "#002110",
    "--System-OnTertiaryFixedVariant": "#095131",
    "--System-Outline": "#757780",
    "--System-OutlineVariant": "#B3BAC7",
    "--System-Primary": "#4056C6",
    "--System-PrimaryContainer": "#CCD8FF",
    "--System-PrimaryFixed": "#D9E2FF",
    "--System-PrimaryFixedDim": "#AFC6FF",
    "--System-Scrim": "#000000",
    "--System-Secondary": "#565F78",
    "--System-SecondaryContainer": "#CCD4F0",
    "--System-SecondaryFixed": "#DBE2F9",
    "--System-SecondaryFixedDim": "#BFC6DC",
    "--System-Shadow": "#000000",
    "--System-Surface": "#E8EEFA",
    "--System-Surface1": "#DFE7F5",
    "--System-Surface2": "#DAE3F5",
    "--System-Surface3": "#D5DEF0",
    "--System-Surface4": "#CED9ED",
    "--System-Surface5": "#CAD5EB",
    "--System-SurfaceBright": "#FAF8FF",
    "--System-SurfaceDim": "#DAD9E0",
    "--System-SurfaceTint": "#4056C6",
    "--System-SurfaceVariant": "#E1E2EC",
    "--System-Tertiary": "#2A6A64",
    "--System-TertiaryContainer": "#97DFCC",
    "--System-TertiaryFixed": "#AEF2C5",
    "--System-TertiaryFixedDim": "#93D5AA",
}
