<template>
    <div class="panel" :class="{border: border}">
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    name: "BasePanel",
    props: {
        border: {
            type: Boolean,
            default: true
        }
    }
}
</script>


<style scoped>
.panel {
    padding: 24px;
    border-radius: var(--Corner-small);
    background: var(--System-Surface);
}

.panel.border {
    border: 1px solid var(--System-OutlineVariant);
}
</style>
