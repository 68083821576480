<template>
    <div class="chips-list">
        <h5 v-if="$slots['title']" class="title body__medium">
            <slot name="title"/>
        </h5>
        <div class="list">
            <MessageChipsListItem
                v-for="(message, index) in messages"
                :key="index"
                :message="message"
                @click.native="sendMessage(message)"
            />
        </div>
    </div>
</template>

<script>
import MessageChipsListItem from "@/new-design/entities/LiveChat/ui/MessageChipsListItem.vue"

export default {
    name: "MessageChipsList",
    components: {MessageChipsListItem},
    props: {
        messages: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        sendMessage (message) {

            this.$emit('send-message', message)
        }
    }
}
</script>

<style scoped>
.chips-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0;
}

.title {
    color: var(--System-Outline);
}

.list {
    display: flex;
    gap: 8px;
}
</style>
