<template>
    <div class="content scroll">
        <NewsListItem
            v-for="news in newsFeed"
            :key="news.id"
            :news="news"
        />
    </div>
</template>

<script>
import NewsListItem from "@/new-design/entities/MarketNews/ui/NewsListItem.vue"
import {mapGetters} from "vuex"

export default {
    mounted() {
        setInterval(() => {
            if (!this.$refs['news-scroll']) {
                return
            }

            if (this.$refs['news-scroll'].scrollTop + this.$refs['news-scroll'].clientHeight === this.$refs['news-scroll'].scrollHeight && this.modalMenu.news && this.activeAccount.news.length > this.newsPage * 20) {
                this.newsPage++
            }
        }, 1000)
    },
    name: "NewsList",
    components: {NewsListItem},
    data() {
        return {
            newsPage: 1
        }
    },
    computed: {
        ...mapGetters(['modalMenu', 'tokens', 'feed']),
        newsFeed() {
            let feed = []

            for (let n in this.feed.slice(0, this.newsPage * 20)) {
                feed.push(this.feed[n])
            }

            feed = feed.map((i) => {
                if (i.summary.includes('img')) {
                    i.summary = i.summary.replace("img", 'img style="max-width: 340px; max-height: 230px"')
                }
                return i
            })

            return feed
        }
    }
}
</script>

<style scoped>
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
</style>
