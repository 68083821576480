<template>
    <div class="widget">
        <div class="title">{{ tokens.widgetOrders.tradingHistory }} <i class="material-icons close"
                                                                       v-on:click="hideModal('history')">close</i></div>
        <div class="content scroll" ref="history-scroll">
            <div class="no-orders" :class="{hide: history.length}">
                <i class="material-icons">restore</i>
                <span>{{ tokens.widgetOrders.noHistoryLine1 }}<br/>{{ tokens.widgetOrders.noHistoryLine2 }}</span>
            </div>
            <div class="col">
                <div class="history-item"
                     v-for="order in history"
                     :key="order.id"
                     :class="{
                         active: detailsOrder === order.id,
                     }"
                >
                    <div class="history-head">
                        <div class="row">
                            <div class="col">
                                <div class="time">{{ order.timeStr }}</div>
                                <div class="lesser">{{ tokens.months[order.month] + ' ' + order.day }}</div>
                                <div class="row pointer"
                                     v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    {{ detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less }} <i
                                    class="material-icons">{{ detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row symbol-row" @click="changeSymbol(order.symbol)">
                                    <!--                                <img :src="staticBase+symbol.symbol.toUpperCase()+'.png'" /> -->
                                    <div class="symbol"
                                         :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''"/>
                                    <span>{{ symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol  }}</span>
                                </div>
                                <div class="lesser">{{ symbols[order.symbol] ? symbols[order.symbol].market.charAt(0).toUpperCase() + symbols[order.symbol].market.slice(1) : ''  }}
                                </div>
                                <div class="lesser" :class="{hide: !order.price_close}">
                                    {{
                                        symbols[order.symbol] && order.price_open ?
                                            order?.price_open?.toLocaleString(undefined, {
                                                minimumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5,
                                                maximumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5
                                            }) : '-'
                                    }}
                                    →
                                    {{
                                        order?.price_close?.toLocaleString(undefined, {
                                            minimumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5,
                                            maximumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5
                                        })
                                    }}
                                </div>

                            </div>
                        </div>
                        <div class="col">
                            <div class="profit" :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">{{ order.price_close ? ((order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toLocaleString(undefined, {minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}))  : tokens.widgetOrders.cancelled.toUpperCase() }}
                            </div>
                            <div class="lesser">
                                {{ order && order?.volume !== undefined && order?.volume !== null ?
                                (order?.volume > 1000 ? order?.volume.toFixed(0) : order?.volume.toFixed(2)) + ' ' + order?.symbol : '-' }}
                            </div>
                        </div>

                    </div>
                    <div class="details" :class="{hide: detailsOrder !== order.id}">
                        <div class="item">
                            <span>{{ tokens.widgetOrders.purchaseTime }}</span>
                            <span>{{ new Date(order.time_open * 1000).toLocaleString() }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.ordersPane.closeTime }}</span>
                            <span>{{ new Date(order.time_close * 1000).toLocaleString() }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceOpen }}</span>
                            <span>
                                {{
                                    order.price_open
                                        ? symbols[order.symbol] && symbols[order.symbol].precision
                                            ? order.price_open.toLocaleString(undefined, {
                                                minimumFractionDigits: symbols[order.symbol].precision,
                                                maximumFractionDigits: symbols[order.symbol].precision
                                            })
                                            : order.price_open.toLocaleString()
                                        : '-'
                                }}
                            </span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceSl }}</span>
                            <div class="row">
                                <span>{{ order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-' }}</span>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceTp }}</span>
                            <div class="row">
                                <span>{{ order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-' }}</span>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.orderType }}</span>
                            <span :class="order.type % 2 ? 'red' : 'green'">
                                    {{ tokens.orderTypes[order.type] }} <i class="material-icons">{{order.type % 2 ? 'arrow_drop_down' : 'arrow_drop_up'}}</i>
                                </span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.volume }}</span>
                            <span>{{ order.volume }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.margin }}</span>
                            <span>{{ order.easy_amount ?  currencySign(activeAccount.currency) + order.easy_amount.toFixed(activeAccount.precision) : '-' }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.pnl }}</span>
                            <span>{{ (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount.precision) }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.commission }}</span>
                            <span>{{ (order.commission > 0 ? '+' : (0 > order.commission ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.commission).toFixed(activeAccount.precision)  }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_MENU, SYMBOL_ACTIVE_UPDATE} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'

export default {
    name: 'OrdersHistory',
    mounted() {
        setInterval(() => {
            if (!this.$refs['history-scroll']) {
                return
            }

            if (this.$refs['history-scroll'].scrollTop + this.$refs['history-scroll'].clientHeight === this.$refs['history-scroll'].scrollHeight && this.modalMenu.history && this.activeAccount.history.length > this.historyPage * 20) {
                this.historyPage++
            }
        }, 1000)
    },
    data() {
        return {
            historyPage: 1,
            detailsOrder: 0
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'tokens', 'chartActive', 'activeAccount', 'modalMenu', 'staticBase']),
        history() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const history = []

            if (!this.activeAccount.history) {
                return history
            }

            const startIndex = 0
            const endIndex = this.historyPage * 20
            const slicedHistory = this.activeAccount.history.slice(startIndex, endIndex)

            slicedHistory.forEach((order) => {
                const d = new Date(order.time_close * 1000)

                order.timeStr = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                order.dayStr = monthNames[d.getMonth()] + ' ' + ('0' + d.getDate()).slice(-2)
                order.month = monthNames[d.getMonth()]
                order.day = ('0' + d.getDate()).slice(-2)

                order.amount = order.easy_amount || order.margin

                history.push(order)
            })

            return history
        },
    },
    watch: {
        activeAccount() {
            this.historyPage = 1
            this.$refs['history-scroll'].scrollTo(0, 0)
        }
    }
}
</script>

<style scoped>
.history-item {
    border-top: 1px solid var(--border);
    padding: 15px;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
}

.details .close-position {
    margin-bottom: 10px;
    padding: 4px;
}

.details .item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    background-color: inherit;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 8px;
    overflow: initial;
}

.history-item.active {
    border: 1px solid var(--border);
}

.details .item span:last-child {
    color: var(--white-to-black);
    display: flex;
    align-items: center;
}

.details .item span:last-child i {
    font-size: 16px;
}

.history-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.history-item .row {
    align-items: flex-start;
}

.row.pointer {
    align-items: center;
    text-transform: capitalize;
}

.history-item .time {
    color: var(--white-to-black);
    font-size: 1.1em;
    font-weight: 400;
}

.history-item .lesser {
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 5px;
}

.history-item .symbol-row {
    font-size: 1.1em;
    font-weight: 400;
    color: var(--white-to-black)
}

.history-item .symbol-row img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.history-item .symbol {
    zoom: 0.25;
    margin-right: 20px;
}

@-moz-document url-prefix() {
    .history-item .symbol {
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -24px;
        top: -24px;
    }

    .history-item .symbol-row {
        position: relative;
        padding-left: 20px;
    }
}

.history-item > .row {
    flex-wrap: nowrap;
}

.history-item .col {
    margin-right: 15px;
}

.history-item > .col:last-child {
    margin-right: 0;
    align-items: flex-end;
}

.history-item .profit {
    font-size: 1.1em;
    font-weight: 600;
    text-align: right;
}

.scroll {
    overflow-x: scroll;
    overflow-y: scroll;
}

.symbol {
    cursor: pointer;
}

.no-orders {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px
}

.no-orders i {
    font-size: 60px;
}

.no-orders span {
    font-size: 1.2em;
    text-align: center;
}
</style>
