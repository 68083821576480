<template>
    <div class="card-other">
        <a :href="other.url" target="_blank">
            <div class="state-layer">
                <div class="logo">
                    <img
                        :src="`${!other.logo ? `${staticBase}/exchanges/custom/${getOtherPspImage(other.name)}.png` : `${api}/kyc/document/${other.logo}`} `">
                </div>
                <div class="link">
                    <a class="body__extrasmall" :href="other.url" target="_blank">{{ other.name }}</a>
                    <i class="material-symbols-outlined">open_in_new</i>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import {REST_ENDPOINT} from "@/common/config"
import {mapGetters} from "vuex"

export default {
    name: "CardOther",
    props: {
        other: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            api: REST_ENDPOINT,
        }
    },
    methods: {
        getOtherPspImage(psp) {
            if (psp === 'other' && this.brand === 'orionconsultingcompany') {
                return 'consulting-other'
            }

            if (psp === 'other-2' && this.brand === 'orionconsultingcompany') {
                return 'consulting-other-2'
            }

            if (psp === 'other-3' && this.brand === 'orionconsultingcompany') {
                return 'consulting-other-3'
            }

            return psp
        },
    },
    computed: {
        ...mapGetters(['staticBase'])
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: inherit;
}

.card-other {
    background: var(--System-OnSecondaryFixedVariant);
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    object-fit: cover;
    height: 28px;
}

.link {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: flex-end;
}

.link a {
    color: var(--System-SecondaryFixedDim);
    text-decoration: none;
}

.state-layer {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-other:hover .state-layer {
    background: var(--Statelayers-primaryfixeddim-opacity08);
    color: var(--System-PrimaryFixedDim);
}

.card-other:hover .state-layer .link a {
    color: var(--System-PrimaryFixedDim);
}

.card-other:active .state-layer {
    background: var(--Statelayers-primaryfixeddim-opacity16);
    color: var(--System-PrimaryFixedDim);
}

.card-other:active .state-layer .link a {
    color: var(--System-PrimaryFixedDim);
}
</style>
