<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.pending}"
         v-on:mousedown.stop="hideModal">
        <div class="modal" v-on:mousedown.stop
             :style="{
                top: position.top+'px',
                left: position.left+'px'
            }"
        >
            <div class="title">{{tokens.pendingModal.pending.toUpperCase()}}</div>
            <div class="row stretch">
                <div class="value-container">
                    <span>{{ tokens.pendingModal.assetPrice }}</span>
                    <input class="value" v-model="values.price"
                           @focus="focusPrice"
                           @blur="blurPrice"
                           @keypress="validateInput"
                           @keyup="updatePendingInput"
                           ref="price"
                           :placeholder="tokens.pendingModal.current" />
                </div>
                <div class="buttons">
                    <button @click="changePrice(1)">+</button>
                    <button @click="changePrice(0)">-</button>
                </div>
            </div>
            <button class="revert" :class="{hide: !values.price}" @click="values.price = ''">{{ tokens.pendingModal.revert }}</button>
            <button  class="apply-button" style="margin-top: 10px" @click="apply">Apply
            </button>
            <div class="row stretch">
                <span class="info">{{ tokens.pendingModal.automatically }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {MODAL, MODAL_PENDING} from '@/store/mutations.type'
import {mapGetters} from 'vuex'
import {isMobile} from '@/common/helpers.js'

export default {
    name: 'PendingModal',
    data() {
        return {
            priceTransparent: false,
            values: {
                symbolActive: null,
                price: ''
            }
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                pending: false
            })
        },
        validateInput(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].indexOf(e.key) === -1) {
                e.preventDefault()
            }

            if ((e.key === '.' || e.key === ',') && (e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1)) {
                e.preventDefault()
            }
        },
        updatePendingInput(e) {
            let price = String(e.target.value)
            price = price.includes(',') ? price.replace(/,/, '.') : price
            !isNaN(price) ? this.values.price = price: ''
        },
        focusPrice() {
            this.priceTransparent = true
        },
        blurPrice() {
            this.priceTransparent = false
        },
        changePrice(dir) {
            if (!this.values.price) {
                this.values.price = this.currentSym.quote.bid
            }

            if (dir) {
                this.values.price = Number(this.values.price) + Math.pow(0.1, this.currentSym.precision)
            } else {
                this.values.price -= Math.pow(0.1, this.currentSym.precision)
                this.values.price = Math.max(0, this.values.price)
            }

            this.values.price = (this.values.price + Number.EPSILON).toFixed(this.currentSym.precision)
        },
        apply(){
            this.$store.commit(MODAL_PENDING, {event: 1})
            this.$store.commit(MODAL, {
                pending: false
            })
        },
        isMobile
    },
    computed: {
        ...mapGetters(['modals', 'modalPending', 'tokens', 'symbolsActive', 'symbols']),
        currentSym() {
            if (this.values.symbolActive === undefined || this.values.symbolActive === null || !this.symbolsActive[this.values.symbolActive]) {
                return {
                    precision: 0,
                    quote: {
                        bid: 0
                    }
                }
            }

            return this.symbols[this.symbolsActive[this.values.symbolActive].symbol]
        },
        position() {
            if (!this.modals.pending) {
                return {
                    left: 0,
                    top: 0
                }
            }

            let screen = {
                    width: document.body.offsetWidth,
                    height: document.body.offsetHeight,
                },
                modal = {
                    width: 210,
                    height: this.values.price ? 232: 199
                }

            if(screen.width >= 600 && this.isMobile()) {
                return {
                    left: this.modalPending.posX,
                    top: this.modalPending.posY - 29
                }
            }

            if(screen.width < 600 && this.isMobile()) {
                return {
                    left: screen.width - modal.width,
                    top: this.modalPending.posY - 29
                }
            }

            return {
                left: this.modalPending.left - modal.width - 10,
                top: Math.min(this.modalPending.top, screen.height - modal.height)
            }
        }
    },
    watch: {
        modalPending: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_PENDING, {
                    symbolActive: val.symbolActive,
                    price: val.price
                })
            }
        }
    }
}
</script>

<style scoped>
    .modal-wrapper {
        position: absolute;
        z-index: 200;
    }

    .modal {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: var(--background-dark);
        z-index: 200;
        position: absolute;
        width: 210px;
    }

    .title {
        padding: 15px;
        background: var(--background-dark-mid);
        font-weight: 400;
        font-size: 1.1em;
        color: var(--white-to-black);
    }

    .value-container {
        border: 2px solid var(--background-light);
        background-color: var(--background-light);
        display: flex;
        flex-direction: column;
        padding: 5px;
        cursor: pointer;
        position: relative;
        transition: background-color ease-in-out 0.3s;
        flex: 1 1 auto;
        max-width: 150px;
        border-radius: 3px 0 0 3px;
    }

    .light-theme .value-container {
        border: 2px solid var(--border);
        background-color: var(--aspin-background-light);

    }

    .value-container.transparent {
        background-color: transparent;
    }

    .value-container:hover {
        color: var(--white-to-black);
    }

    .value {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.5em;
        margin-top: 3px;
        flex: 1 1 auto;
    }

    .stretch {
        position: relative;
        padding: 15px;
        flex-wrap: nowrap;
    }

    .buttons {
        display: flex;
        flex: 0 0 30px;
        flex-direction: column;
    }

    .buttons button {
        margin-left: 2px;
        font-size: 1.3em;
        padding: 0;
        flex: 1 1 auto;
        background-color: var(--aspin-background-light);
        color: var(--white-to-black);
        border: 1px solid var(--border);
    }

    .buttons button:first-child {
        border-radius: 0 3px 0 0;
        margin-bottom: 2px;
    }

    .buttons button:last-child {
        border-radius: 0 0 3px 0;
    }

    input::placeholder {
        color: var(--white-to-black)
    }

    .info {
        color: var(--white-to-black);
        text-align: center;
        font-weight: 400;
    }

    .revert {
        margin: 0 15px;
    }

    .apply-button {
        background-color: var(--green-button);
        margin: 0 15px;
    }

    @media (orientation: landscape) {
        .mobile-app .modal {
            left: initial !important;
            right: 110px;
            top: 0 !important;
        }
    }
</style>