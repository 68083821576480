<template>
    <div class="saving-accounts">
        <div class="saving-accounts-header">
            <!-- need to clarify -->
            <PositionsSelect
                class="select"
                :options="{
                    '': 'All period investment',
                    'pct_0' : 'Flexible', 
                    'pct_30' : '1 Month', 
                    'pct_90' : '1 Quarter', 
                    'pct_180' : '6 Months', 
                    'pct_365' : '1 Year'}"
                :active="chosenPeriod"
                @update="chosenPeriod = $event" />
        </div>
        <div v-if="filteredStakingAccounts.length" class="saving-accounts-list">
            <div 
                class="saving-accounts-item" 
                v-for="account in filteredStakingAccounts" 
                :key="account.id">
                    <div class="item-header">
                        <div class="currency">
                            <div class="image"><img :src="staticBase + `currencies/${account.currency}.png`"></div>
                            <div class="name body__large">{{ account.currency }}</div>
                        </div>
                        <div class="rate body__large">{{ account.annual_pct.toFixed(1) }}%</div>
                    </div>
                    <div class="item-body">
                        <div class="investment body__small">
                            <span>{{ tokens.fullScreenSavings.investment }}</span>
                            <span>
                                {{ currencySign(account.currency) + account.balance ? 
                                    account.balance.toFixed(2) : '' }}
                            </span>
                        </div>
                        <div class="period body__small">
                            <span>{{ tokens.fullScreenSavings.period }}</span>
                            <span>{{ tokens.fullScreenSavings['pct_'+account.period]}}</span>
                        </div>
                        <div v-if="account.period != 0" class="days-total">
                            <span>{{ tokens.fullScreenSavings.daysTotal }}</span>
                            <span>{{ account.period }} {{ tokens.fullScreenSavings.days }}</span>
                        </div>
                        <template v-if="account.period >= 0">
                            <div class="created">
                                <span>{{ tokens.fullScreenSavings.created }}</span>
                                <span>{{ timeToString(account.created*1000).split(' ')[0] }}</span>
                            </div>
                            <div class="release">
                                <span>{{ tokens.fullScreenSavings.releaseTime }}</span>
                                <span>{{ account.period > 0 ?
                                        timeToString((account.created * 1000) + (account.period * 24 * 3600 * 1000)).split(' ')[0] : '-' }}
                                </span>
                            </div>
                            <div class="elapsed">
                                <span>{{ tokens.fullScreenSavings.daysElapsed }}</span>
                                <span>
                                    {{ Math.floor((time / 1000 - account.created)/86400) }} 
                                    {{ tokens.fullScreenSavings.days }}
                                </span>
                            </div>
                        </template>
                    </div>
                    <div class="item-footer">
                        <div v-if="account.period == 0" class="earnings">
                            <span>{{ tokens.fullScreenSavings.earnings }}</span>
                            <span>
                                {{ currencySign(account.currency) + ((account.balance * account.annual_pct * (time / 1000
                                - account.created))/(100 * 365 * 86400)).toFixed(6) }}
                            </span>
                        </div>
                        <template v-if="account.period != 0">
                            <div class="final-earnings">
                                <span>{{ tokens.fullScreenSavings.finalEarnings }}</span>
                                <span>{{ currencySign(account.currency) + ((account.balance * account.annual_pct *
                                        account.period)/(100 * 365)).toFixed(2) }}
                                </span>
                            </div>
                            <div  class="funds">
                                <span>{{ tokens.fullScreenSavings.fundsReleasedIn }}</span>
                                <span>
                                    {{ account.period - Math.floor((time /1000 - account.created)/86400) }} 
                                    {{ tokens.fullScreenSavings.days }}
                                </span>
                            </div>
                        </template>

                        <BaseButton 
                        v-show="account.period == 0" 
                        :buttonText="tokens.fullScreenSavings.claim" 
                        :buttonStyle="'outlined-primary'" 
                        class="claim-btn"
                        @click.native="claim(account.id)"  />

                        <div class="progress-bar" v-if="account.period != 0">
                            <div class="empty">
                                <div class="filled" :style="{ width: calcFilledPercent(account) }"></div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div v-else class="not-found body__large">Not found</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {currencySign, timeToString} from "@/common/helpers"
import {STAKING_CLAIM} from '@/store/actions.type'
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton"
import PositionsSelect from "@/new-design/shared/ui/selects/PositionsSelect";


export default {
    name: "SavingsAccountsTab",
    components: {BaseButton, PositionsSelect},
    props: {
        time: {
            type: [Number, String]
        },
    },
    data(){
        return {
            chosenPeriod: ''
        }
    },
    computed: {
        ...mapGetters(['tokens', 'symbols', 'staticBase', 'stakingAccounts']),
        filteredStakingAccounts() {
            return Object.values(this.stakingAccounts).filter(account => (account.period == this.chosenPeriod.split('_')[1] ) || this.chosenPeriod === '')
        }
    },
   
    methods: {
        currencySign,
        timeToString,
        claim(id) {
            this.$store.dispatch(STAKING_CLAIM, {
                account_id: id
            })
        },
        calcFilledPercent(account) {
            let zeroDatum = 5,
                result = zeroDatum
            if (account.period) {
                let timeElapsed = (this.time / 1000 - account.created) / 86400,
                    percentElapsed = 100 * Math.floor(timeElapsed / account.period)
                result = (100 - zeroDatum) / 100 * percentElapsed + zeroDatum
            }
            return result + '%'
        }
    }
}
</script>

<style scoped>

.saving-accounts {
    max-height: 100%;
    overflow: hidden;
}

.saving-accounts-header {
    padding: 0 16px 16px;
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.saving-accounts-list {
    padding: 16px;
    height: calc(100% - 20px);
    overflow-y: auto;
}

.saving-accounts-item {
    border-radius: 8px;
    margin-bottom: 12px;
    overflow: hidden;
}

.item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: var(--System-Surface2);
}

.currency {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 10px;
}

.image img {
    height: 24px;
}

.name,
.rate {
    color: var(--System-OnSurface);
}

.item-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background: var(--System-Surface1);
}
 
 .item-footer { 
    border-top: 1px solid var(--System-OutlineVariant);
    background: var(--System-Surface1);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
}

 .item-body > div > span:last-child,
 .item-footer > div > span:last-child {
    color: var(--System-OnSurface);
 }

 .claim-btn {
    margin-top: 8px;
 }

.item-body > div,
.item-footer > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--System-Outline);
}

.progress-bar {
    margin-top: 8px;
    height: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    background: var(--System-SecondaryContainer);
}

.progress-bar .empty {
    height: 12px;
    border-radius: 8px;
}

.progress-bar .filled {
    height: 100%;
    background: var(--System-Secondary);
    border-radius: 8px;
}

.not-found {
    margin-top: 50px;
    color: var(--System-OnSurface);
    text-align: center;
}
</style>