<template>
    <div class="tab">
        <component :is="component"/>
    </div>
</template>

<script>
export default {
    name: "BaseTabViewerItem",
    props: {
        component: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style scoped>

</style>
