<template>
    <button class="button-standard" :class="{'danger': type === 'danger'}">
        <div class="state-layer" v-if="type === 'upload'">
            <span class="label__small" v-if="text">{{text}}</span>
            <input type="file" @change="documentSelected" />
        </div>
        <div class="state-layer" v-else>
            <span class="label__small" v-if="text">{{text}}</span>
        </div>
    </button>
</template>

<script>
import { AVATAR_UPLOAD } from "@/store/actions.type"
export default {
    name: "UploadPhotoButton",
    props: {
        text: {
            type: String,
        },
        type: {
            type: String,
            default: '',
        },
    },
    methods: {
        documentSelected(e) {
            let data = new FormData()

            data.append('file', e.target.files[0])

            this.$store.dispatch(AVATAR_UPLOAD, data)

            e.target.value = ''
        },
    }
}
</script>

<style scoped>
.button-standard, .button-standard .state-layer {
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
    white-space: nowrap;
}

.state-layer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 4px 6px;
}

.button-standard:disabled {
    cursor: not-allowed;
}

.button-standard {
    color: var(--System-Primary);
}

.button-standard:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-standard:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-standard:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-standard:disabled {
    color: var(--System-OnSurface);
}

.button-standard.danger {
    color: var(--System-Error);
}

.button-standard.danger:hover .state-layer {
    background: var(--Statelayers-error-opacity08);
}

.button-standard.danger:focus-visible .state-layer {
    background: var(--Statelayers-error-opacity12);
}

.button-standard.danger:active .state-layer {
    background: var(--Statelayers-error-opacity16);
}

.button-standard.danger:disabled {
    color: var(--System-OnSurface);
}

.button-standard {
    color: var(--System-Primary);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: max-content
}

.button-standard input {
    position: absolute;
    width: 300%;
    height: 150%;
    top: -50%;
    left: -150%;
    cursor: pointer;
    font-size: 16px;
    opacity: 0;
}
</style>
