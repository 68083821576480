<template>
    <div class="orders-pane" :class="{hide: !ordersPane}">
        <div class="tabs">
            <div class="tab-item" :class="{active: tabActive === 'active'}" @click="activateTab('active')">
                <span>{{ tokens.ordersPane.ordersActive.toUpperCase() }}</span>
            </div>
            <div class="tab-item" :class="{active: tabActive === 'history'}" @click="activateTab('history')">
                <span>{{ tokens.ordersPane.ordersHistory.toUpperCase() }}</span>
            </div>
        </div>

        <div class="tables-container" id="tables-container">
            <OrdersTable  :class="{hide: tabActive !== 'active'}" />
            <HistoryTable :class="{hide: tabActive !== 'history'}" :active="tabActive === 'history'" />
        </div>

        <i class="material-icons expander pointer" @click="closeOrdersPane()">expand_more</i>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ORDERS_PANE, HISTORY_TABLE_IS_ACTIVE} from '@/store/mutations.type'
import OrdersTable from '@/components/OrdersTable'
import HistoryTable from '@/components/HistoryTable'

export default {
    name: 'OrdersPane',
    data() {
        return {
            tabActive: 'active'
        }
    },
    components: {HistoryTable, OrdersTable},
    methods: {
        closeOrdersPane() {
            this.$store.commit(ORDERS_PANE, false)
        },
        activateTab(name) {
            this.tabActive = name
            if(name === 'history') {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, true)
            } else {
                this.$store.commit(HISTORY_TABLE_IS_ACTIVE, false)
            }
        }
    },
    computed: {
        ...mapGetters(['ordersPane','tokens'])
    }
}
</script>

<style scoped>
    .orders-pane {
        flex: 1 1 110px;
        min-height: 110px;
        width: 100%;
        padding: 5px;
        border-left: 1px solid var(--background-light);
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr;
    }

    .tabs {
        display: flex;
        align-items: flex-end;
        margin: 0;
        padding-bottom: 3px;
    }

    .tabs .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1.1em;
        color: var(--white-to-black);
        border-bottom: 1px solid transparent;
        padding: 5px 0;
        cursor: pointer;
    }

    .tabs .tab-item.active {
        color: var(--brand);
        border-bottom-color: var(--brand);
    }

    .tabs span {
        width: 100%;
        text-align: center;
        padding: 0 20px;
    }

    .tabs .tab-item:first-child span {
        border-right: 1px solid var(--border);
    }

    .tables-container {
        overflow: auto;
    }

    .expander {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 30px;
    }
</style>