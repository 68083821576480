<script>
import {mapGetters} from "vuex";
export default {
    name: "BaseMonogram",
    props: {
        monogramStyles: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(['user']),
    }
}
</script>

<template>
    <button class="monogram" :class="monogramStyles">
        {{ this.user.firstname && this.user.firstname[0] }}
    </button>
</template>

<style scoped>
.monogram {
    padding: 5px;
    background: var(--System-SecondaryContainer);
    border: 3px solid #3d5977;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--System-OnSecondaryContainer);
    cursor: pointer;
}

.monogram.monogram-square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-size: 32px;
    border-color: transparent;
}

button.monogram:active {
    outline: 2px solid rgb(47, 51, 60) !important;
}
</style>
