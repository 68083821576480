import { render, staticRenderFns } from "./ProgressChipsList.vue?vue&type=template&id=06f88481&scoped=true"
import script from "./ProgressChipsList.vue?vue&type=script&lang=js"
export * from "./ProgressChipsList.vue?vue&type=script&lang=js"
import style0 from "./ProgressChipsList.vue?vue&type=style&index=0&id=06f88481&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f88481",
  null
  
)

export default component.exports