<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.timePicker}"
         @mousedown.stop="hideModal">
        <div class="modal" @mousedown.stop
             :style="{
                top: position.top+'px',
                left: position.left+'px'
            }"
        >
            <div class="title">{{ tokens.timePickerModal.title.toUpperCase() }}</div>
            <DatePicker v-model="values.expiration"
                        type="datetime"
                        :confirm="true"
                        :value-type="'timestamp'"
                        :confirm-text="tokens.autoCloseModal.updateOrder"
                        @confirm="updateOrder"
            >
            </DatePicker>
                {{values.expiration}}
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AUTOCLOSE, NOTIFICATION_ERROR} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'
import {CFD_ORDER_MODIFY_REQUEST} from "@/store/actions.type"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
    name: 'TimePickerModal',
    components: {DatePicker},
    data() {
        return {
            values: {
                left: 0,
                top: 0,
                orderActive: null,
                expiration: null,
            },
            mode: 'default',
        }
    },
    methods: {
        updateOrder() {
            const currentTime = Date.now();
            if ((this.expiration) <= currentTime) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_TIME')
                this.values.expiration = null
                return
            }

            this.$store.dispatch(CFD_ORDER_MODIFY_REQUEST, {
                id: this.values.orderActive,
                account_id: this.activeAccount.id,
                expiration: this.values.expiration / 1000
            })

            this.$store.commit(MODAL, {
                timePicker: false
            })
        },
        hideModal() {
            this.$store.commit(MODAL, {
                timePicker: false
            })

            let mxDatepicker = document.querySelector('.mx-datepicker-popup')
            if (mxDatepicker) {
                mxDatepicker.style.display = 'none'
            }
        },
        currencySign,
        isMobile
    },
    computed: {
        ...mapGetters(['modals', 'modalTimePicker', 'tokens', 'activeAccount', 'symbols', 'orders']),
        position() {
            if (!this.modals.timePicker) {
                return {
                    left: 0,
                    top: 0
                }
            }

            let screen = {
                    width: document.body.offsetWidth,
                    height: document.body.offsetHeight,
                },
                modal = {
                    width: 200,
                    height: this.values.orderActive ? 262 : 209
                }

            if (screen.width >= 600 && this.isMobile()) {
                return {
                    left: this.modalTimePicker.posX,
                    top: this.modalTimePicker.posY - 29 * 3
                }
            }

            if (screen.width < 600 && this.isMobile()) {
                return {
                    left: screen.width - modal.width,
                    top: this.modalTimePicker.top - 29 * 3
                }
            }
            return {
                left: this.modalTimePicker.left - modal.width - 10,
                top: Math.min(this.modalTimePicker.top, screen.height - modal.height)
            }
        },
    },
    watch: {
        modalTimePicker: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                    if (n === 'expiration') {
                        this.values[n] = val[n] * 1000 || null
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_AUTOCLOSE, val)
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.mx-datepicker {
    width: 100%;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--background-dark);
    z-index: 200;
    top: 357px;
    right: 110px;
    width: 200px;
}

.title {
    padding: 15px;
    background: var(--background-dark-mid);
    font-weight: 400;
    font-size: 1.3em;
    color: var(--white-to-black);
    text-align: center;
}

.btns {
    margin-top: 1px;
    display: flex;
    align-items: stretch;
    padding: 10px 15px 0;
}

.btn {
    height: 25px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--background-light);
    border-radius: 2px;
    cursor: pointer;
    color: var(--font);
}

.btn.active {
    color: var(--white-to-black);
}

.light-theme .btn {
    background-color: var(--aspin-background-light);
    outline: 1px solid var(--border);
}

.btn:not(:last-child) {
    margin-right: 2px;
}

.value-container {
    background-color: var(--background-light);
    border: 2px solid var(--background-dark);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: background-color ease-in-out 0.3s;
    flex: 1 1 auto;
}

.light-theme .value-container {

    background-color: var(--aspin-background-light);
    border-color: var(--border);
}

.value-container--grid {
    display: grid;
    grid-template-columns: 1fr 26px;
    grid-template-rows: 1fr 1fr;
}

.subtitle-input {
    grid-column: 1/2;
    grid-row: 1/3;
    padding: 5px;
}

input.value {
    background-color: transparent;
    border: none;
    width: 85%;
    cursor: pointer;
    font-size: 1.5em;
    margin-top: 3px;
}

.plus {
    grid-column: 2/3;
    grid-row: 1/2;
    border-left: 2px solid var(--background-dark);
    border-bottom: 2px solid var(--background-dark);
    display: grid;
}

.minus {
    grid-column: 2/3;
    grid-row: 2/3;
    border-left: 2px solid var(--background-dark);
    display: grid;
}

.plus i, .minus i {
    place-self: center center;
    font-size: 12px;
}

/* modifiers */

.value-container:hover span {
    color: var(--white-to-black);
}

.value-container.transparent {
    border: 2px solid var(--background-light);
    background-color: transparent;
}

.value-container.transparent .plus {
    border-left: 2px solid var(--background-light);
    border-bottom: 2px solid var(--background-light);
}

.value-container.transparent .minus {
    border-left: 2px solid var(--background-light);
}


.sign {
    position: absolute;
    left: 5px;
    bottom: 6px;
    font-size: 1.5em;
    color: var(--white);
    font-weight: 400;
}

.type-select {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
    font-weight: 400;
    color: var(--white);
    flex: 0 0 30px;
    background: var(--background);
    border-left: 2px solid var(--background-dark);
    padding: 10px;
    cursor: pointer;
}

.type-select-options {
    position: absolute;
    z-index: 2;
    top: 53px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 2px;
    width: 100%;
    background: var(--background-dark);
}

.type-select-options .item {
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 6px 10px;
    color: var(--white);
    font-weight: 400;
    cursor: pointer;
}

.type-select-options .item:last-child {
    border-bottom: none;
}

.type-select-options .item.active, .type-select-options .item:hover {
    background: var(--border);
}

.type-select-options .item span {
    margin-right: 10px;
}

.stretch {
    position: relative;
    padding: 15px;
}

button {
    margin: 10px 15px;
}

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>
