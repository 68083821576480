<template>
    <div 
        class="radio-button" 
        :class="{active: state , 'hover-disable': hoverDisable}" 
        @click="setRadio()">
        <div class="radio-box"></div>
    </div>
</template>

<script>
export default {
    name: "BaseRadioButton",
    props: {
        state: {
            type: Boolean,
            default: false
        },
        hoverDisable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setRadio() {
            this.$emit('update', this.state)
        }
    }
}
</script>

<style scoped>
.radio-button {
    padding: 8px;
    border-radius: 100%;
    cursor: pointer;
}

.radio-box {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid var(--System-OnSurfaceVariant);
    position: relative;
}

.radio-button.active .radio-box {
    border: 3px solid var(--System-Tertiary);
}

.radio-button.active .radio-box::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: var(--System-Tertiary);
}

.radio-button:hover {
    background-color: var(--Statelayers-onsurface-opacity08);
}

.radio-button.active:hover {
    background-color: var(--Statelayers-tertiary-opacity08);
}
.radio-button.hover-disable:hover,
.radio-button.active.hover-disable:hover {
    background-color: transparent;
}
</style>
