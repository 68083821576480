<template>
    <div class="input-container" :class="id">
        <div v-if="title" class="title body__medium">{{ title }}</div>
        <div class="input-box">
            <div class="box">
                <div class="slot-wrapper">
                    <slot name="icon"/>
                </div>
                <input
                    v-if="mask"
                    :value="value"
                    :type="getInputType"
                    :placeholder="placeholder"
                    :class="{ 'no-valid': error, hasSlot, filled: value && id === 'otp' }"
                    class="body__large"
                    :disabled="disabled"
                    v-mask="mask"
                    @input="updateInputValue"
                />
                <input
                    v-else
                    :value="value"
                    :type="getInputType"
                    :placeholder="placeholder"
                    :class="{ 'no-valid': error, hasSlot }"
                    class="body__large"
                    :disabled="disabled"
                    @input="updateInputValue"
                />
                <div v-if="showIcons" class="icons">
                    <i
                        v-if="value && type !== 'password'"
                        class="material-symbols-outlined"
                        @click="clearInputValue"
                    >
                        close
                    </i>
                    <i
                        v-if="type === 'password'"
                        class="material-symbols-outlined"
                        @click="showValue"
                    >
                        {{ visibilityState ? 'visibility' : 'visibility_off' }}
                    </i>
                    <div v-if="$slots['info']" class="info">
                        <slot name="info"/>
                    </div>
                </div>
            </div>
        </div>
        <small v-if="info">{{ info }}</small>
    </div>
</template>

<script>
export default {
    name: "BaseInput",
    props: {
        value: {
            type: [String, Number],
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        error: {
            type: Boolean,
            default: false
        },
        info: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String,
            default: ''
        },
        showIcons: {
            type: Boolean,
            default: true
        },
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visibilityState: false
        }
    },
    computed: {
        hasSlot() {
            return !!this.$slots.icon
        },
        getInputType() {
            return this.type === "password" && this.visibilityState
                ? "text"
                : this.type
        }
    },
    methods: {
        showValue() {
            this.visibilityState = !this.visibilityState
        },
        updateInputValue(e) {
            let newValue = e.target.value
            if (this.type === "number") {
                newValue = this.validateNumberInput(newValue)
            }

            this.$emit("input", newValue)
        },
        validateNumberInput(value) {
            return value
                .replace(/[^0-9.,]/g, "")
                .replace(/(\..*)\./g, "$1")
                .replace(/(,.*),/g, "$1")
                .replace(/(,.*)\./g, "$1")
                .replace(/(\..*),/g, "$1")
        },
        clearInputValue() {
            this.$emit("input", "")
        }
    }
}
</script>

<style scoped>
.input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.title {
    display: flex;
    padding-bottom: 4px;
}

.box {
    display: flex;
    align-items: center;
    width: 100%;
}

input {
    padding: 10px 70px 10px 8px;
    outline: none;
    height: 100%;
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
}

input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--System-Outline);
}

input.filled,
input:focus {
    border-color: var(--System-Primary);
}

input.hasSlot {
    padding-left: 33px
}

.slot-wrapper {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 25px;
    max-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 8px;
}

.no-valid {
    border-color: var(--System-Error);
}

.info {
    display: flex;
    cursor: default;
    padding-left: 8px;
    border-left: 1px solid var(--System-OutlineVariant);
}

.otp input {
    padding: 8px;
    text-align: center;
    width: 40px;
    height: 40px;
}
</style>
