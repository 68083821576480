import { render, staticRenderFns } from "./SymbolsPanel.vue?vue&type=template&id=5974b9d2&scoped=true"
import script from "./SymbolsPanel.vue?vue&type=script&lang=js"
export * from "./SymbolsPanel.vue?vue&type=script&lang=js"
import style0 from "./SymbolsPanel.vue?vue&type=style&index=0&id=5974b9d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5974b9d2",
  null
  
)

export default component.exports