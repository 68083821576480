<template>
    <div class="banner-info">
        <component :is="BaseInfoIcon"/>
        <div class="content">
            <marquee class="body__medium">
                {{ message }}
            </marquee>
        </div>
    </div>
</template>

<script>
import BaseInfoIcon from "@/new-design/shared/ui/Icons/BaseInfoIcon.vue"

export default {
    name: "BaseBannerInfo",
    computed: {
        BaseInfoIcon() {
            return BaseInfoIcon
        }
    },
    props: {
        message: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.banner-info {
    display: grid;
    grid-template-columns: 20px 1fr;
    padding: 6px;
    background: var(--System-Secondary);
    color: var(--System-OnSecondary);
}

.content {
    overflow: hidden;
    padding: 0 6px
}
</style>
