<template>
    <div class="withdrawal-request">
        <div class="coast-container">
            <span
                class="coast label__medium">{{
                    currencySign(withdrawal.currency)
                }} {{ withdrawal.status === 1 ? withdrawal.amount.toFixed(2) : (-withdrawal.amount).toFixed(2) }}</span>
            <span class="currency body__small">{{ withdrawal.currency }}</span>
        </div>
        <div class="status-container" :class="tokens.statuses[withdrawal.status].toLowerCase()">
            <span class="status label__small">
                {{ tokens.statuses[withdrawal.status] }}
            </span>
            <i v-if="withdrawal.status === 0 && !hideWithdrawal"
               @click="confirmCancelWithdrawal(withdrawal.id)"
               class="material-icons cancel-withdrawal" title="Cancel withdrawal request">cancel</i>
        </div>
        <div class="time body__extrasmall">
            {{ formatDate(withdrawal.time_created) }}
        </div>
        <div class="info body__extrasmall">
            {{ withdrawal.comment || '-' }}
        </div>
    </div>
</template>

<script>
import {currencySign} from "@/common/helpers"
import {mapGetters} from "vuex"
import {CLOSE_WITHDRAWAL} from "@/common/config";
import {MODAL, MODAL_WITHDRAWAL_CANCEL} from "@/store/mutations.type";

export default {
    name: "WithdrawalRequest",
    props: {
        withdrawal: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            hideWithdrawal: CLOSE_WITHDRAWAL,
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    },
    methods: {
        currencySign,
        confirmCancelWithdrawal(id) {
            this.$store.commit(MODAL_WITHDRAWAL_CANCEL, id)
            this.$store.commit(MODAL, {
                withdrawalCancel: true
            })
        },
        formatDate(timestamp) {
            const date = new Date(timestamp * 1000)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()
            const time = date.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit', second: '2-digit'})

            return `${day}.${month}.${year} ${time}`
        }
    }
}
</script>

<style scoped>
.withdrawal-request {
    display: grid;
    width: 100%;
    gap: 6px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--System-Surface5);
}

.withdrawal-request:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.coast-container {
    grid-area: 1/1;
}

.status-container {
    grid-area: 1/2;
    padding: 2px 4px;
    border-radius: 3px;
    background: var(--Statelayers-onsurfacevariant-opacity16);
    width: fit-content;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    gap: 4px;
}

.status-container.pending .status {
    font-weight: 500;
    color: var(--System-OnSurfaceVariant);
}

.status-container.approved {
    background: var(--Statelayers-tertiary-opacity16);
}

.status-container.approved .status {
    color: var(--System-Tertiary);
}

.status-container.declined {
    background: var(--Statelayers-redfixed-opacity16);
}

.status-container.declined .status {
    color: var(--Custom-RedFixed);
}

.time {
    grid-area: 2/1;
    color: var(--System-OnSurfaceVariant);
}

.info {
    grid-area: 2/2;
    text-align: right;
    color: var(--System-OnSurfaceVariant);
}

.coast {
    color: var(--System-OnSurface);
}

.currency {
    color: var(--System-OnSurfaceVariant);
    margin-left: 4px;
}

i {
    font-size: 16px;
    cursor: pointer;
}
</style>
