<template>
    <div class="tabs">
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    name: "BaseTabsBox"
}
</script>

<style scoped>
.tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px;
}
</style>
