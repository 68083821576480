<template>
    <div class="title">
        <div class="row row-left">
            <!-- <i class="material-icons close" @click="close()">{{ 'close' }}</i> -->
            <h4 class="title__medium"> {{ title }}</h4>
        </div>
        <div class="slot-wrapper">
            <slot name="right" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BalanceModalTitle",
    props: {
        title: {
            type: String,
            default: ''
        },
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.title {
    padding: 18px 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    color: var(--System-OnSurface);
}

@media (min-width: 767px) {
    .close {
        display: none;
    }
}

@media (max-width: 767px) {
    .title h4 {
        font-size: 22px;
    }
}

.row-left {
    gap: 8px;
}

i {
    cursor: pointer;
}
</style>
