<template>
  <div class="modal-wrapper" :class="{hide: !modals.AlertMessageModal}" v-on:click.stop="hideModal">
      <div class="modal" v-on:click.stop>
         <div class="row">
           {{ varNames.ALERT_MESSAGE }}
          </div>
          <button class="button" @click="hideModal">close</button>
      </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from '@/store/mutations.type'

export default {
  name: "AlertMessageModal",
  data(){
    return {
      firstTimeShowed : true
    }
  },
  methods: {
      hideModal() {
          this.$store.commit(MODAL, {
              AlertMessageModal: false
          })
      },
  },
  computed: {
      ...mapGetters(['modals', 'tokens','variables']),
      varNames(){
          return this.variables
      }
  },
  watch: {
    modals: {
      deep: true,
      handler(val) {
        if (val.fullscreen && this.firstTimeShowed) {
          this.firstTimeShowed = false
          this.$store.commit(MODAL, {
            AlertMessageModal: true
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--background-dark);
  padding: 25px;
  border: 1px solid var(--border);
  z-index: 1100;
}

label {
  margin-bottom: 5px;
}

input {
  width: 100%;
}

.row {
  overflow: hidden;
  align-items: stretch;
  flex-wrap: nowrap;
  line-height: 1.5;
  text-align: center;
}

.button {
  width: max-content;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  font-weight: 600;
  cursor: pointer;
  color: var(--white);
  background-color: var(--green-button);

}
</style>./PsiDss.vue
