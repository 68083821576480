import {LIGHT_THEME} from "@/common/config"
import {baseLightTheme} from "@/new-design/app/theme/Themes/baseLightTheme"

const setLightTheme = () => {
    switch (LIGHT_THEME) {
        default:
            return baseLightTheme
    }
}

export const lightTheme = setLightTheme()
