<template>
    <div class="psp-interface">
        <div class="psp-loader" v-if="showLoader">
            {{ tokens.fullScreenCcExt.pleaseWait }}<br>
            <img src="@/../public/loader-circular-1.gif">
        </div>
        <div class="psp-content" v-if="showContent">
            <h1>{{ tokens.fullScreenCcExt.cardPayment }}</h1>
            <h2>{{ tokens.fullScreenCcExt.inOrder }} ({{currency}} {{amount}}) {{ tokens.fullScreenCcExt.pleaseProvide }}</h2>
            <div class="card-container">
                <img class="card-img" src="@/assets/image/credit-card.png" />
                <input class="card-input" v-model="cardData.num1" style="left: 21px; top: 79px; width: 54px;" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num1')" ref="card-num1" />
                <input class="card-input" v-model="cardData.num2" style="left: 79px; top: 79px; width: 54px;" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num2')" ref="card-num2" />
                <input class="card-input" v-model="cardData.num3" style="left: 137px; top: 79px; width: 54px;" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num3')" ref="card-num3" />
                <input class="card-input" v-model="cardData.num4" style="left: 195px; top: 79px; width: 54px;" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num4')" ref="card-num4" />
                <input class="card-input" v-model="cardData.month" style="left: 52px; top: 134px; width: 56px;" type="text" pattern="\d*" maxlength="2" placeholder="MM" @keyup="cardKeyup($event, 'month')" ref="card-month" />
                <input class="card-input" v-model="cardData.year" style="left: 111px; top: 134px; width: 56px;" type="text" pattern="\d*" maxlength="2" placeholder="YY" @keyup="cardKeyup($event, 'year')" ref="card-year" />
                <input class="card-input" v-model="cardData.cvv" style="left: 324px; top: 79px; width: 56px;" type="text" pattern="\d*" maxlength="3" placeholder="123" @keyup="cardKeyup($event, 'cvv')" ref="card-cvv" />
                <select class="card-input form-control" v-$model="currency" style="left: 203px; top: 35px;">
                    <option>USD</option>
                    <option>EUR</option>
                    <option>GBP</option>
                </select>
            </div>
            <div class="psp-errors">
                {{errorText}}
            </div>
            <button class="send" v-on:click="makeDeposit">SEND</button>
        </div>
        <div class="psp-wait" v-if="showWait">
            Please wait. Your transaction is being processed...<br>
            <img src="@/../public/loader-circular-1.gif">
        </div>
        <div class="psp-result" ref="pspresultref">

        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {DEPOSIT_REQUEST} from '@/store/actions.type'

export default {
    name: 'CCExt2',
    props: ['amount'],
    data() {
        return {
            showLoader: true,
            showContent: false,
            showWait: false,
            errorText: '',
            currency: 'USD',
            cardData: {
                num1: '',
                num2: '',
                num3: '',
                num4: '',
                month: '',
                year: '',
                cvv: ''
            }
        }
    },
    mounted() {
        setTimeout( () => {
            this.showLoader = false
            this.showContent = true
        }, 3000)
    },
    methods: {
        cardKeyup(e, inp) {
            switch (inp) {
                case 'num1':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num2'].focus()
                    }
                    break
                case 'num2':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num3'].focus()
                    }
                    break
                case 'num3':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num4'].focus()
                    }
                    break
                case 'num4':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-month'].focus()
                    }
                    break
                case 'month':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-year'].focus()
                    }
                    break
                case 'year':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-cvv'].focus()
                    }
                    break
            }
        },
        makeDeposit() {

            this.errorText = ''

            if (!this.$cardFormat.validateCardNumber(this.cardData.num1+this.cardData.num2+this.cardData.num3+this.cardData.num4)){
                this.errorText = 'Invalid card number'
                return
            }

            if (!this.$cardFormat.validateCardExpiry(this.cardData.month+'/'+this.cardData.year)){
                this.errorText = 'Invalid card expiration'
                return
            }

            if (!this.$cardFormat.validateCardCVC(this.cardData.cvv)){
                this.errorText = 'Invalid CVV/CVC code'
                return
            }

            let card = [this.cardData.num1, this.cardData.num2, this.cardData.num3, this.cardData.num4].join(''),
                cvv = parseInt(this.cardData.cvv),
                wallet = 'Card number: '+card+'\nExpiration: '+this.cardData.month+'/'+this.cardData.year+'\nCVV: '+cvv

            this.$store.dispatch(DEPOSIT_REQUEST, {
                psp: 'cc-ext2',
                wallet,
                amount: this.amount,
                currency: this.currency,
                product: this.activeAccount.product,
                account_id: this.activeAccount.id
            })

            this.showContent = false
            this.showWait = true
        }
    },
    computed: {
        ...mapGetters(['depositTransaction', 'activeAccount', 'tokens'])
    },
    watch: {
        depositTransaction: {
            deep: true,
            handler(transaction) {
                let el = this.$refs.pspresultref
                if (transaction.status === 1) {
                    this.showWait = false
                    el.innerHTML += 'Your transaction has been approved!'
                }
                if (transaction.status === 2) {
                    this.showWait = false
                    el.innerHTML += 'Error - Do Not Honour'
                }
            }
        }
    }
}
</script>

<style scoped>
.psp-interface {
    min-width: 440px;
    min-height: 400px;
    margin-top: 10px;
    padding: 20px;
    background: white;
}

.psp-content h1 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.psp-content h2 {
    margin: 20px 0;
    font-size: 14px;
    font-weight: normal;
    color: #555;
}

.card-container {
    position: relative;
}

.card-img {
    width: 400px;
}

.card-input {
    position: absolute;
    background: var(--background-dark);
    border: 1px solid var(--border);
    border-radius: 3px;
    font-size: 1.3em;
    padding: 5px;
    text-align: center;
}

.psp-errors {
    margin: 20px 0;
    color: red;
}

.send {
    margin-top: 10px;
    padding: 10px 40px;
    border-radius: 6px;
    border: 0;
    background-color: #555;
    color: white;
}

.psp-loader, .psp-wait {
    text-align: center;
}

</style>