<template>
    <h3 class="page-title title__large">
        <slot name="left-icon"/>
        {{title}}
        <div class="slot-wrapper">
            <slot name="icon"/>
        </div>
    </h3>
</template>

<script>
export default {
    name: "BasePageTitle",
    props: {
        title: {
            type: String,
        }
    }
}
</script>

<style scoped>
.page-title {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--System-OnSurface);
    padding: 24px 0 16px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.slot-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
