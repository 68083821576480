<template>
    <div class="pending">
        <div class="value-container">
            <BaseNumberInput 
                :title="tokens.pendingModal.assetPrice" 
                type="number"
                :placeholder="tokens.pendingModal.current" 
                v-model="values.price" 
                :validateFunction="updateInput" />
            <BaseButton 
                class="minus" 
                :buttonStyle="'outlined-primary'" 
                @click.native="changePrice(0)">
                    <template #icon>
                        <i class="material-symbols-outlined">remove</i>
                    </template>
            </BaseButton>
            <BaseButton 
                class="plus" 
                :buttonStyle="'outlined-primary'"
                @click.native="changePrice(1)">
                    <template #icon>
                        <i class="material-symbols-outlined">add</i>
                    </template>
            </BaseButton>
        </div>
        <div class="value-container">
            <div class="block-bottom">
                <BaseButton 
                    :buttonText="tokens.pendingModal.revert" 
                    :buttonStyle="'outlined-primary'"
                    @click.native="values.price = ''" />
                <span class="info body__small">{{ tokens.pendingModal.automatically }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { MODAL, MODAL_PENDING } from '@/store/mutations.type'
import { mapGetters } from 'vuex'
import { isMobile } from '@/common/helpers.js'
import BaseNumberInput from "@/new-design/shared/ui/Inputs/BaseNumberInput";
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton.vue';

export default {
    name: 'PendingBlock',
    components: {
        BaseNumberInput, BaseButton
    },
    props: {
        data: {
            type: Object
        }
    },
    data() {
        return {
            values: {
                symbolActive: this.data.symbolActive,
                price: this.data.price
            }
        }
    },
    computed: {
        ...mapGetters(['modals', 'modalPending', 'tokens', 'symbols', 'symbolsActive']),
        currentSym() {
            /* logic dont work now , symbolsActive getter not defined */
           /*  if (this.values.symbolActive === undefined || this.values.symbolActive === null || !this.symbolsActive[this.values.symbolActive]) {
                return {
                    precision: 0,
                    quote: {
                        bid: 0
                    }
                }
            }

            return this.symbols[this.symbolsActive[this.values.symbolActive].symbol] */
            return {
                    precision: 0,
                    quote: {
                        bid: 0
                    }
                }
        },
    },
    methods: {
        changePrice(dir) {
            if (!this.values.price) {
                this.values.price = this.currentSym.quote.bid
            }

            if (dir) {
                this.values.price = Number(this.values.price) + Math.pow(0.1, this.currentSym.precision)
            } else {
                this.values.price -= Math.pow(0.1, this.currentSym.precision)
                this.values.price = Math.max(0, this.values.price)
            }

            this.values.price = (this.values.price + Number.EPSILON).toFixed(this.currentSym.precision)
        },
        updateInput(e) {
            return e.replace(/,/g, "");
        },
        apply() {
            this.$store.commit(MODAL_PENDING, { event: 1 })
            this.$store.commit(MODAL, {
                pending: false
            })
        },
        isMobile
    },
   
    watch: {
        modalPending: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_PENDING, {
                    symbolActive: val.symbolActive,
                    price: val.price
                })

                this.apply()
            }
        }
    }
}
</script>

<style scoped>
.pending {
    padding: 8px 0 24px;
}

.title {
    padding: 20px;
    color: var(--System-OnSurface);
}

.value-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    padding: 5px 20px;
}

.plus i,
.minus i {
    font-size: 18px;
    padding: 2.5px 0;
    margin: 0 -5px;
}

.block-bottom {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    width: 100%;
}
.info {
    width: 100%;
    white-space: wrap;
}

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
