<template>
    <BaseButton
        :button-text="'Confirm Withdrawal'"
        :button-style="'filled-primary'"
        :disabled="disabled"
        @click.native="requestWithdrawal"
    />
</template>

<script>
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import {NOTIFICATION_ERROR} from "@/store/mutations.type"
import {MINIMUM_WITHDRAWAL} from "@/common/config"
import {WITHDRAWAL_REQUEST} from "@/store/actions.type"
import {mapGetters} from "vuex"

export default {
    name: "SendWithdrawal",
    components: {BaseButton},
    props: {
        disabled: {
            type: Boolean,
        },
        info: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        requestWithdrawal() {
            let amount = String(this.info.amount)
            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount)

            for (let n in this.withdrawals) {
                if (this.withdrawals[n].status === 0) {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_ONE_PENDING_WITHDRAWAL')
                    return
                }
            }

            if (isNaN(parseFloat(amount))) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                return
            }

            if (this.info.method === 'bitcoin') {
                if (amount * this.symbols['BTCUSD'].quote.bid < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            } else if (this.info.method === 'bank' || this.info.method === 'card') {
                if (amount < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            }

            let details = ''
            switch (this.info.method) {
                case 'bitcoin':
                    details = this.info.wallet
                    break
                case 'bank':
                    details =
                        `Account: ${this.methods.bank.account}\nAccount holder: ${this.methods.bank.holder}${this.methods.bank.iban ? `\nIBAN: ` + this.methods.bank.iban : ''}${this.methods.bank.swift ? '\nSWIFT: ' + this.methods.bank.swift : ''}${this.methods.bank.recipientAddress ? '\nRecipient Address: ' + this.methods.bank.recipientAddress : ''}${this.methods.bank.routingNumber ? '\nRouting Number: ' + this.methods.bank.routingNumber : ''}${this.methods.bank.bankBeneficiary ? '\nBank of Beneficiary Name: ' + this.methods.bank.bankBeneficiary : ''}${this.methods.bank.bankAddress ? '\nBank Address: ' + this.methods.bank.bankAddress : ''}${this.methods.bank.bankCode ? '\nBank code (BSB): ' + this.methods.bank.bankCode : ''}${this.methods.bank.desc ? '\nDescription: ' + this.methods.bank.desc : ''}`
                    break
                case 'card':
                    details =
                        `Card: ${this.info.cardNumber.number}\nCard holder: ${this.info.name}`
                    break
                case 'ethereum':
                    details = `ETH: ${this.methods.btc.wallet}`
                    break
                case 'usdt':
                    details = `USDT: ${this.methods.btc.wallet}`
                    break
                case 'USDC':
                    details = `USDC: ${this.methods.btc.wallet}`
                    break
                case 'matic':
                    details = `MATIC: ${this.methods.btc.wallet}`
                    break
                case 'zelle':
                    details = `Phone/Eamil:${this.methods.zelle.contact}\nFull Name Zelle: ${this.methods.zelle.name}`
                    break
            }

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                wallet: details,
                amount: amount,
                account_id: this.info.activeAccount.id,
                product: this.info.activeAccount.product,
                currency: this.info.activeAccount.currency
            })

            this.$emit('send')
        },

    },
    computed: {
        ...mapGetters(['withdrawals', 'symbols'])
    }
}
</script>


<style scoped>

</style>
