<template>
    <div>
        <div class="row-fs buttons-row">
            <div class="col">
                <div
                    class="item"
                    v-for="button in depositButtonsList.left"
                    :key="'button' + button.amount"
                    @click="changeAmount(button.amount)"
                >
                    {{ button.amountStr }}
                </div>
            </div>
            <div class="col">
                <div
                    class="item"
                    v-for="button in depositButtonsList.right"
                    :key="'button' + button.amount"
                    @click="changeAmount(button.amount)"
                >
                    {{ button.amountStr }}
                </div>
            </div>
        </div>
        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
        <div class="row-fs">
            <div class="cont">
                <input
                    :value="amount"
                    readonly
                    :style="{ 'pointer-events': 'none' }"
                />
                <select
                    class="currency fs"
                    :value="currency"
                    :class="{
                        activeSelect: activeSelect,
                        disabled: config.currencies.length <= 1,
                    }"
                    @click.stop="changeActiveSelect(activeSelect)"
                    @change="changeSelect"
                >
                    <option
                        v-for="currency in config.currencies"
                        :key="currency"
                    >
                        {{ currency }}
                    </option>
                </select>
            </div>
            <button class="fs-normal proceed" @click="makeDeposit">
                {{ tokens.fullScreenDeposit.continue }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: [
        "config",
        "depositButtonsList",
        "amount",
        "currency",
        "activeSelect",
    ],
    computed: { ...mapGetters(["tokens"]) },
    methods: {
        makeDeposit() {
            this.$emit("make-deposit");
        },
        changeAmount(value) {
            this.$emit("change-amount", value);
        },
        changeActiveSelect(value) {
            this.$emit("change-activeSelect", value);
        },
        changeSelect(value){
            this.$emit("change-select", value.target.value);
        }
    },
};
</script>

<style scoped>
.row-fs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    min-width: 300px;
}

.deposit .row-fs.stretch {
    grid-template-columns: repeat(2, max-content);
}

.aspin-design .deposit .row-fs.stretch {
    grid-template-columns: repeat(2, 1fr);
}

.deposit {
    display: flex;
    align-items: stretch;
    margin: 30px 0;
}

.aspin-design .deposit {
    gap: 30px;
}

.table-cont {
    overflow-x: scroll;
    height: 200px;
}

.cabinet th.fs {
    position: sticky;
    top: 0;
}

.amounts .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.methods {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.form-title.copy {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}

.form-title.copy i {
    font-size: 16px;
    color: var(--white-to-black);
    cursor: pointer;
}

.aspin-design .methods {
    max-height: 700px;
    min-width: min-content;
    overflow: auto;
    padding-right: 30px;
}

.aspin-design button {
    height: 100%;
}

.amounts,
.address {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.aspin-design .cabinet th {
    background-color: #000;
}

.light-theme .aspin-design .cabinet th {
    background-color: var(--aspin-background-active);
}

.amounts .buttons-row {
    align-items: stretch;
}

.amounts .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.amounts .item {
    color: var(--white-to-black);
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    padding: 15px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    margin: 0 5px 25px 0;
    width: 200px;
    transition: background-color ease-in-out 0.3s;
    background: var(--background-dark-mid);
}

.amounts .item:hover {
    background-color: var(--fs-border);
}

.address-generated {
    background: var(--background-dark-mid);
    font-size: 18px;
    color: var(--white);
    font-weight: 300;
    padding: 30px;
    display: grid;
    place-content: center;
    color: var(--white-to-black);
    text-align: center;
}

.amounts .currency {
    height: 50px;
    position: relative;
    margin-left: 5px;
    padding: 5px;
    color: var(--white-to-black);
    font-weight: bold;
    border-radius: 5px;
    border: none;
}

.amount {
    margin: 15px 0;
}

.cont {
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    height: 49px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    position: relative;
}

.row-fs .cont {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    place-content: center;
}

.aspin-design .amounts .item,
.aspin-design .row-fs .cont {
    border: 1px solid #34384c;
    border-radius: 50px;
    height: 45px;
}

.aspin-design .row-fs .cont {
    padding: 0 20px;
}

.cont .amount {
    margin: 3px 0 3px 5px;
}

.cont input {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    color: var(--white-to-black);
    padding-left: 10px;
    width: 100%;
    margin-top: 0;
}

.cont .sign {
    font-size: 1.5em;
    font-weight: 500;
}

.field-container input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.fullscreen-page-sub-title {
    display: flex;
    gap: 15px;
    align-items: center;
}

.disabled {
    pointer-events: none;
    user-select: none;
    background: none;
}

@media (max-width: 1378px) {
    .amounts .card label {
        display: inline-block;
    }
}

@media (min-width: 1000px) {
    .row-fs {
        gap: 1vw;
    }

    .deposit {
        margin: 1.5vw 0;
    }

    .aspin-design .amounts .item,
    .aspin-design .row-fs .cont {
        border: 0.1vw solid #34384c;
        border-radius: 10vw;
        height: 3.5vw;
    }

    .aspin-design .row-fs .cont {
        padding: 0 2.4vw;
    }

    .amounts,
    .address {
        margin-left: 1vw;
    }

    .address .form-title {
        margin-bottom: 1vw;
    }

    .amounts .item {
        border-radius: 0.5vw;
        border: 0.1vw solid var(--fs-border);
        padding: 0.8vw;
        font-size: 1vw;
        margin: 0 0 1vw 0;
        width: 100%;
    }
    .amounts .currency.fs {
        background-position: calc(100% - 0.35vw) calc(0.8vw),
        calc(100% - 0vw) calc(0.8vw), calc(100% - 3vw) 0.4vw;
    }

    .amounts .currency.fs.activeSelect {
        background-position: calc(100% - 0vw) 0.7vw, calc(100% - 0.35vw) 0.7vw,
        calc(100% - 1vw) 0.4vw;
    }

    .amounts .currency {
        height: 2vw;
        padding: 0.4vw 1vw;
        border-radius: 0.1vw;
        top: 50%;
        font-size: 1vw;
        position: absolute;
        right: 0.5vw;
        transform: translateY(-50%);
    }

    .cont {
        border-radius: 0.5vw;
        border: 0.1vw solid var(--fs-border);
        background: var(--background-dark-mid);
        height: 3vw;
        padding: 0 0.5vw;
        position: relative;
    }

    .proceed {
        height: auto;
    }

    .cont .amount {
        margin: 0.15vw 0 0.15vw 0.25vw;
    }

    .cont .sign {
        bottom: 0.55vw;
        font-size: 1.5vw;
    }
    .amount {
        font-size: 1vw;
        margin: 0.7vw 0;
    }

    .cont input {
        font-size: 1.3vw;
        height: 100%;
        padding-left: 0.3vw;
    }

    .other {
        gap: 2vw;
        height: 100%;
    }

    i {
        font-size: 1.25vw;
    }

    .address-generated {
        padding: 7vw 12vw;
        border-radius: 0.5vw;
        font-size: 1vw;
    }

    .form-container input {
        border: 0.1vw solid var(--fs-border);
    }
}

@media (max-width: 1000px) {
    .bank-steps li {
        width: 100%;
        padding: 10px;
    }

    .form-title {
        margin-top: 20px;
    }

    .copy {
        margin-left: 0;
        margin-top: 15px;
    }

    .cont .sign {
        bottom: 12px;
    }

    .amounts .item {
        margin: 0 0 25px 0;
    }

    .row-fs {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        min-width: 15vw;
    }

    .bank-currency {
        right: 10px;
        top: 5px;
        width: 80px;
    }

    .proceed {
        height: 49px;
    }

    .amounts .currency {
        height: 40px;
        width: 50px;
    }

    .amounts .currency.fs {
        margin-left: 0;
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .deposit {
        flex-wrap: wrap;
    }

    .methods {
        width: 100%;
        margin-bottom: 20px;
    }

    .amounts {
        margin-left: 0 !important;
        width: 100%;
    }

    .amounts .col {
        flex: 1 1 calc(50% - 10px);
    }

    .amounts .col:first-child {
        margin-right: 0;
    }

    .amounts .item {
        width: auto;
    }

    .proceed,
    .cont {
        width: 100%;
    }

    .address {
        margin-left: 0;
    }

    .currency.fs {
        background-position: calc(100% - 5px) calc(15px),
        calc(100% - 0px) calc(15px), calc(100% - 25px) 4px;
    }

    .currency.fs.activeSelect {
        background-position: calc(100% - 0px) 15px, calc(100% - 5px) 15px,
        calc(100% - 1px) 3px;
    }

    .qrcode img {
        background: var(--fs);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        padding: 20px;
    }

    .certificate {
        max-width: 700px;
    }

    .certificate img {
        max-width: 150px;
        width: 100%;
    }
}
</style>
