<template>
    <div class="verification-page">
        <BasePageTitle :title="'Verification'" />
        <BasePanel>
            <template #content>
                <ProgressChipsList 
                    :categoriesItems="categoriesItems" 
                    :active="activeItem"
                    @update="(e) => changeActiveitem(e)" />
                <div class="inner" 
                    v-for="tab in categoriesItems.filter((data) => data.id === activeItem)" 
                    :key="tab.id">
                        <div class="info" v-html="infoText[activeItem]"></div>
                        <div class="update-document-list">
                            <UpdateDocument 
                                v-for="(docData, i) in documentsData.filter(data => data.tabId === activeItem)"
                                :key="'docData' + i" 
                                :document="docData" 
                                :idx="i" />
                        </div>
                </div>
                <!-- not found in the new design -->
                <!-- <div v-if="questions.length > 0 && !disableQuestions" class="questions hide">
            <div class="row">
                <div class="form-row fs">
                    <div class="form-title">{{ tokens.fullScreenVerification.questionnaire.toUpperCase() }}</div>
                </div>
            </div>

            <div class="row">
                <div class="questions-item">
                    <div v-for="question in questions" :key="question.id">
                        <div class="form-row fs">
                            <label class="fs">{{ question.question }}</label>
                            <input type="text" class="form-control fs" v-model="question.answer">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row fs">
                <button class="fs-normal" v-on:click="sendAnswers">{{
                    tokens.fullScreenVerification.sendAnswers
                }}
                </button>
            </div>
        </div> -->
                <div class="documents-table">
                    <div class="table-title label__largeprominent">{{ tokens.fullScreenVerification.documentsList }}</div>
                    <div class="table-desc body__extrasmall">To complete the verification process for your account, please provide clear and legible copies of the following documents</div>
                    <UploadedDocumentList />
                </div>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NOTIFICATION } from "@/store/mutations.type"
import RestService from "@/common/rest.service"
import {
    BRAND,
    KYC_DOCUMENTS,
    NEW_KYC_DOCUMENT_TEXT,
    DISABLE_QUESTIONS
} from "@/common/config"
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import ProgressChipsList from "@/new-design/entities/Verification/ui/ProgressChipsList"
import UpdateDocument from "@/new-design/features/Verification/ui/UpdateDocument"
import UploadedDocumentList from "@/new-design/entities/Verification/ui/UploadedDocumentList.vue";

export default {
    name: "PersonalInformationPage",
    components: { BasePageTitle, BasePanel,ProgressChipsList,UpdateDocument, UploadedDocumentList },
    data() {
        return {
            kycDocuments: KYC_DOCUMENTS,
            newVerificationText: NEW_KYC_DOCUMENT_TEXT,
            disableQuestions: DISABLE_QUESTIONS,
            brand: BRAND,
            activeItem: 'identify',
            infoText: {
                identify:
                    `Government-issued photo ID (e.g., passport, driver's license , national ID card)<br />
                    Residence permit (if applicable)<br />
                    The document must be valid (not expired)<br />
                    The full name, date of birth, and photograph must be visible.`,
                address:
                    `Upload a recent utility bill, bank statement, or rental agreement issued within the last 3 months<br />
                    Ensure your full name and current residential address are clearly visible.`,
                card:
                    `Upload a copy of the front side of your credit card, ensuring the first six and last four digits are visible<br />
                    Please cover the middle digits for security`,
                selfie:
                    `Take and upload a selfie holding your ID next to your face<br />
                    Ensure both your face and the ID are clearly visible`,
                other:
                    `Other documents`,
            }
        }
    },
    computed: {
        ...mapGetters(['documents', 'tokens', 'questions']),
        documentsData() {
            let resultingArray = [],
                docs = this.documents,
                over = false,
                status,
                type,
                tabId

            this.kycDocuments.forEach((value) => {
                // let typeToken = this.tokens.fullScreenVerification[this.newVerificationText ? 'types2' : 'types'][value]

                let docsOfType = docs.filter(doc => doc.type === value)
                docsOfType.sort((a, b) => a.id - b.id)
                let lastDocOfType = docsOfType[docsOfType.length - 1]

                status = lastDocOfType ? lastDocOfType.status : -1
                type = value

                switch (type) {
                    case 0:
                    case 4:
                        tabId = 'identify'
                        break;
                    case 1:
                        tabId = 'address'
                        break;
                    case 2:
                    case 3:
                        tabId = 'card'
                        break;
                    case 5:
                        tabId = 'selfie'
                        break;
                    case 6:
                        tabId = 'other'
                        break;
                }

                let resultingDoc = { over, status, type, tabId, text: this.getCategoryAndText(type).text, category: this.getCategoryAndText(type).category }

                resultingArray.push(resultingDoc)
            })

            return resultingArray
        },
        categoriesItems() {
            let categoriesItems = [
                {
                    id: 'identify',
                    title: 'Identify',
                    documentCount: 0,
                    uploadedCount: 0,
                },
                {
                    id: 'address',
                    title: 'Address',
                    documentCount: 0,
                    uploadedCount: 0,
                },
                {
                    id: 'card',
                    title: 'Credit Card',
                    documentCount: 0,
                    uploadedCount: 0,
                },
                {
                    id: 'selfie',
                    title: 'Your Selfie',
                    documentCount: 0,
                    uploadedCount: 0,
                },
                {
                    id: 'other',
                    title: 'Other',
                    documentCount: 0,
                    uploadedCount: 0,
                },
            ]
            this.documentsData.forEach((i) => {
                if (i.tabId === categoriesItems[0].id) {
                    categoriesItems[0].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[0].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[1].id) {
                    categoriesItems[1].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[1].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[2].id) {
                    categoriesItems[2].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[2].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[3].id) {
                    categoriesItems[3].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[3].uploadedCount++
                    }
                } else if (i.tabId === categoriesItems[4].id) {
                    categoriesItems[4].documentCount++
                    if (i.status === 1 || i.status === 0) {
                        categoriesItems[4].uploadedCount++
                    }
                }
            })

            return categoriesItems.filter(i => i.documentCount > 0)
        },
    },
    methods: {
        sendAnswers() {
            let answersArray = []
            for (let n in this.questions) {
                answersArray.push({ question_id: this.questions[n].id, answer: this.questions[n].answer })
            }

            RestService.post('/kyc/questions', { answers: answersArray })
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Answers have been saved')
                })
        },
        changeActiveitem(id) {
            this.activeItem = id
        },
        getCategoryAndText(type) {
            let category, text;

            switch (type) {
                case 0:
                    category = 'Identify'
                    text = 'Front Side'
                    break;
                case 4:
                    category = 'Identify'
                    text = 'Back Side'
                    break;
                case 1:
                    category = 'Address'
                    text = 'Front side of the document'
                    break;
                case 2:
                    category = 'Credit Card'
                    text = 'Front Side'
                    break;
                case 3:
                    category = 'Credit Card'
                    text = 'Back Side'
                    break;
                case 5:
                    category = 'Selfie'
                    text = 'Front Side'
                    break;
                case 6:
                    category = 'Other'
                    text = 'Front side of the document'
                    break;
            }

            return { category, text }
        },
    }
}
</script>

<style scoped>
.panel {
    border: none !important;
    padding: 0;
}

.inner {
    padding: 16px 0;
}

.info {
    margin-bottom: 16px;
    line-height: 1.6;
}

.update-document-list {
    display: flex;
    gap: 16px;
}

@media (max-width: 768px) {
    .update-document-list {
        flex-direction: column;
    }
}

.documents-table {
    padding-top: 24px;
}

.table-title {
    margin-bottom: 5px;
    color: var(--System-OnSurface);
}

.table-desc {
    margin-bottom: 20px;
}
</style>
