<template>
    <button class="start-trading">
        <span class="state-layer label__large">
            {{ tokens.fullScreen.startTrading }}
        </span>
    </button>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "BaseStartTradingButton",
    computed: {
        ...mapGetters(['tokens'])
    }
}
</script>

<style scoped>
.start-trading {
    background: var(--System-SecondaryContainer);
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
    color: var(--System-OnSecondaryContainer);
    font-weight: 600;
}

.state-layer {
    display: inline-block;
    padding: 8px 16px;
}

.start-trading:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.start-trading:hover .state-layer {
    background: var(--Statelayers-onsecondarycontainer-opacity08);
}

.start-trading:focus .state-layer {
    background: var(--Statelayers-onsecondarycontainer-opacity12);
}

.start-trading:active .state-layer {
    background: var(--Statelayers-onsecondarycontainer-opacity16);
}
</style>
