<template>
    <div class="card-inner">
        <div class="info">
            <div class="list body__medium">
                <div class="item">
                    <div>{{ tokens.balanceModal.balance }}</div>
                    <div class="val-large">
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.balance > 0 ?
                            balances.balance : 0).toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.credit }}</div>
                    <div class="val">
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.credit > 0 ? balances.credit : 0).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.invested }}</div>
                    <div class="val">
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.orders * (1 / conversions[activeAccount.currency])).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ balances.profit >= 0 ? tokens.balanceModal.profit :
                        tokens.balanceModal.loss }}</div>
                    <div class="val" :class="balances.profit > 0 ? 'green' : (balances.profit < 0 ? 'red' : '')">
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.profit).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.equity }}</div>
                    <div class="val-large">{{ currencySign(activeAccount.currency) }}
                        {{ (balances.equity_apprx).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.margin }}</div>
                    <div class="val">
                        {{ currencySign(activeAccount.currency) +
                            (balances.margin).toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.marginLevel }}</div>
                    <div class="val-large">
                        {{ balances.margin !== 0 ? balances.margin_level_apprx.toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) + '%' : '-' }}
                    </div>
                </div>
                <div class="item">
                    <div>{{ tokens.balanceModal.marginFree }}</div>
                    <div class="val-large">
                        {{ balances.margin !== 0 ? currencySign(activeAccount.currency) +
                            balances.free_margin_apprx.toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) : '-' }}
                    </div>
                </div>
            </div>
            <div class="item-link">
                <div class="account-link label__small">
                    <a href="#!">
                        {{ activeAccount.demo ?
                            tokens.balanceModal.practice :
                            tokens.balanceModal.real
                        }}
                        {{ tokens.balanceModal.account }} #{{ activeAccount.id }}
                        <i class="material-symbols-outlined">open_in_new</i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { currencySign } from "@/common/helpers";

export default {
    name: "AccountListItemInfo",
    methods: { currencySign },
    computed: {
        ...mapGetters(['activeAccount', 'symbols', 'tokens', 'conversions']),
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                margin: 0,
                // equity: 0,
                margin_level_apprx: 0,
                free_margin_apprx: 0,
                equity_apprx: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for (let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision) + o.swap + o.commission) : 0
            }

            for (let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n]) * (1 / this.conversions[this.activeAccount.currency])
            }

            b.equity = b.balance + b.credit + b.profit
            b.margin_level_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit !== 0 && b.margin ? ((b.equity / b.margin) * 100).toFixed(2) : ''
            b.free_margin_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit - b.margin
            b.equity_apprx = b.equity

            return b
        },
    }
}
</script>

<style scoped>
.info {
    padding: 0 20px;
    border-left: 1px solid var(--System-OutlineVariant);
    position: absolute;
    top: 70px;
    right: 0;
    width: 250px;
}

.list {
    display: flex;
    flex-direction: column;
}

.list .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;
}

.list .item .val,
.list .item .val-large {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17ch;
}

.list .item .val-large {
    color: var(--System-OnSurface)
}

.account-link a {
    color: var(--System-OnSurfaceVariant);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.account-link i {
    font-size: 16px;
    margin-bottom: 2px;
}

.red {
    color: var(--System-Error);
}

.item-link .account-link {
    display: none;
}

@media (max-width: 767px) {
    .info {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border-left: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: var(--System-Surface2);
        padding: 10px 20px 0;
    }

    .item-link .account-link {
        display: block;
        border-top: 1px solid var(--System-OutlineVariant);
        padding: 20px 0;
        margin-top: 10px;
    }

    .item-link .account-link a {
        justify-content: start;
    }
}
</style>
