<template>
    <h3 class="icon-title title__medium">
        <span v-if="!$slots['icon']" class="material-symbols-outlined">{{materialIcon}}</span>
        <slot v-else name="icon"/>
        {{title}}
    </h3>
</template>

<script>
export default {
    name: "BaseIconTitle",
    props: {
        title: {
            type: String,
        },
        materialIcon: {
            type: String
        }
    }
}
</script>

<style scoped>
.icon-title {
    color: var(--System-OnSurfaceVariant);
    display: inline-flex;
    align-items: center;
    gap: 13px;
}
</style>
