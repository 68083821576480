<template>
    <div class="widget">
        <BaseWidgetTitle :title="tokens.widgetMarketNews.marketNews" @close="hideModal('news')"/>
        <NewsList/>
    </div>
</template>

<script>
import {MODAL, MODAL_MENU} from '@/store/mutations.type'
import {isMobile} from '@/common/helpers'
import {mapGetters} from 'vuex'
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue"
import NewsList from "@/new-design/entities/MarketNews/ui/NewsList.vue"

export default {
    name: 'MarketNews',
    components: {NewsList, BaseWidgetTitle},
    computed: {
        ...mapGetters(['tokens', 'modalMenu'])
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
