import { render, staticRenderFns } from "./BaseModalChipsList.vue?vue&type=template&id=cfd7720a&scoped=true"
import script from "./BaseModalChipsList.vue?vue&type=script&lang=js"
export * from "./BaseModalChipsList.vue?vue&type=script&lang=js"
import style0 from "./BaseModalChipsList.vue?vue&type=style&index=0&id=cfd7720a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfd7720a",
  null
  
)

export default component.exports