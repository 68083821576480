<template>
    <div class="modal-otp" :class="{hide: !modals.otpCode}"
         @click.stop="hideModal">
        <div class="modal ElevationDark__3" @click.stop>
            <div class="title">
                <span class="title__large">Verify Your OTP</span>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row code">
                        <BaseInput
                            v-model="otpCode"
                            :title="'OTP'"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                        <BaseInput
                            v-model="otpCode2"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                        <BaseInput
                            v-model="otpCode3"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                        <BaseInput
                            v-model="otpCode4"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                        <BaseInput
                            v-model="otpCode5"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                        <BaseInput
                            v-model="otpCode6"
                            :show-icons="false"
                            :mask="'#'"
                            :placeholder="'0'"
                            :id="'otp'"
                        />
                    </div>
                    <span class="body__small subdesc">Enter the 6-digit code sent to your [email/phone]</span>
                </div>
            </div>
            <div class="buttons">
                <BaseButton
                    :button-text="'Cancel'"
                    :button-style="'text-primary'"
                    @clicl.native="hideModal()"
                />
                <BaseButton
                    :button-text="'Submit'"
                    :disabled="!!(!otpCode || !otpCode2 || !otpCode3 || !otpCode4 || !otpCode5 || !otpCode6)"
                    :button-style="'filled-primary'"
                    @clicl.native="sendOtp()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from "@/store/mutations.type"
import {DEPOSIT_OTP} from "@/store/actions.type";
import {OTP_CONFIRM} from "@/common/config";
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue";

export default {
    name: "OtpModal",
    components: {BaseButton, BaseInput},
    data() {
        return {
            otpCode: '',
            otpCode2: '',
            otpCode3: '',
            otpCode4: '',
            otpCode5: '',
            otpCode6: '',
            otpConfirm: OTP_CONFIRM
        }
    },
    methods: {
        clearCode() {
            this.otpCode = ''
            this.otpCode2 = ''
            this.otpCode3 = ''
            this.otpCode4 = ''
            this.otpCode5 = ''
            this.otpCode6 = ''
        },
        sendOtp() {
            if (this.otpCode) {
                let code = this.otpCode + this.otpCode2 + this.otpCode3 + this.otpCode4 + this.otpCode5 + this.otpCode6
                this.$store.dispatch(DEPOSIT_OTP, code)
                this.hideModal()
                this.clearCode()
            }
        },
        hideModal() {
            this.clearCode()
            this.$store.commit(MODAL, {
                otpCode: false
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal i {
    text-align: right;
}

.modal {
    width: 328px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--System-Surface3);
    border-radius: var(--Corner-small);
    z-index: 1100;
    position: absolute;
}

.title {
    color: var(--System-OnSurface);
    padding: 24px;
}

.title span:first-child {
    text-transform: uppercase;
    font-size: 16px;
}

.confirm {
    font-size: 11px;
}

.title i {
    font-size: 19px;
}

label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.form-row:last-child {
    margin-bottom: 0;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.code {
    display: grid;
    grid-template-columns: repeat(6, 40px);
    gap: 8px;
    place-items: end;
    padding: 8px 24px 0;
}

.subdesc {
    color: var(--System-OnSurfaceVariant);
    border-bottom: 1px solid var(--System-OutlineVariant);
    padding: 0 24px 66px;
    margin-bottom: 24px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 24px 24px;
}
</style>
