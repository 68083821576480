<script>
export default {
    name: "BaseTradingChips",
    props: {
        label: {
            type: String,
        },
        icon: {
            type: String
        },
        symbol: {
            type: String
        }
    },
    methods: {
        assetSymbol(symbol) {
            if (this.brand === 'cryptoalgoritm') {
                switch (symbol) {
                    case 'SUGAR':
                        return 'symbol-CAPITALSUGAR'
                    case 'DIS':
                        return 'symbol-CAPITALSUGAR'
                    default:
                        return 'symbol-' + symbol.toUpperCase().replace('.', '-')
                }
            } else {
                return 'symbol-' + symbol.toUpperCase().replace('.', '-')
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<template>
    <div class="trading-chips">
        <div class="state-layer">
            <div class="symbol" :class="assetSymbol(symbol)" />
            <span class="label label__large">{{label}}</span>
            <i class="close material-symbols-outlined" @click.stop="close()">close</i>
        </div>
    </div>
</template>

<style scoped>
.trading-chips {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface1);
    cursor: pointer;
}

.state-layer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
}

.symbol {
    zoom: .3;
}

.trading-chips:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.trading-chips:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.trading-chips.active {
    background: var(--System-Surface5);
    color: var(--System-Primary);
}

.trading-chips.active:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.trading-chips.active:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.close {
    font-size: 12px;
    font-weight: bold;
    color: var(--System-OnSurfaceVariant);
}
</style>
