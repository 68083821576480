<template>
    <li class="aside-menu-item" :class="{ active, logout: materialIcon === 'logout' }">
        <div>
            <i v-if="materialIcon" class="material-symbols-outlined">{{ materialIcon }}</i>
            <slot name="icon" />
        </div>
        <span class="title__small">{{ text }}</span>
    </li>
</template>

<script>
export default {
    name: "AsideMenuItem",
    props: {
        materialIcon: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        active: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style>
.aside-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 20px;
    gap: 16px;
    color: var(--System-OnSurfaceVariant);
    transition: all .3s ease;
    cursor: pointer;
}

.aside-menu-item:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.aside-menu-item.logout {
    border-top: 1px solid var(--System-OutlineVariant);
}

.aside-menu-item div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.aside-menu-item.active {
    color: var(--System-Primary);
    background-color: var(--System-Surface5);
}

.aside-menu-item.active svg path {
    fill: var(--System-Primary);
}
</style>
