<script>
export default {
    name: "BaseModalChips",
}
</script>

<template>
    <div class="modal-chips">
        <div class="state-layer label__large">
            <slot name="content"/>
        </div>
    </div>
</template>

<style scoped>
.modal-chips {
    border: 1px solid var(--System-OutlineVariant);
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
}

.modal-chips.active {
    background: var(--System-Surface5);
    color: var(--System-Primary);
}

.modal-chips:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.modal-chips path {
    fill: var(--System-OnSurfaceVariant);
}

.modal-chips.active path {
    fill: var(--System-Primary);
}

.state-layer {
    display: flex;
    padding: 5px 8px;
    height: 28px;
}

.label__large {
    text-transform: capitalize;
    font-weight: 500;
}
</style>
