<template>
    <div class="tab">
        <div class="icon">
            <slot name="icon"/>
        </div>
        <div class="info">
            <div class="title">
                <slot name="title"/>
            </div>
            <div class="desc">
                <slot name="desc"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTabBox",
    props: {
        tab: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.tab {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: var(--Corner-Extra-small);
    border: 1px solid var(--System-Surface5);
    background: var(--System-Surface1);
    cursor: pointer;
}

.title {
    color: var(--System-OnSurface);
}

.desc {
    color: var(--System-Outline);
}

.icon {
    fill: var(--System-Outline);
}

.icon path {
    fill: var(--System-Outline);
}

.tab.active {
    border: 1px solid var(--System-Primary);
}

.tab.active .icon {
    color: var(--System-Primary);
}

.tab.active .icon path {
    fill: var(--System-Primary);
}

.tab.active .title {
    color: var(--System-Primary);
}

</style>
