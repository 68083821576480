<template>
    <div class="update-password">
        <BaseIconTitle
            :title="tokens.fullScreenSettings.updatePassword"
            :material-icon="'password'"
        />
        <BaseInput
            v-model="currentPassword"
            :title="tokens.fullScreenSettings.currentPassword"
            :type="'password'"
        />
        <BaseInput
            v-model="newPassword"
            :title="tokens.fullScreenSettings.newPassword"
            :type="'password'"
        />
        <BaseInput
            v-model="confirmNewPassword"
            :title="tokens.fullScreenSettings.confirmNewPassword"
            :type="'password'"
        />
        <div class="change-password">
            <BaseButton
                :button-text="tokens.fullScreenSettings.changePassword"
                :button-style="'filled-primary'"
                @click.native="changePassword()"
            />
        </div>
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import {mapGetters} from "vuex"
import {NOTIFICATION_ERROR} from "@/store/mutations.type"
import {PASSWORD_CHANGE} from "@/store/actions.type"

export default {
    name: "UpdatePassword",
    components: {BaseButton, BaseInput, BaseIconTitle},
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    },
    methods: {
        changePassword() {
            if (this.newPassword !== this.confirmNewPassword) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_PASSWORDS_NOT_MATCH')
                return
            }

            this.$store.dispatch(PASSWORD_CHANGE, {
                old_password: this.currentPassword.trim(),
                new_password: this.newPassword.trim()
            })

            this.currentPassword = ''
            this.newPassword = ''
            this.confirmNewPassword = ''
        }
    }
}
</script>

<style scoped>
.icon-title {
    padding-bottom: 16px;
}

.input-container {
    margin-bottom: 24px;
}

.input-container:last-child {
    margin-bottom: 16px;
}

.change-password {
    width: 100%;
    text-align: right;
    padding-top: 16px;
}
</style>
