<template>
    <div class="language" @click="toggler()">
        <BaseIconTitle
            :title="'Language'"
            :material-icon="'language'"
        />

        <BaseSelectModalBox
            :placeholder="rawTokens[langActive] ? rawTokens[langActive]._name : ''"
            :options="Object.entries(languages).map(([key, value]) => ({
                    key: value,
                    title: rawTokens[value] ? rawTokens[value]._name : '',
                }))
            "
            v-model="lang"
            :state="state"
            @update="changeLang"
        >
            <template #option="{option}">
                <div class="flag fs small-flag" :class="'flag-'+option.key"></div>
                <div class="name">{{ option.title }}</div>
            </template>
        </BaseSelectModalBox>
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import toggler from "@/new-design/shared/mixins/toggler"
import BaseSelectModalBox from "@/new-design/shared/ui/Inputs/BaseSelectModalBox.vue"
import {mapGetters} from "vuex"
import {LANG} from "@/store/mutations.type"

export default {
    name: "PlatformLanguage",
    components: {BaseSelectModalBox, BaseIconTitle},
    mixins: [toggler],
    computed: {
        lang: {
            get() {
                return this.langActive
            },
            set(value) {
                this.$store.commit('SET_LANG_ACTIVE', value)
            }
        },
        ...mapGetters(['languages', 'rawTokens', 'langActive'])
    },
    methods: {
        changeLang(lang) {
            this.$store.commit(LANG, lang)
        }
    }
}
</script>

<style scoped>
.language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
</style>
