<template>
    <div class="modal-wrapper" :class="{hide: !modals.newPassword}" v-on:click.stop="hideModal">
        <div class="modal" v-on:click.stop>
            <div class="title">
                <span>{{tokens.newPasswordModal.setNewPassword.toUpperCase()}}</span>
                <i class="material-icons close pointer">plus</i>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <div class="show-hide-password">
                            <input type="password" ref="password" class="form-control" v-model="newPassword" :placeholder="tokens.newPasswordModal.newPassword">
                            <i class="material-icons"
                               @touchstart="showPass($refs.password)"
                               @touchend="hidePass($refs.password)"
                               @touchmove="hidePass($refs.password)"
                               @mousedown="showPass($refs.password)"
                               @mouseup="hidePass($refs.password)"
                               @dragover="hidePass($refs.password)"
                               @mouseout="hidePass($refs.password)">visibility</i>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="show-hide-password">
                            <input type="password" class="form-control" ref="password2" v-model="confirmedNewPassword" :placeholder="tokens.newPasswordModal.confirmNewPassword">
                            <i class="material-icons"
                               @touchstart="showPass($refs.password2)"
                               @touchend="hidePass($refs.password2)"
                               @touchmove="hidePass($refs.password2)"
                               @mousedown="showPass($refs.password2)"
                               @mouseup="hidePass($refs.password2)"
                               @dragover="hidePass($refs.password2)"
                               @mouseout="hidePass($refs.password2)">visibility</i>
                        </div>
                    </div>
                    <div>
                        <div class="button" :class="{'green-button': newPassword === confirmedNewPassword}" @click="setNewPassword">
                            {{tokens.newPasswordModal.setButton.toUpperCase()}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {PASSWORD_RESET} from '@/store/actions.type'
import {MODAL, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {hidePass, showPass} from "@/common/helpers";

export default {
    name: "NewPasswordModal",
    data() {
        return {
            newPassword: '',
            confirmedNewPassword: ''
        }
    },
    methods: {
        showPass,
        hidePass,
        setNewPassword() {
            if(this.newPassword === this.confirmedNewPassword) {
                this.$store.dispatch(PASSWORD_RESET, {
                    password: this.newPassword
                })
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_PASSWORDS_NOT_MATCH')
            }
        },
        hideModal() {
            this.$store.commit(MODAL, {
                newPassword: false
            })
        }
    },
    computed: {
        ...mapGetters(['modals', 'tokens'])
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.forgot {
    border-bottom: 1px dashed var(--font);
}
</style>
