<script>
export default {
    name: "BaseSwitch",
    props: {
        value: {
            type: [Number, Boolean],
        }
    },
    data() {
        return {
            state: this.value,
        }
    },
    methods: {
        update() {
            this.state = !this.state
            this.$emit("input", this.state)
        }
    }
}
</script>

<template>
    <div
        class="switch"
        :class="{active: state}"
        @click="update()"
    >
        <input type="checkbox" :value="state">
    </div>
</template>

<style scoped>
.switch {
    width: 40px;
    height: 24px;
    border-radius: 100px;
    background: var(--System-Surface5);
    position: relative;
    cursor: pointer;
}

.switch::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    width: 20px;
    height: 20px;
    background: var(--System-Outline);
    border-radius: 100%;
    transition: all .3s ease-in-out;
}

.switch.active {
    background: var(--System-Tertiary);
}

.switch.active::after {
    background: var(--System-TertiaryContainer);
    left: calc(100% - 22px);
}

.switch:focus-within:before,
.switch:hover::before { 
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6px;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    background: var(--Statelayers-onsurface-opacity08);
    transition: all .3s ease-in-out;
}

.switch.active:focus-within:before,
.switch.active:hover::before {
    left: calc(100% - 30px);
}

input[type="checkbox"] {
    opacity: 0;
    z-index: -10;
}
</style>
