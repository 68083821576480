<template>
    <div class="support-modal">
        <div class="support-header">
            <div class="title">
                {{ tokens.fullScreen.liveChat.toUpperCase() }}
            </div>
            <div class="close-button" @click.stop="closeModal">
                <i class="material-icons">close_small</i>
            </div>
        </div>
        <div class="messages__inner">
            <div class="messages__content">
                <div class="messages" id="support-messages">
                    <div class="message"
                         v-for="message in supportMessages"
                         :key="message.id"
                         :class="'from-'+(message.manager_id ? 'manager' : 'client')"
                    >
                        <div class="text">
                            <div class="text-content" >
                                <div v-html="convertText(message.text)"></div>
                                <div v-if="['.jpg', '.png', 'jpeg'].indexOf(message.file.slice(-4)) > -1"
                                     @click="openImg(restEndpoint+'/kyc/document/'+message.file)"
                                     class="pic"
                                >
                                    <img :src="restEndpoint+'/kyc/document/'+message.file">
                                </div>
                                <a v-if="message.file.slice(-4) === '.pdf'"
                                   :href="restEndpoint+'/kyc/document/'+message.file"
                                   class="pdf" target="_blank"
                                >
                                    <i class="material-icons">attach_file</i> PDF attachment
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <div class="send" :class="{fileShow: previewShow}">
                <div v-show="previewShow" class="preview">
                    <img v-show="['image/jpeg', 'image/png'].indexOf(this.typeFile) > -1" :src="preview" alt="">
                    <span v-show="['application/pdf'].indexOf(this.typeFile) > -1">.PDF</span>
                </div>
                <label for="file" class="attach"><i class="material-icons">attach_file</i></label>
                <textarea maxlength="524288" :placeholder="`${tokens.fullScreenSupport.sendMessage}`" id="support-message" class="form-control fs"
                          v-model="message" @keyup.enter="sendMessage"/>
                <div>
                    <input type="file" id="file" ref="file" @change="loadPreview()">
                    <i class="material-icons" @click="sendMessage">send</i>
                </div>
            </div>
            <FsLightbox
                :toggler="toggler"
                :sources="path ? path : []"
            />
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {REST_ENDPOINT} from "@/common/config"
    import {ATTACHMENT_UPLOAD, SUPPORT_READ, SUPPORT_SEND} from '@/store/actions.type'
    import { NOTIFICATION_ERROR} from "@/store/mutations.type"
    import FsLightbox from "fslightbox-vue"

    export default {
        name: 'SupportModal',
        props: ['supportModalIsOpen'],
        components: {FsLightbox},
        computed: {
        ...mapGetters(['supportMessages', 'modalFullscreen', 'user', 'tokens'])
        },
        mounted(){
            setInterval(() => {
            if (this.modalFullscreen.page === 'support') {
                this.$store.dispatch(SUPPORT_READ)
            }
            }, 3000)
            setTimeout(() => {
                let container = document.getElementById("support-messages")
                container.scrollTop = container.scrollHeight
            }, 300)
        },
        data(){
            return {
                restEndpoint: REST_ENDPOINT,
                message: '',
                ddOver: false,
                toggler: false,
                path: [],
                preview: '',
                previewShow: false,
                typeFile: '',
            }
        },
        methods:{
            closeModal() {
                this.$emit('close-modal');
            },
            convertText(text) {
                let objRE = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                return text.replace(objRE, url => '<a style="color: var(--white); word-break: break-word;" target="_blank" href="' + url + '">' + url + '</a>')
            },
            sendMessage() {
                if (!this.message.trim()) {
                    return
                }
                // send message
                if (this.user.enable_chat === 0) {
                    this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.CHAT_DISABLED)
                    this.message = ''
                    this.$refs.file.value = ''
                    this.preview = ''
                    this.previewShow = false
                    return
                }

                if (this.message) {
                    this.$store.dispatch(SUPPORT_SEND, this.message)
                    this.message = ''
                }

                // send attached file:
                if (this.$refs.file.files[0]) {
                    if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(this.$refs.file.files[0].type) > -1) {
                        let data = new FormData()
                        data.append('file', this.$refs.file.files[0])
                        this.$store.dispatch(ATTACHMENT_UPLOAD, data)
                    } else {
                        this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                    }
                }

                this.$refs.file.value = ''
                this.preview = ''
                this.previewShow = false
            },
            over(val) {
                this.ddOver = val
            },
            addFile(e) {
                this.ddOver = false

                if (!e.dataTransfer.files) {
                    return
                }

                let data = new FormData()

                data.append('file', e.dataTransfer.files[0])

                this.$store.dispatch(ATTACHMENT_UPLOAD, data)
            },
            openImg(path) {
                this.path[0] = path
                this.toggler = !this.toggler
            },
            loadPreview() {
                let file = this.$refs.file.files[0]
                this.typeFile = file.type
                if (['image/jpeg', 'image/png'].indexOf(this.typeFile) > -1) {
                    this.preview = URL.createObjectURL(file);
                    this.previewShow = true
                } else if (['application/pdf'].indexOf(this.typeFile) > -1) {
                    this.typeFile = file.type
                    this.preview = ''
                    this.previewShow = true
                } else {
                    this.preview = ''
                    this.preview = false
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                }
            },
        },
        watch: {
            supportMessages() {
                setTimeout(() => {
                    let container = document.getElementById("support-messages")
                    container.scrollTop = container.scrollHeight
                }, 300)
            },
        modalFullscreen: {
            deep: true,
            handler(val) {
                if (val.page === 'support') {
                    setTimeout(() => {
                        let container = document.getElementById("support-messages")
                        container.scrollTop = container.scrollHeight
                        }, 300)
                    }
                }
            }
        }
    }
</script>

<style scoped>

.support-modal{
    width: 269px;
    height: 375px;
    background: rgba(19, 25, 36, 0.95);
    border: 1px solid rgba(140, 143, 150, 0.12);
    position: absolute;
    right: 40px;
    bottom: 40px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.light-theme .support-modal{
    background: var(--white);
    border: 1px solid rgba(251, 251, 251, 0.12);
}

.support-header{
    background-color: var(--new-chat-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vw ;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.title{
    font-size: 1vw;
}
.close-button{
    width: 25px;
    cursor: pointer;
}

.messages__inner {
    position: relative;
    height: 90%;
    overflow-y: scroll;
}
.messages__content {
    height: 100%;
}

.pic {
    height: 125px;
    cursor: pointer;
    position: relative;
    z-index: 3;
}
.pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 15px 5px;
    margin-bottom: 15px;
    height: 100%;
}
.message {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}
.message:last-child {
    margin-bottom: 0;
}
.message.from-manager {
    align-items: flex-start;
}
.message.from-client {
    align-items: flex-end;
}
.message.from-manager .text-content {
    background: var(--new-chat-color);
}
.text {
    display: grid;
    grid-template-columns: repeat(2, auto);
}.preview {
    border: 1px solid var(--fs-border);
    border-radius: 5px;
    padding: 3px;
    background-color: var(--new-chat-color);
    position: absolute;
    top: -120%;
    left: 5px;
    z-index: 7;
}
.preview span {
    font-weight: bold;
    font-size: 21px;
}
.preview img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.text-content {
    padding: 12px 15px;
    background: var(--background-dark-mid);
    color: var(--white-to-black);
    font-weight: 400;
    font-size: 1.1em;
    max-width: 100%;
    word-break: break-all;
    border-radius: 5px;
    position: relative;
}
.text-content span {
    position: absolute;
    width: 103%;
    height: 103%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}
.text-content {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
}
#file {
    display: none;
}
label.attach {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
label.attach i {
    transform: rotate(45deg);
}

i {
    font-size: 25px;
    cursor: pointer;
}

i:hover {
    color: var(--white-to-black);
}
.send.fileShow {
    grid-template-columns: auto auto 1fr auto;
}
.send {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 5px;
    padding: 5px 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    align-items: center;
    background-color: var(--new-chat-color);
    position: relative;
}
textarea {
    width: 100%;
    font-family: inherit;
}
.send button {
    font-size: 12px;
    font-weight: 400;
}
a.pic img {
    width: 200px;
}
a.pdf {
    color: var(--white);
}
textarea[name=support-message] {
    resize: none;
}
#support-message {
    resize: none;
    background: var(--background);
    height: 75%;
    background-color: var(--new-chat-color);
    font-size: 14px;
}

</style>