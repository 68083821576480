<template>
    <div class="select" :class="{ selected: value }" @click="toggler()">
        <i class="material-symbols-outlined">filter_list</i>
        <span class="title__small">
            <slot name="title"/>
        </span>
    </div>
</template>

<script>
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: 'PositionsSelectButton',
    mixins: [toggler],
    props: {
        value: {
            type: String
        }
    },
}
</script>

<style scoped>
.select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 4px 10px;
    background: var(--System-Surface1);
    border-radius: 4px;
    cursor: pointer;
    width: max-content;
    color: var(--System-OnSurfaceVariant);
    transition: all .3s ease;
}

.select.selected {
    color: var(--System-Primary);
    background: var(--System-Surface5);
}

.select:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.select i {
    font-size: 18px;
    display: block;
    margin-bottom: 2px;
}
</style>
