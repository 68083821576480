<template>
    <div class="base-modal-title">
        <div class="left-slot-wrapper">
            <slot name="left" />
        </div>
        <div class="right-slot-wrapper">
            <slot name="right" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseModalTitle"
}
</script>

<style scoped>
.base-modal-title { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap-reverse;
}
.left-slot-wrapper,
.right-slot-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

@media (max-width: 540px) {
    .right-slot-wrapper:has(.modal-title-right) {
        width: 100%;
        justify-content: flex-end;
    }
}
</style>
