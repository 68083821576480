<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.grids}"
         v-on:click.stop="hideModal">
        <div class="modal" v-on:click.stop>
            <div class="title">
                <span>{{ tokens.gridsModal.chartGrids }}</span>
            </div>
            <div class="content">
                <div class="line">
                    <div class="line-title">{{ tokens.gridsModal.chart1 }}</div>
                    <div class="grids">
                        <div class="grid" v-on:click="changeGrid('1-1')" :class="{active: gridLayout === '1-1'}">
                            <div class="col item"></div>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line-title">{{ tokens.gridsModal.chart2 }}</div>
                    <div class="grids">
                        <div class="grid" v-on:click="changeGrid('2-1')" :class="{active: gridLayout === '2-1'}">
                            <div class="col item"></div>
                            <div class="col item"></div>
                        </div>
                        <div class="grid col" v-on:click="changeGrid('2-2')" :class="{active: gridLayout === '2-2'}">
                            <div class="row item"></div>
                            <div class="row item"></div>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line-title">{{ tokens.gridsModal.chart3 }}</div>
                    <div class="grids">
                        <div class="grid col" v-on:click="changeGrid('3-1')" :class="{active: gridLayout === '3-1'}">
                            <div class="row item"></div>
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                        </div>
                        <div class="grid col" v-on:click="changeGrid('3-2')" :class="{active: gridLayout === '3-2'}">
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                            <div class="row item"></div>
                        </div>
                        <div class="grid" v-on:click="changeGrid('3-3')" :class="{active: gridLayout === '3-3'}">
                            <div class="col">
                                <div class="row item"></div>
                                <div class="row item"></div>
                            </div>
                            <div class="col item"></div>
                        </div>
                        <div class="grid" v-on:click="changeGrid('3-4')" :class="{active: gridLayout === '3-4'}">
                            <div class="col item"></div>
                            <div class="col">
                                <div class="row item"></div>
                                <div class="row item"></div>
                            </div>
                        </div>
                        <div class="grid" v-on:click="changeGrid('3-5')" :class="{active: gridLayout === '3-5'}">
                            <div class="col item"></div>
                            <div class="col item"></div>
                            <div class="col item"></div>
                        </div>
                    </div>
                </div>
                <div class="line">
                    <div class="line-title">{{ tokens.gridsModal.chart4 }}</div>
                    <div class="grids">
                        <div class="grid col" v-on:click="changeGrid('4-1')" :class="{active: gridLayout === '4-1'}">
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                        </div>
                        <div class="grid col" v-on:click="changeGrid('4-2')" :class="{active: gridLayout === '4-2'}">
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                            <div class="row item"></div>
                        </div>
                        <div class="grid col" v-on:click="changeGrid('4-3')" :class="{active: gridLayout === '4-3'}">
                            <div class="row item"></div>
                            <div class="row">
                                <div class="col item"></div>
                                <div class="col item"></div>
                                <div class="col item"></div>
                            </div>
                        </div>
                        <div class="grid" v-on:click="changeGrid('4-4')" :class="{active: gridLayout === '4-4'}">
                            <div class="col item"></div>
                            <div class="col">
                                <div class="row item"></div>
                                <div class="row item"></div>
                                <div class="row item"></div>
                            </div>
                        </div>
                        <div class="grid" v-on:click="changeGrid('4-5')" :class="{active: gridLayout === '4-5'}">
                            <div class="col">
                                <div class="row item"></div>
                                <div class="row item"></div>
                                <div class="row item"></div>
                            </div>
                            <div class="col item"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GRID_LAYOUT, MODAL} from "@/store/mutations.type";
    import {mapGetters} from "vuex";

    export default {
        name: "GridsModal",
        methods: {
            hideModal() {
                this.$store.commit(MODAL, {
                    grids: false
                })
            },
            changeGrid(val) {
                this.$store.commit(GRID_LAYOUT, val)
            }
        },
        computed: {
            ...mapGetters(['modals', 'gridLayout', 'tokens'])
        }
    }
</script>

<style scoped>
    .modal-wrapper {
        background: transparent;
    }

    .modal {
        position: fixed;
        z-index: 4;
        left: 230px;
        top: 81px;
        width: 370px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .title {
        padding: 15px;
        color: var(--white);
        font-weight: 600;
        font-size: 1.1em;
        background-color: var(--background);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        padding: 15px;
        background-color: var(--background-dark);
        font-weight: 400;
    }

    .line {
        width: 100%;
        border-bottom: 1px solid var(--border);
        display: flex;
        align-items: center;
        padding: 15px 5px;
    }

    .line:last-child {
        border-bottom: none;
    }

    .line-title {
        flex: 0 0 100px;
        font-size: 1.1em;
    }

    .grids {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .grid {
        height: 25px;
        flex: 0 0 25px;
        margin-right: 20px;
        cursor: pointer;
    }

    .grid .item {
        border-radius: 2px;
        background: var(--font);
        display: flex;
        transition: background-color ease-in-out 0.3s;
    }

    .grid:hover .item, .grid.active .item {
        background: var(--white);
    }

    .grid .col, .grid .row {
        flex: 1 1 auto
    }

    .grid .col {
        margin-right: 2px;
        align-items: stretch;
    }

    .grid .row {
        margin-bottom: 2px;
        align-items: stretch;
    }

    .grid .col:last-child {
        margin-right: 0;
    }

    .grid .row:last-child {
        margin-bottom: 0;
    }
</style>