<template>
    <div class="logo">
        <a
            :href="!logoRedirectOff ? website : '#'"
            :target="!logoRedirectOff ? '_blank' : ''"
            :style="{cursor: !logoRedirectOff ? '' : 'default', 'pointer-events': hideLogo ? 'none' : 'auto'}">
            <img
                :src="src"
                :style="{height: '16px', width: '100', opacity: hideLogo ? 0 : 1}"
                :class="{hide: !src}"
                @error="errorHandler"
            />
            <slot name="logo-placeholder"/>
        </a>
    </div>
</template>

<script>
import {HIDE_LOGO, LOGO_REDIRECT_OFF, WEBSITE} from "@/common/config"

export default {
    name: "BaseLogo",
    props: {
        src: {
            type: String
        },
        height: {
            type: String
        }
    },
    data() {
        return {
            logoRedirectOff: LOGO_REDIRECT_OFF,
            website: WEBSITE,
            hideLogo: HIDE_LOGO,
        }
    },
    methods: {
        errorHandler() {
            this.logo.url = ''
        }
    }
}
</script>

<style scoped>
.logo {
    padding: 10px;
    background: transparent;
    border-radius: 3px;
}

.logo:hover {
    background: var(--Statelayers-secondary-opacity08);
}

.logo:active {
    background: var(--Statelayers-secondary-opacity12);
}
</style>
