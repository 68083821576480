<script>
export default {
    name: "BaseAccountType",
    props: {
        accountType: {
            type: String,
        }
    },
    methods: {
        getAccountCLass(type) {
            switch (type.toLowerCase()) {
                case 'vip':
                    return 'vip'
                default:
                    return type
            }
        }
    }
}
</script>

<template>
    <div class="account-type" :class="getAccountCLass(accountType)">
        {{ accountType }} account
    </div>
</template>

<style scoped>
.account-type {
    padding: 2px 6px;
    border-radius: 3px;
    background: var(--Statelayers-secondary-opacity08);
    color: var(--System-OnPrimaryContainer);
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
}

.account-type.vip {
    background: linear-gradient(55deg, rgba(41, 151, 149, 0.30) 6.34%, rgba(58, 123, 204, 0.30) 97.55%);
}
</style>
