import {DARK_THEME} from "@/common/config"
import {baseDarkTheme} from "@/new-design/app/theme/Themes/baseDarkTheme"

const setDarkTheme = () => {
    switch (DARK_THEME) {
        default:
            return baseDarkTheme
    }
}

export const darkTheme = setDarkTheme()
