<template>
    <div class="personal-information-page">
        <BasePanel>
            <template #content>
                <div class="title title__medium">{{ tokens.fullScreen.personalInfo }}</div>
                <UpdatePersonalInfo/>
            </template>
        </BasePanel>
        <BasePanel>
            <template #content>
                <div class="title title__medium">{{ 'Profile Settings' }}</div>
                <UpdatePersonalAvatar/>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import UpdatePersonalInfo from "@/new-design/features/Personal/UpdatePersonalInfo/ui/UpdatePersonalInfo.vue"
import UpdatePersonalAvatar from "@/new-design/features/Personal/UpdatePersonalAvatar/ui/UpdatePersonalAvatar.vue"
export default {
    name: "PersonalInformationPage",
    components: {BasePanel,UpdatePersonalInfo,UpdatePersonalAvatar},
    computed: {
        ...mapGetters(['tokens']),
    }
}
</script>

<style scoped>
.panel {
    margin-top: 24px;
}

.title {
    margin-bottom: 16px;
    color: var(--System-OnSurface)
}
</style>
