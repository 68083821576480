<template>
    <div class="no-connection" :class="{hide: hideWindow}">
        <div class="upper">
            <i class="material-icons">wifi_off</i>
            <span>{{ tokens.noConnectionModal.connectionLost }}</span>
        </div>
        <button @click="refresh">{{ tokens.noConnectionModal.retryNow }}</button>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'NoConnectionModal',
        data() {
            return {
                timeout: null,
                hideWindow: true
            }
        },
        methods: {
            refresh() {
                location.reload()
            }
        },
        computed: {
            ...mapGetters(['socketState', 'tokens'])
        },
        watch: {
            socketState(val) {
                if (!val) {
                    if (this.timeout) {
                        return
                    }

                    this.timeout = setTimeout(() => {
                        this.hideWindow = false
                    }, 15000)
                } else {
                    if (this.timeout) {
                        clearTimeout(this.timeout)
                    }

                    this.hideWindow = true
                }
            }
        }
    }
</script>

<style scoped>
    .no-connection {
        position: absolute;
        bottom: 50px;
        left: 50px;
        width: 200px;
        display: flex;
        flex-direction: column;
        background: var(--background-dark);
        padding: 20px;
        border-radius: 5px;
        border: 1px solid var(--border);
    }

    .upper {
        display: flex;
        align-items: flex-start;
    }

    .upper span {
        color: var(--red);
    }

    i {
        margin-right: 20px;
    }

    button {
        margin-top: 10px;
    }
</style>
