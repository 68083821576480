var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auto-close"},[_c('div',{staticClass:"value-container"},[_c('BaseNumberInput',{attrs:{"title":_vm.tokens.autoCloseModal.takeProfit,"type":"number","placeholder":"0.0","validateFunction":_vm.updateInput},model:{value:(_vm.values.tp),callback:function ($$v) {_vm.$set(_vm.values, "tp", $$v)},expression:"values.tp"}}),_c('BaseButton',{staticClass:"minus",attrs:{"buttonStyle":'outlined-primary'},nativeOn:{"click":function($event){_vm.values.tp = _vm.values.tp ? 
                    (Math.max(0, parseFloat(_vm.values.tp) - Math.pow(0.1, _vm.modalSymbolActive.precision))).toFixed(_vm.modalSymbolActive.precision) : 
                    _vm.modalSymbolActive.quote.bid.toFixed(_vm.modalSymbolActive.precision)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("remove")])]},proxy:true}])}),_c('BaseButton',{staticClass:"plus",attrs:{"buttonStyle":'outlined-primary'},nativeOn:{"click":function($event){_vm.values.tp = _vm.values.tp ? 
                    (parseFloat(_vm.values.tp) + Math.pow(0.1, _vm.modalSymbolActive.precision)).toFixed(_vm.modalSymbolActive.precision) : 
                    _vm.modalSymbolActive.quote.bid.toFixed(_vm.modalSymbolActive.precision)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("add")])]},proxy:true}])})],1),_c('div',{staticClass:"value-container"},[_c('BaseNumberInput',{attrs:{"title":_vm.tokens.autoCloseModal.stopLoss,"type":"number","placeholder":"0.0","validateFunction":_vm.updateInput},model:{value:(_vm.values.sl),callback:function ($$v) {_vm.$set(_vm.values, "sl", $$v)},expression:"values.sl"}}),_c('BaseButton',{staticClass:"minus",attrs:{"buttonStyle":'outlined-primary'},nativeOn:{"click":function($event){_vm.values.sl = (_vm.values.sl ?
                    (Math.max(0, parseFloat(_vm.values.sl) - Math.pow(0.1, _vm.modalSymbolActive.precision))).toFixed(_vm.modalSymbolActive.precision) : 
                    _vm.modalSymbolActive.quote.ask.toFixed(_vm.modalSymbolActive.precision))}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("remove")])]},proxy:true}])}),_c('BaseButton',{staticClass:"plus",attrs:{"buttonStyle":'outlined-primary'},nativeOn:{"click":function($event){_vm.values.sl = _vm.values.sl ? 
                    (parseFloat(_vm.values.sl) + Math.pow(0.1, _vm.modalSymbolActive.precision)).toFixed(_vm.modalSymbolActive.precision) : 
                    _vm.modalSymbolActive.quote.ask.toFixed(_vm.modalSymbolActive.precision)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-symbols-outlined"},[_vm._v("add")])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }