<script>
export default {
    name: "BaseInfoIcon"
}
</script>

<template>
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.16666 13.1667H9.83332V8.16666H8.16666V13.1667ZM8.99999 6.5C9.2361 6.5 9.43402 6.42014 9.59374 6.26041C9.75346 6.10069 9.83332 5.90277 9.83332 5.66666C9.83332 5.43055 9.75346 5.23264 9.59374 5.07291C9.43402 4.91319 9.2361 4.83333 8.99999 4.83333C8.76388 4.83333 8.56596 4.91319 8.40624 5.07291C8.24652 5.23264 8.16666 5.43055 8.16666 5.66666C8.16666 5.90277 8.24652 6.10069 8.40624 6.26041C8.56596 6.42014 8.76388 6.5 8.99999 6.5ZM8.99999 17.3333C7.84721 17.3333 6.76388 17.1146 5.74999 16.6771C4.7361 16.2396 3.85416 15.6458 3.10416 14.8958C2.35416 14.1458 1.76041 13.2639 1.32291 12.25C0.885406 11.2361 0.666656 10.1528 0.666656 9C0.666656 7.84722 0.885406 6.76389 1.32291 5.75C1.76041 4.73611 2.35416 3.85416 3.10416 3.10416C3.85416 2.35416 4.7361 1.76041 5.74999 1.32291C6.76388 0.885414 7.84721 0.666664 8.99999 0.666664C10.1528 0.666664 11.2361 0.885414 12.25 1.32291C13.2639 1.76041 14.1458 2.35416 14.8958 3.10416C15.6458 3.85416 16.2396 4.73611 16.6771 5.75C17.1146 6.76389 17.3333 7.84722 17.3333 9C17.3333 10.1528 17.1146 11.2361 16.6771 12.25C16.2396 13.2639 15.6458 14.1458 14.8958 14.8958C14.1458 15.6458 13.2639 16.2396 12.25 16.6771C11.2361 17.1146 10.1528 17.3333 8.99999 17.3333ZM8.99999 15.6667C10.8611 15.6667 12.4375 15.0208 13.7292 13.7292C15.0208 12.4375 15.6667 10.8611 15.6667 9C15.6667 7.13889 15.0208 5.5625 13.7292 4.27083C12.4375 2.97916 10.8611 2.33333 8.99999 2.33333C7.13888 2.33333 5.56249 2.97916 4.27082 4.27083C2.97916 5.5625 2.33332 7.13889 2.33332 9C2.33332 10.8611 2.97916 12.4375 4.27082 13.7292C5.56249 15.0208 7.13888 15.6667 8.99999 15.6667Z"
            fill="#ADB0B6"/>
    </svg>
</template>

<style scoped>

</style>
