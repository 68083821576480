import { render, staticRenderFns } from "./BaseLogo.vue?vue&type=template&id=70196908&scoped=true"
import script from "./BaseLogo.vue?vue&type=script&lang=js"
export * from "./BaseLogo.vue?vue&type=script&lang=js"
import style0 from "./BaseLogo.vue?vue&type=style&index=0&id=70196908&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70196908",
  null
  
)

export default component.exports