<template>
    <div class="modal-otp" :class="{hide: !modals.otpCode}"
         @click.stop="hideModal">
        <div class="modal" @click.stop>
            <i class="material-icons pointer" @click="hideModal">close</i>
            <div class="title">
                <span>{{ tokens.fullScreenDeposit.otpCode }}</span>
                <span class="confirm" v-if="otpConfirm">{{ tokens.fullScreenDeposit.confirm }}</span>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <label>{{ tokens.fullScreenDeposit.otpCode }}</label>
                        <input class="form-control" v-model="otpCode" @keyup.enter="sendOtp"/>
                        <div class="button green-button" @click="sendOtp">
                            {{ tokens.fullScreenDeposit.sendOtp.toUpperCase() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from "@/store/mutations.type"
import {DEPOSIT_OTP} from "@/store/actions.type";
import {OTP_CONFIRM} from "@/common/config";

export default {
    name: "OtpModal",
    data() {
        return {
            otpCode: '',
            otpConfirm: OTP_CONFIRM
        }
    },
    methods: {
        sendOtp() {
            if(this.otpCode){
                this.$store.dispatch(DEPOSIT_OTP, this.otpCode)
                this.hideModal()
                this.otpCode = ''
            }
        },
        hideModal() {
            this.otpCode = ''
            this.$store.commit(MODAL, {
                otpCode: false
            })
        }
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal i {
    text-align: right;
}

.modal {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(149.09deg, rgba(155, 162, 187, 1) 2.06%, rgba(57, 57, 57, 1) 77.03%);
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 30px 40px;
    z-index: 1100;
    position: absolute;
}

.title {
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: var(--white);
}

.title span:first-child {
    text-transform: uppercase;
    font-size: 16px;
}

.confirm {
    font-size: 11px;
}

.title i {
    font-size: 19px;
}

label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.form-row:last-child {
    margin-bottom: 0;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    cursor: pointer;
    background: #247243;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}
</style>
