<template>
    <div class="chip-inner label__small" 
        :class="{
            danger: mode === 'danger',
            success: mode === 'success'}">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: "BaseChip",
    props: {
        mode: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.chip-inner {
    padding: 2px 4px;
    text-align: center;
    width: max-content;
    border-radius: 4px;
    background: var(--Statelayers-onsurfacevariant-opacity16);
    color: var(--System-OnSurfaceVariant)
}

.chip-inner.danger {
    color: var(--System-Error);
    background-color: var(--Statelayers-error-opacity16);
}
.chip-inner.success {
    color: var(--System-Tertiary);
    background-color: var(--Statelayers-tertiary-opacity16);
}
</style>
