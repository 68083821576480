var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders-pane",class:{close: !_vm.ordersPane}},[_c('div',{staticClass:"tabs",class:{'border-bottom': !_vm.ordersPane}},[_c('BaseTabs',{attrs:{"activeTab":_vm.getKeyByValue(_vm.tabActive),"tabs":[
                _vm.tokens.ordersPane.ordersActive.toUpperCase(), 
                _vm.tokens.ordersPane.ordersHistory.toUpperCase()
            ],"tabStyle":{ 
                'font-size': '1.1em', 
                'padding': '4px 20px', 
                'max-width': 'max-content',
                'border-bottom': '1px solid transparent',
            }},on:{"updateActiveTab":function($event){return _vm.activateTab(_vm.tabs[$event])}}})],1),_c('div',{staticClass:"tables-container",class:{hide: !_vm.ordersPane},attrs:{"id":"tables-container"}},[_c('OrdersTable',{class:{hide: _vm.tabActive !== 'active'}}),_c('HistoryTable',{class:{hide: _vm.tabActive !== 'history'},attrs:{"active":_vm.tabActive === 'history'}})],1),(_vm.ordersPane)?_c('i',{staticClass:"material-icons expander pointer",on:{"click":function($event){return _vm.closeOrdersPane()}}},[_vm._v("remove")]):_c('i',{staticClass:"material-symbols-outlined expander pointer",on:{"click":function($event){$event.stopPropagation();return _vm.openOrdersPane()}}},[_vm._v("expand_content")])])
}
var staticRenderFns = []

export { render, staticRenderFns }