<!--todo make as single base component-->

<template>
    <div class="deposit-request">
        <div class="coast-container">
            <span
                class="coast label__medium">{{
                    currencySign(deposit.currency)
                }} {{ deposit.status === 1 ? deposit.amount.toFixed(2) : (-deposit.amount).toFixed(2) }}</span>
            <span class="currency body__small">{{ deposit.currency }}</span>
        </div>
        <div class="status-container" :class="tokens.statuses[deposit.status].toLowerCase()">
            <span class="status label__small">
                {{ tokens.statuses[deposit.status] }}
            </span>
        </div>
        <div class="time body__extrasmall">
            {{ formatDate(deposit.time_created) }}
        </div>
        <div class="info body__extrasmall">
            {{ deposit.info || '-' }}
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {currencySign} from "@/common/helpers"

export default {
    name: "DepositRequest",
    props: {
        deposit: {
            type: Object,
            default: () => {
            }
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    },
    methods: {
        currencySign,
        formatDate(timestamp) {
            const date = new Date(timestamp * 1000)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()
            const time = date.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit', second: '2-digit'})

            return `${day}.${month}.${year} ${time}`
        }
    }
}
</script>

<style scoped>
.deposit-request {
    display: grid;
    width: 100%;
    gap: 6px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--System-Surface5);
}

.deposit-request:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.coast-container {
    grid-area: 1/1;
}

.status-container {
    grid-area: 1/2;
    padding: 2px 4px;
    border-radius: 3px;
    background: var(--Statelayers-onsurfacevariant-opacity16);
    width: fit-content;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    gap: 4px;
}

.status-container.pending .status {
    font-weight: 500;
    color: var(--System-OnSurfaceVariant);
}

.status-container.approved {
    background: var(--Statelayers-tertiary-opacity16);
}

.status-container.approved .status {
    color: var(--System-Tertiary);
}

.status-container.declined {
    background: var(--Statelayers-redfixed-opacity16);
}

.status-container.declined .status {
    color: var(--Custom-RedFixed);
}

.time {
    grid-area: 2/1;
    color: var(--System-OnSurfaceVariant);
}

.info {
    grid-area: 2/2;
    text-align: right;
    color: var(--System-OnSurfaceVariant);
}

.coast {
    color: var(--System-OnSurface);
}

.currency {
    color: var(--System-OnSurfaceVariant);
    margin-left: 4px;
}

i {
    font-size: 16px;
    cursor: pointer;
}
</style>
