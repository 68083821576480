<script>
export default {
    name: "BaseModalChipsList"
}
</script>

<template>
    <div class="chips-list">
        <slot name="content"/>
    </div>
</template>

<style scoped>
.chips-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}
</style>
