<template>
    <div class="successful">
        <div class="content">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                    <g filter="url(#filter0_b_954_22455)">
                        <path d="M23.5004 0.166992C10.6437 0.166992 0.167053 10.6437 0.167053 23.5003C0.167053 36.357 10.6437 46.8337 23.5004 46.8337C36.3571 46.8337 46.8337 36.357 46.8337 23.5003C46.8337 10.6437 36.3571 0.166992 23.5004 0.166992ZM34.6537 18.1337L21.4237 31.3637C21.0971 31.6903 20.6537 31.877 20.1871 31.877C19.7204 31.877 19.2771 31.6903 18.9504 31.3637L12.3471 24.7603C11.6704 24.0837 11.6704 22.9637 12.3471 22.287C13.0237 21.6103 14.1437 21.6103 14.8204 22.287L20.1871 27.6537L32.1804 15.6603C32.8571 14.9837 33.9771 14.9837 34.6537 15.6603C35.3304 16.337 35.3304 17.4337 34.6537 18.1337Z" fill="url(#paint0_linear_954_22455)"/>
                    </g>
                    <defs>
                        <filter id="filter0_b_954_22455" x="-61.0829" y="-61.083" width="169.167" height="169.167" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="30.625"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_954_22455"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_954_22455" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_954_22455" x1="-0.299841" y1="8.1001" x2="38.4406" y2="54.189" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4A7EFF"/>
                            <stop offset="0.486792" stop-color="#61BEDA"/>
                            <stop offset="1" stop-color="#404C73"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <h5 class="title__medium">Withdrawal Successful</h5>
        </div>
        <div class="info">
            <div
                v-for="(detail, index) in details"
                :key="index"
                class="item"
            >
                <h6 class="item-title body__small">
                    {{detail.title}}
                </h6>
                <h6 class="item-info body__small">
                    {{detail.value}}
                </h6>
            </div>
        </div>
        <div class="actions">
            <BaseButton
                :button-style="'outlined-primary'"
                :button-text="'View Accounts'"
                @click.native="showAccounts"
            />
            <BaseButton
                :button-style="'filled-primary'"
                :button-text="'Continue Withdrawal'"
                @click.native="continueWithdrawal"
            />
        </div>
    </div>
</template>

<script>
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"

export default {
    name: "SuccessfulTab",
    components: {BaseButton},
    props: {
        details: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        continueWithdrawal() {
            this.$emit('continue')
        },
        showAccounts() {
            this.$emit('showAccounts')
        }
    }
}
</script>

<style scoped>
.info {
    padding: 16px;
    background: var(--System-Surface1);
    border-radius: var(--Corner-small);
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    gap: 16px;
}

.icon {
    padding: 12px;
    border-radius: 100%;
    background: var(--Statelayers-onsurface-opacity08);
    display: flex;
    align-items: center;
    justify-content: center;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-title {
    color: var(--System-SurfaceVariant);
    font-weight: 500;
}

.item-info {
    color: var(--System-OnSurface);
    font-weight: 500;
}

.actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center stretch;
    gap: 16px;
}
</style>
