<template>
    <div class="account-card" :class="visibility ? '' : 'blur'">
        <div class="card-header">
            <div class="title">
                <div class="account-symbol symbol" :class="`symbol-${account.currency.toUpperCase()}`"></div>
                <span class="title__small">{{ account.currency }}</span>
                <!-- <span class="label__largeprominent">Canadian dollar</span> -->
                <span></span>
            </div>

            <MakeActiveButton :active="activeAccount.id === account.id" @update="makeActive(account.id)" />
        </div>

        <div class="card-main">
            <div>
                <div class="account-name body__extrasmall">
                    {{ accountName }} {{ tokens.fullScreenAccounts.account }}
                </div>
            </div>
            <div>
                <div class="account-info">
                    <div class="balance">
                        {{ tokens.fullScreenAccounts.balance }}
                        <span>
                            {{ currencySign(account.currency) + ' ' + account.balance.toFixed(account.precision ?
                            account.precision : 1) }}
                        </span>
                    </div>
                    <div class="credit">
                        {{ tokens.fullScreenAccounts.credit }}
                        <span>
                            {{ currencySign(account.currency) + ' ' + account.credit.toFixed(account.precision ?
                            account.precision : 1) }}
                        </span>
                    </div>
                </div>

                <DepositAccountIconButton @update="changePage(account.id)" />
            </div>
        </div>

        <div class="card-footer body__extrasmall" v-if="activeAccount.id === account.id">
            <div>
                <div class="card-footer-item">
                    <span>{{ tokens.balanceModal.invested }}</span>
                    <span>
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.orders * (1 / conversions[activeAccount.currency])).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </span>
                </div>
                <div class="card-footer-item">
                    <span>{{ balances.profit >= 0 ? tokens.balanceModal.profit : tokens.balanceModal.loss }}</span>
                    <span :class="balances.profit > 0 ? 'green' : (balances.profit < 0 ? 'red' : '')">
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.profit).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </span>
                </div>
                <div class="card-footer-item">
                    <span>{{ tokens.balanceModal.equity }} </span>
                    <span>
                        {{ currencySign(activeAccount.currency) }}
                        {{ (balances.equity_apprx).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) }}
                    </span>
                </div>
                <div class="card-footer-item">
                    <span>{{ tokens.balanceModal.margin }} </span>
                    <span>
                        {{ currencySign(activeAccount.currency) + ' ' + (balances.margin * (1 /
                            conversions[activeAccount.currency])).toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) }}
                    </span>
                </div>
                <div class="card-footer-item">
                    <span>{{ tokens.balanceModal.marginLevel }} </span>
                    <span>
                        {{ balances.margin !== 0 ? balances.margin_level_apprx.toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) + '%' : '-' }}
                    </span>
                </div>
                <div class="card-footer-item">
                    <span>{{ tokens.balanceModal.marginFree }} </span>
                    <span>
                        {{ balances.margin !== 0 ? currencySign(activeAccount.currency) + ' ' +
                            balances.free_margin_apprx.toLocaleString(undefined, {
                                minimumFractionDigits: activeAccount.precision,
                                maximumFractionDigits: activeAccount.precision
                            }) : '-' }}
                    </span>
                </div>
            </div>
        </div>
        <div class="card-footer body__extrasmall" v-else>
            <div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.invested }}</span> <span>-</span></div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.profit }}</span> <span>-</span></div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.equity }}</span> <span>-</span></div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.margin }}</span> <span>-</span></div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.marginLevel }}</span> <span>-</span></div>
                <div class="card-footer-item"><span>{{ tokens.balanceModal.marginFree }}</span> <span>-</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { currencySign, localStorage } from '@/common/helpers'
import { ACCOUNT_ACTIVE, MODAL, MODAL_FULLSCREEN } from '@/store/mutations.type'
import MakeActiveButton from "@/new-design/shared/ui/buttons/MakeActiveButton"
import DepositAccountIconButton from "@/new-design/shared/ui/buttons/DepositAccountIconButton"

export default {
    name: "AccountCard",
    components: {
        MakeActiveButton,
        DepositAccountIconButton
    },
    props: {
        account: {
            type: Object
        },
        visibility: {
            type: Boolean
        }
    },

    computed: {
        ...mapGetters(['accounts', 'activeAccount', 'symbols', 'tokens', 'conversions']),
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                margin: 0,
                // equity: 0,
                margin_level_apprx: 0,
                free_margin_apprx: 0,
                equity_apprx: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for (let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision) + o.swap + o.commission) : 0
            }

            for (let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n])
            }

            b.equity = b.balance + b.credit + b.profit
            b.margin_level_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit !== 0 && b.margin ? (100 * (this.activeAccount.balance + this.activeAccount.credit + b.profit) / b.margin).toFixed(2) : ''
            b.free_margin_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit - b.margin
            b.equity_apprx = b.equity

            return b
        },
        accountName() {
           return (this.account.alias && this.account.alias.toUpperCase()) ||
                    (this.account.account_name && this.account.account_name != 'MAIN' ?
                        this.account.account_name : this.account.group === 'main' ?
                            'STANDARD' : this.account.group.toUpperCase())
        }
    },
    methods: {
        changePage(id) {
            this.makeActive(id);

            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page: 'deposit',
                fromPage: 'accounts'
            })
        },
        makeActive(id) {
            localStorage.setItem('activeAccount', id)
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        currencySign
    }
}
</script>

<style scoped>

.account-card {
    transition: all .3s ease;
    border: 1px solid var(--System-OutlineVariant);
    margin-bottom: 24px;
    border-radius: 4px;
    overflow: hidden;
}

.blur {
    filter: blur(4px);
    pointer-events: none
}

.card-header {
    background: var(--System-Surface3);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.title>span:not(:last-child) {
    color: var(--System-OnSurface)
}

.account-symbol {
    zoom: .25
}

.card-main {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.card-main>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 20px;
    max-height: 34px;
}

.account-name {
    color: var(--System-OnPrimaryContainer);
    padding: 2px 6px;
    border-radius: 4px;
    background: var(--Statelayers-secondary-opacity08, rgba(191, 198, 220, 0.08));
    text-transform: uppercase;
}

.account-info {
    display: flex;
    gap: 24px;
}

.balance,
.credit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
    color: var(--System-Outline)
}

.balance>span,
.credit>span {
    color: var(--System-OnSurface)
}

.card-footer {
    padding: 0 16px
}

.card-footer>div {
    display: flex;
    align-items: center;
    gap: 24px;
    color: var(--System-Outline);
    padding: 16px 0;
    border-top: 1px solid var(--System-Surface5);
    overflow-x: auto;
}

.card-footer-item {
    font-size: 12px;
    line-height: 1.4;
}

.card-footer-item span {
    color: var(--System-OnSurfaceVariant);
    white-space: nowrap;
    display: inline-block;
    margin-right: 5px;
}

.red {
    color: var(--Custom-RedFixed) !important;
}

.green {
    color: var(--Custom-GreenFixed) !important;
}

@media(max-width:768px){
    .card-footer>div {
        gap: 8px;
    }
}


</style>
