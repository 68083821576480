<template>
    <div class="symbol-groups">
        <BaseModalChipsList>
            <template #content>
                <template v-for="(cat, index) in marketCats">
                    <BaseModalChipsListItem
                        v-if="cat.symbols.length || (cat.name === 'All' || cat.name === 'favorites')"
                        :key="index"
                        :class="{ active: currentCat === cat.name.toLowerCase() }"
                        @click.native="changeCat(cat.name)"
                    >
                        <template #content>
                            <svg
                                v-if="cat.name === 'favorites'"
                                xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"
                                fill="none"
                            >
                                <path
                                    d="M0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H12C12.55 0 13.0208 0.195833 13.4125 0.5875C13.8042 0.979167 14 1.45 14 2V18L7 15L0 18Z"
                                    fill="#E3E2E9"/>
                            </svg>
                            <span v-else>{{ cat.name }}</span>
                        </template>
                    </BaseModalChipsListItem>
                </template>
            </template>
        </BaseModalChipsList>

        <BaseSearch v-model="search" :placeholder="tokens.widgetMarketWatch.search"/>

        <div class="content scroll">
            <div class="col">
                <MarketItem
                    v-for="symbol in filteredSymbols"
                    :key="symbol.symbol"
                    :symbol="symbol"
                    :class="{
                        active: charts[chartActive] && charts[chartActive].symbol === symbol.symbol
                      }"
                    v-ripple
                    @change="changeSymbol"
                    @add="addChart"
                    @info="showAssetsInfoModal"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {
    MODAL,
    MODAL_MENU,
    SYMBOL_ACTIVE_UPDATE,
    SYMBOL_SHOW,
    MODAL_ASSETS_INFO
} from '@/store/mutations.type'
import {isMobile} from '@/common/helpers'
import {mapGetters} from 'vuex'
import {MARKET_WATCH_DEFAULT_CAT, BRAND} from "@/common/config"
import BaseSearch from "@/new-design/shared/ui/Inputs/BaseSearch.vue"
import MarketItem from "@/new-design/entities/MarketWatch/ui/MarketItem.vue"
import BaseModalChipsListItem from "@/new-design/shared/ui/chips/BaseModalChipsListItem.vue"
import BaseModalChipsList from "@/new-design/shared/ui/chips/BaseModalChipsList.vue"

export default {
    name: 'SymbolsGroup',
    components: {BaseModalChipsList, BaseModalChipsListItem, MarketItem, BaseSearch},
    data() {
        return {
            search: '',
            collapsed: {
                forex: MARKET_WATCH_DEFAULT_CAT === 'forex' ? false : true,
                stocks: MARKET_WATCH_DEFAULT_CAT === 'stocks' ? false : true,
                indices: MARKET_WATCH_DEFAULT_CAT === 'indices' ? false : true,
                commodities: MARKET_WATCH_DEFAULT_CAT === 'commodities' ? false : true,
                crypto: MARKET_WATCH_DEFAULT_CAT === 'crypto' ? false : true,
                metals: MARKET_WATCH_DEFAULT_CAT === 'metals' ? false : true,
                nft: MARKET_WATCH_DEFAULT_CAT === 'nft' ? false : true
            },
            brand: BRAND,
            time: Date.now(),
            currentCat: 'all',
        }
    },
    methods: {
        changeCat(cat) {
            if (this.currentCat === cat) {
                return
            }

            this.currentCat = cat.toLowerCase()
        },
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        showAssetsInfoModal(symbol) {
            this.$store.commit(MODAL_ASSETS_INFO, {
                symbol,
                chartActive: this.chartActive
            })
            this.$store.commit(MODAL, {
                assetsInfo: true,
            })
        },
        changeSymbol(symbol) {
            if (isMobile()) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }

            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        addChart(symbol) {
            this.$store.commit(SYMBOL_SHOW, {
                symbol,
                frame: 1
            })
        },
        shift(data, time) {
            let shifted = 0
            let tempArray = [].concat(data[0], data[1], data[2], data[3], data[4], data[5], data[6])
            let tempShifted = []
            if (time > 0) {

                for (let i = 0; i < time; i++) {
                    shifted = tempArray.pop()
                    tempShifted[i] = shifted
                }

                for (let i = 0; i < tempShifted.length; i++) {
                    tempArray.unshift(tempShifted[i])
                }
            } else if (time < 0) {
                time = Math.abs(time)
                for (let i = 0; i < time; i++) {
                    shifted = tempArray.shift()
                    tempShifted[i] = shifted
                }

                for (let i = 0; i < tempShifted.length; i++) {
                    tempArray.push(tempShifted[i])
                }
            }

            const chunkArray = (arr, cnt) => arr.reduce((prev, cur, i, a) => !(i % cnt) ? prev.concat([a.slice(i, i + cnt)]) : prev, [])

            let newArray = []
            newArray = chunkArray(tempArray, 24)
            return newArray
        },
        generateMarketCats() {
            let cats = {
                favorites: {
                    name: 'favorites',
                    ico: 'icon_bookmark',
                    cnt: 0,
                    symbols: []
                },
                all: {
                    name: 'All',
                    cnt: 0,
                    symbols: []
                },
                forex: {
                    name: this.tokens.markets.forex,
                    ico: 'euro_symbol',
                    cnt: 0,
                    symbols: [],
                },
                stocks: {
                    name: this.tokens.markets.stocks,
                    ico: 'account_balance',
                    cnt: 0,
                    symbols: [],
                },
                indices: {
                    name: this.tokens.markets.indices,
                    ico: 'pie_chart',
                    cnt: 0,
                    symbols: [],
                },
                crypto: {
                    name: this.tokens.markets.crypto,
                    ico: 'cloud',
                    cnt: 0,
                    symbols: [],
                },
                commodities: {
                    name: this.tokens.markets.commodities,
                    ico: 'local_fire_department',
                    cnt: 0,
                    symbols: [],
                },
                metals: {
                    name: this.tokens.markets.metals,
                    ico: 'view_stream',
                    cnt: 0,
                    symbols: [],
                },
                nft: {
                    name: this.tokens.markets.nft,
                    ico: 'collections',
                    cnt: 0,
                    symbols: []
                }
            }

            for (let n in this.symbols) {
                let s = this.symbols[n]

                if (!s.visible) {
                    continue
                }

                if (!cats[s.market]) {
                    continue
                }

                cats.all.symbols.push(s)
                cats.all.cnt++

                cats[s.market].symbols.push(s)
                cats[s.market].cnt++

                if (this.symbolsFavorite.some(fav => fav.symbol === s.symbol)) {
                    cats.favorites.symbols.push(s)
                    cats.favorites.cnt++
                }
            }

            return Object.values(cats)
        },
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'symbols', 'sessions', 'sessionsState', 'symbolGroups', 'chartActive', 'charts', 'modalMenu', 'staticBase', 'symbolsFavorite']),
        marketCats() {
            return this.generateMarketCats()
        },
        filteredSymbols() {
            const cat = this.marketCats.find(c => c.name.toLowerCase() === this.currentCat)
            if (!cat) return []

            if (!this.search) {
                return cat.symbols
            }

            const searchTerm = this.search.toLowerCase()
            return cat.symbols.filter(symbol =>
                symbol.symbol.toLowerCase().includes(searchTerm) ||
                symbol.alias.toLowerCase().includes(searchTerm)
            )
        }
    }
}
</script>

<style scoped>
.symbol-groups {
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    overflow: hidden;
}

select {
    margin: 0 15px 15px;
}

.chips-list {
    padding: 8px;
}

.search-input {
    border-top: 1px solid var(--System-OutlineVariant);
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.cat-title {
    display: flex;
    align-items: center;
    background: var(--market-watch-title);
    justify-content: space-between;
    padding: 8px 10px;
    color: var(--white-to-black);
    cursor: pointer;
    border-bottom: 1px solid var(--background-dark);
}

.light-theme .cat-title {
    border-bottom: 1px solid var(--border);
}

.cat-title i {
    font-size: 1.2em;
    margin: 0 5px 0 3px;
}

.row span {
    font-size: 12px;
}

.row.close, .price.close, .pct.close {
    color: #9f9f9f;
}

.row-2 {
    display: flex;
    align-items: center;
}

.light-theme .search {
    outline: 1px solid var(--border)
}

.light-theme .search:focus {
    outline: 1px solid var(--border) !important
}

.col {
    overflow: hidden;
}
</style>
