<template>
    <div class="chips-item body__medium">
        {{message}}
    </div>
</template>

<script>
export default {
    name: "MessageChipsListItem",
    props: {
        message: {
            type: String
        }
    }
}
</script>

<style scoped>
.chips-item {
    padding: 6px 8px;
    border-radius: var(--Corner-small);
    border: 1px solid var(--System-SecondaryContainer);
    color: var(--System-Secondary);
    cursor: pointer;
}
</style>
