<template>
    <div>
        <table class="orders-table" :class="{hide: !ordersLength}">
            <thead>
                <tr class="label__small">
                    <th>
                        <div class="symbol-container">
                            {{tokens.ordersPane.symbol}}
                        </div>
                    </th>
                    <th class="no-mobile" @click="sortBy('id')">
                        {{tokens.ordersPane.id}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('id')}`"></div>
                    </th>
                    <th @click="sortBy('type')">
                        {{tokens.ordersPane.type}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('type')}`"></div>
                    </th>
                    <th @click="sortBy('volume')">
                        {{ tokens.ordersPane.volume }}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('volume')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('price_open')">
                        {{tokens.ordersPane.openPrice}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('price_open')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('expiration')">
                        {{tokens.ordersPane.timedOrder}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('expiration')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('time_open')">
                        {{tokens.ordersPane.openTime}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('time_open')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('sl')">
                        {{tokens.ordersPane.sl}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('sl')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('tp')">
                        {{tokens.ordersPane.tp}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('tp')}`"></div>
                    </th>
                    <th @click="sortBy('price_open')">
                        {{tokens.ordersPane.price}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('price_open')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('commission')">
                        {{tokens.ordersPane.commission}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('commission')}`"></div>
                    </th>
                    <th class="no-mobile" @click="sortBy('swap')">
                        {{tokens.ordersPane.swap}}
                        <div class="sort-button material-symbols-outlined"
                            :class="`${getSortButtonClass('swap')}`"></div>
                    </th>
                    <th>
                        {{tokens.ordersPane.pnl}}
                    </th>
                    <th>
                        {{tokens.ordersPane.actions}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, i) in orders" :key="i" v-on:click="setActiveSymbol(order.symbol)"
                    class="body__small">
                    <td>
                        <span class="symbol " :class="'symbol-'+order.symbol?.toUpperCase()" />
                        {{symbols[order.symbol] ? symbols[order.symbol].alias : ''}}
                    </td>
                    <td class="no-mobile">
                        {{order.id}}
                    </td>
                    <td :class="order.type % 2 ? 'red' : 'green'">
                        {{orderTypeString(order.type)}}
                    </td>
                    <td>
                        {{order.volume.toLocaleString()}}
                    </td>
                    <td class="no-mobile">
                        {{symbols[order.symbol] ? order.price_open.toLocaleString(undefined, { minimumFractionDigits:
                        symbols[order.symbol] ? symbols[order.symbol].precision : 3, maximumFractionDigits:
                        symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : 0}}
                    </td>
                    <td class="no-mobile">
                        {{order.expiration ? new Date(order.expiration * 1000).toLocaleString() : '-'}}
                    </td>
                    <td class="no-mobile">
                        {{new Date(order.time_open * 1000).toLocaleString()}}
                    </td>
                    <td class="no-mobile">
                        {{order.sl && symbols[order.symbol] ? order.sl.toLocaleString(undefined, {
                        minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3,
                        maximumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : '-'}}
                    </td>
                    <td class="no-mobile">
                        {{order.tp && symbols[order.symbol] ? order.tp.toLocaleString(undefined, {
                        minimumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3,
                        maximumFractionDigits: symbols[order.symbol] ? symbols[order.symbol].precision : 3 }) : '-'}}
                    </td>
                    <td
                        :class="!symbols[order.symbol] ? '' : ([0, 3, 5].indexOf(order.type) === -1 ? symbols[order.symbol].quote.dirAsk : symbols[order.symbol].quote.dirBid)">
                        {{symbols[order.symbol] ? (symbols[order.symbol].quote[[0, 3, 5].indexOf(order.type) === -1 ?
                        'ask' : 'bid'] || 0).toLocaleString(undefined, { minimumFractionDigits: symbols[order.symbol] ?
                        symbols[order.symbol].precision : 3, maximumFractionDigits: symbols[order.symbol] ?
                        symbols[order.symbol].precision : 3 }) : ''}}
                    </td>
                    <td class="no-mobile">
                        {{ currencySign(activeAccount.currency) + order.commission.toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision
                        }) }}
                    </td>
                    <td class="no-mobile">
                        {{ currencySign(activeAccount.currency) + order.swap.toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision
                        }) }}
                    </td>
                    <td :class="getOrderStyle(order)">
                        {{getOrderPnl(order)}}
                    </td>
                    <td class="actions">
                        <BaseInLineIconButton
                            :button-style="'primary'"
                            @click.native.stop="showTimePickerModal($event, order.id, i, order)"
                        >
                            <template #icon>
                                <i class="material-symbols-outlined">schedule</i>
                            </template>
                        </BaseInLineIconButton>
                        <BaseInLineIconButton
                            :button-style="'primary'"
                            @click.native.stop="showAutoCloseModal($event, order.id, i)"
                        >
                            <template #icon>
                                <i class="material-symbols-outlined">edit</i>
                            </template>
                        </BaseInLineIconButton>
                        <BaseInLineIconButton
                            :button-style="'primary'"
                            @click.native.stop="showCloseOrderConfirmation(order.id, i)"
                        >
                            <template #icon>
                                <i class="material-symbols-outlined">cancel</i>
                            </template>
                        </BaseInLineIconButton>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="info" :class="{hide: ordersLength || !dataLoaded}">
            <h3 class="title__medium">No Active Orders</h3>
            <span class="body__small">It looks like you don't have any active orders at the moment</span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {orderTypeString, timeToString, currencySign} from "@/common/helpers";
import {
    MODAL,
    MODAL_CLOSE_POSITION,
    MODAL_AUTOCLOSE,
    SYMBOL_ACTIVE_UPDATE,
    NOTIFICATION_ERROR, SORT_ORDERS, MODAL_TIME_PICKER
} from '@/store/mutations.type'
import {DISABLE_ORDER_SORT} from "@/common/config"
import BaseInLineIconButton from "@/new-design/shared/ui/buttons/BaseInLineIconButton.vue";

export default {
    name: "OrdersTable",
    components: {BaseInLineIconButton},
    data() {
        return {
            disableOrderSort: DISABLE_ORDER_SORT,
            dataLoaded: false,
            sortButtonState: {}
        }
    },
    computed: {
        ...mapGetters(['tokens', 'orders', 'symbols', 'activeAccount', 'user', 'chartActive','staticBase']),
        ordersLength() {
            return Object.keys(this.orders).length
        }
    },
    methods: {
        orderTypeString,
        timeToString,
        currencySign,
        setSortButtonState(colName){
            if(this.sortButtonState.colName !== colName) {
                this.sortButtonState.colName = colName;
                this.sortButtonState.directionUp = false
            }else{
                this.sortButtonState.directionUp = !this.sortButtonState.directionUp
            }
        },
        getSortButtonClass(colName) {
            if(this.sortButtonState.colName !== colName) return '';
            return this.sortButtonState.directionUp ? 'up' : 'down';
        },
        sortBy(sortBy) {
            if (this.disableOrderSort) {
                return
            }

            this.$store.commit(SORT_ORDERS, {
                sort: sortBy,
                account: this.activeAccount.id
            })

            this.setSortButtonState(sortBy)
        },
        getOrderStyle(order) {
            const symbol = this.symbols[order.symbol]
            if (!symbol) {
                return ''
            }
            const diff = order.type ? order.price_open - symbol.quote.ask : symbol.quote.bid - order.price_open
            const value = order.point * diff * Math.pow(10, symbol.precision) + order.swap + order.commission
            return value >= 0 ? 'green' : 'red'
        },
        getOrderPnl(order) {
            const symbol = this.symbols[order.symbol]
            if (order.type >= 2) {
                return '0'
            }
            if (!symbol) {
                return '-'
            }
            const diff = order.type ? order.price_open - symbol.quote.ask : symbol.quote.bid - order.price_open
            const value = (order.point * diff * Math.pow(10, symbol.precision) + order.swap + order.commission)
            const formattedValue = value.toLocaleString(undefined, {
                minimumFractionDigits: this.activeAccount.precision,
                maximumFractionDigits: this.activeAccount.precision,
            })
            return `${currencySign(this.activeAccount.currency)}${formattedValue}`
        },
        setActiveSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        showCloseOrderConfirmation(id, index) {
            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }

            this.$store.commit(MODAL_CLOSE_POSITION, {
                activeOrderId: id,
                index: index
            })
            this.$store.commit(MODAL, {
                closePosition: true
            })
        },
        showTimePickerModal(e, id, item, order) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_TIME_PICKER, {
                left: rect.left + 100,
                top: rect.top - 50,
                symbolActive: null,
                orderActive: id,
                item: parseInt(item),
                expiration: order.expiration
            })

            this.$store.commit(MODAL, {
                timePicker: true
            })
        },
        showAutoCloseModal(e, id,item) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_AUTOCLOSE, {
                left: rect.left,
                top: rect.top - 150,
                symbolActive: null,
                orderActive: id,
                tp: this.activeAccount.orders[item].tp || '',
                tpType: '=',
                sl: this.activeAccount.orders[item].sl || '',
                slType: '=',
                item: parseInt(item)
            })

            this.$store.commit(MODAL, {
                autoClose: true
            })
        },
    },
    watch: {
        orders(){
            if (!this.dataLoaded)
                setTimeout(() => {
                    this.dataLoaded = true
                }, 1000);
        }
    }
}
</script>

<style scoped>

table {
    width: 100%;
    position: relative;
    background: var(--System-Surface, #161B24);
    border-collapse: collapse;
    border: 1px solid var(--System-Surface5);

}

thead tr th:hover {
    cursor: pointer;
    background: var(--System-Surface3, #252B36);
}

tr:not(thead  tr):hover {
    background: var(--System-Surface3, #252B36);
}

td, th {
    white-space: nowrap;
    text-align: left;
    padding: 7px 7px 6px;
    background: var(--System-Surface);
}

th {
    position: sticky;
    top: 0px;
    border: 1px solid var(--System-Surface5);
    font-weight: 500;
}

.symbol-container::after ,
th::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

.symbol-container::before ,
th::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 100%;
    height: 1px;
    background: var(--System-Surface5);
}

td {
    color: var(--System-OnSurface);
    border-right: 1px solid var(--System-Surface5);
}

td:last-child {
    border: none;
}

td:last-child span {
    cursor: pointer;
}

td:last-child span {
    margin-right: 15px;
}

td:last-child span:last-child {
    margin-right: 0;
}

td:first-child img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 5px;
}

td .symbol {
    zoom: 0.21875;
    margin-right: 23px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 15px;
}

td i {
    font-size: 12px;
}

.red {
    color: var(--Custom-RedFixed)
}

.green {
    color: var(--Custom-GreenFixed)
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 150px;
    text-align: center;
    padding: 5px 10px;
}

thead tr th:first-child,
td:has(span.symbol){
    position: sticky;
    left: 0;
    z-index: 5;
}

thead tr th:first-child{
    top: 0;
    z-index: 6;
}

thead tr th:first-child::after,
td:has(span.symbol)::after{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    right: -1px;
    top: 0;
}

thead tr th:first-child::before,
td:has(span.symbol)::before{
    content: '';
    width: 1px;
    height: 100%;
    background: var(--System-Surface5);
    position: absolute;
    left: -1px;
    top: 0;
}

.sort-button{
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
    transform: translateY(2px);
    margin-top: -3px;
    margin-bottom: -4px;
}

.sort-button::after,
.sort-button::before{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: var(--System-Outline);
    transition: color .2s ease;
}

.sort-button::before{
    content: 'arrow_drop_up';
    top: 35%;
}

.sort-button::after{
    content: 'arrow_drop_down';
    top: 65%;
}

.sort-button.up::before,
.sort-button.down::after {
    color: var(--System-Primary);
}

.sort-button.down::before,
.sort-button.up::after {
    color: var(--System-OutlineVariant);
}

.actions {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
}

@media (max-height: 900px) {
    .info{
        min-height: 15vh;
    }
}

@-moz-document url-prefix() {
    td .symbol {
        -moz-transform: scale(0.21875);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -16px;
        top: -22px;
    }

    td:first-child {
        position: relative;
        padding-left: 28px !important;;
    }
}
</style>
