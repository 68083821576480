<script>
import {currencySign} from "@/common/helpers";

export default {
    name: "BaseActiveAccountButton",
    methods: {currencySign},
    props: {
        symbol: {
            type: String
        },
        balance: {
            type: String
        }
    }
}
</script>

<template>
    <button class="active-account">
        <span class="state-layer">
            <span class="balance label__large">{{currencySign(symbol)}}{{balance}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                  d="M6 20C4.9 20 3.95833 19.6083 3.175 18.825C2.39167 18.0417 2 17.1 2 16V8C2 6.9 2.39167 5.95833 3.175 5.175C3.95833 4.39167 4.9 4 6 4H18C19.1 4 20.0417 4.39167 20.825 5.175C21.6083 5.95833 22 6.9 22 8V16C22 17.1 21.6083 18.0417 20.825 18.825C20.0417 19.6083 19.1 20 18 20H6ZM6 8H18C18.3667 8 18.7167 8.04167 19.05 8.125C19.3833 8.20833 19.7 8.34167 20 8.525V8C20 7.45 19.8042 6.97917 19.4125 6.5875C19.0208 6.19583 18.55 6 18 6H6C5.45 6 4.97917 6.19583 4.5875 6.5875C4.19583 6.97917 4 7.45 4 8V8.525C4.3 8.34167 4.61667 8.20833 4.95 8.125C5.28333 8.04167 5.63333 8 6 8ZM4.15 11.25L15.275 13.95C15.425 13.9833 15.575 13.9833 15.725 13.95C15.875 13.9167 16.0167 13.85 16.15 13.75L19.625 10.85C19.4417 10.6 19.2083 10.3958 18.925 10.2375C18.6417 10.0792 18.3333 10 18 10H6C5.56667 10 5.1875 10.1125 4.8625 10.3375C4.5375 10.5625 4.3 10.8667 4.15 11.25Z"
                  fill="#93D5C6"/>
            </svg>
        </span>
    </button>
</template>

<style scoped>
.active-account {
    background: transparent;
    color: var(--System-Tertiary);
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
}

.state-layer {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 16px 6px 12px;
    border-radius: var(--Corner-Extra-small);
}

.active-account:hover .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity08);
}

.active-account:focus .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity12);
}

.active-account:active .state-layer {
    background: var(--Statelayers-secondarycontainer-opacity16);
}

@media (max-width: 768px) {
    .balance {
        display: none;
    }

    .state-layer {
        padding: 6px;
    }
}
</style>
