import { render, staticRenderFns } from "./BaseNumberInput.vue?vue&type=template&id=1ea54034&scoped=true"
import script from "./BaseNumberInput.vue?vue&type=script&lang=js"
export * from "./BaseNumberInput.vue?vue&type=script&lang=js"
import style0 from "./BaseNumberInput.vue?vue&type=style&index=0&id=1ea54034&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ea54034",
  null
  
)

export default component.exports