<template>
    <button class="make-active-button label__medium" :class="{active: isActive}"  @click="update">
        <div class="state-layer label__medium" :style="{opacity: loader ? 0 : 1}">{{ isActive ? 'Active' : 'Make Active' }}</div>
        <i v-if="isActive" class="material-symbols-outlined">check_circle</i>
        <div v-if="loader" class="loader"></div>
    </button>
</template>

<script>
export default {
    name: "MakeActiveButton",
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        isActive(){
            return this.active && !this.loader
        }
    },
    methods: {
        update(){
            this.loader = true
            this.$emit('update')
            setTimeout(() => {
                this.loader = false
            }, 500)
        }
    }
}
</script>

<style scoped>
.make-active-button {
    border: 1px solid var(--System-Outline);
    padding: 6px 14px;
    color: var(--System-Secondary);
    border-radius: 4px;
    width: max-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    min-height: 30px;
    max-height: 30px;
    min-width: 100px;
    transition: all .3s ease;
    background: transparent;

}

.make-active-button.active {
    border-color: transparent;
    padding: 3px 8px 3px 20px;
    justify-content: flex-end;
    pointer-events: none;
    background: transparent;
}

.make-active-button i {
    font-size: 20px;
}

.make-active-button:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.make-active-button:focus-visible {
    background: var(--Statelayers-onsurface-opacity12);
}

.make-active-button:active {
    background: var(--Statelayers-onsurface-opacity16);
}

.make-active-button:disabled {
    background: transparent;
    color: var(--System-On-Surface);
}

.loader {
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid transparent;
    background: transparent;
    border-right-color: var(--System-Secondary);
    border-top-color: var(--System-Secondary);
    animation: l2 1s infinite linear;
    z-index: 1;
}

@keyframes l2 {
    to {
        transform: rotate(1turn)
    }
}
</style>