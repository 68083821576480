export const manualPspList = [{
    alias: "bitcoin_manual",
    symbol: "BTC",
    text: 'Bitcoin'
},{
    alias: "tron_manual",
    symbol: "TRX",
    text: 'Tron',
},{
    alias: "xrp_manual",
    symbol: "XRP",
    text: 'XRP',
},{
    alias: "ethereum_manual",
    symbol: "ETH",
    text: 'Ethereum',
},{
    alias: "usdt_sol_manual",
    symbol: "SOLUSDT",
    text: 'USDT (SOL)',
},{
    alias: "usdt_erc20_manual",
    symbol: "USDTE",
    text: 'USDT (ERC20)',
},{
    alias: "usdt_trc20_manual",
    symbol: "USDTT",
    text: 'USDT (TRC20)',
}]
