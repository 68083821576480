<template>
    <div
        class="grid-layout-container"
        :class="`layout_${gridLayout}`"
    >
        <div v-for="(chart, i) in visibleCharts"
             :key="i"
             class="chart-container"
             :class="`chart-${i}`"
        >
            <Chart :chart="chart" :gridId="i"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Chart from '@/new-design/not-sorted/Chart'

export default {
    name: 'Grid',
    components: {
        Chart
    },
    computed: {
        ...mapGetters(['blur', 'gridCharts', 'charts', 'gridLayout']),
        visibleCharts() {
            let visible = []

            for (let n in this.gridCharts) {
                let id = this.gridCharts[n]
                if (id === undefined || this.charts[id] === undefined) {
                    visible.push(false)
                    continue
                }

                visible.push(this.charts[id])
            }

            return visible
        }
    }
}
</script>

<style scoped>

.grid-layout-container {
    flex: 5 1 200px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 10px;
    overflow: auto;
}

.chart-container {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--background-light);
    font-size: 0.9rem;
}

.light-theme .chart-container {
    border-color: var(--border)
}

.layout_ .chart-0 { display: none }
.layout_ .chart-1 { display: none }
.layout_ .chart-2 { display: none }
.layout_ .chart-3 { display: none }

.layout_1-1 .chart-0 { grid-column: 1/7; grid-row: 1/7 }
.layout_1-1 .chart-1 { display: none }
.layout_1-1 .chart-2 { display: none }
.layout_1-1 .chart-3 { display: none }

.layout_2-1 .chart-0 { grid-column: 1/4; grid-row: 1/7 }
.layout_2-1 .chart-1 { grid-column: 4/7; grid-row: 1/7 }
.layout_2-1 .chart-2 { display: none }
.layout_2-1 .chart-3 { display: none }

.layout_2-2 .chart-0 { grid-column: 1/7; grid-row: 1/4 }
.layout_2-2 .chart-1 { grid-column: 1/7; grid-row: 4/7 }
.layout_2-2 .chart-2 { display: none }
.layout_2-2 .chart-3 { display: none }

.layout_3-1 .chart-0 { grid-column: 1/7; grid-row: 1/4 }
.layout_3-1 .chart-1 { grid-column: 1/4; grid-row: 4/7 }
.layout_3-1 .chart-2 { grid-column: 4/7; grid-row: 4/7 }
.layout_3-1 .chart-3 { display: none }

.layout_3-2 .chart-0 { grid-column: 1/4; grid-row: 1/4 }
.layout_3-2 .chart-1 { grid-column: 4/7; grid-row: 1/4 }
.layout_3-2 .chart-2 { grid-column: 1/7; grid-row: 4/7 }
.layout_3-2 .chart-3 { display: none }

.layout_3-3 .chart-0 { grid-column: 1/4; grid-row: 1/4 }
.layout_3-3 .chart-1 { grid-column: 1/4; grid-row: 4/7 }
.layout_3-3 .chart-2 { grid-column: 4/7; grid-row: 1/7 }
.layout_3-3 .chart-3 { display: none }

.layout_3-4 .chart-0 { grid-column: 1/4; grid-row: 1/7 }
.layout_3-4 .chart-1 { grid-column: 4/7; grid-row: 1/4 }
.layout_3-4 .chart-2 { grid-column: 4/7; grid-row: 4/7 }
.layout_3-4 .chart-3 { display: none }

.layout_3-5 .chart-0 { grid-column: 1/3; grid-row: 1/7 }
.layout_3-5 .chart-1 { grid-column: 3/5; grid-row: 1/7 }
.layout_3-5 .chart-2 { grid-column: 5/7; grid-row: 1/7 }
.layout_3-5 .chart-3 { display: none }

.layout_4-1 .chart-0 { grid-column: 1/4; grid-row: 1/4 }
.layout_4-1 .chart-1 { grid-column: 4/7; grid-row: 1/4 }
.layout_4-1 .chart-2 { grid-column: 1/4; grid-row: 4/7 }
.layout_4-1 .chart-3 { grid-column: 4/7; grid-row: 4/7 }

.layout_4-2 .chart-0 { grid-column: 1/3; grid-row: 1/4 }
.layout_4-2 .chart-1 { grid-column: 3/5; grid-row: 1/4 }
.layout_4-2 .chart-2 { grid-column: 5/7; grid-row: 1/4 }
.layout_4-2 .chart-3 { grid-column: 1/7; grid-row: 4/7 }

.layout_4-3 .chart-0 { grid-column: 1/7; grid-row: 1/4 }
.layout_4-3 .chart-1 { grid-column: 1/3; grid-row: 4/7 }
.layout_4-3 .chart-2 { grid-column: 3/5; grid-row: 4/7 }
.layout_4-3 .chart-3 { grid-column: 5/7; grid-row: 4/7 }

.layout_4-4 .chart-0 { grid-column: 1/4; grid-row: 1/7 }
.layout_4-4 .chart-1 { grid-column: 4/7; grid-row: 1/3 }
.layout_4-4 .chart-2 { grid-column: 4/7; grid-row: 3/5 }
.layout_4-4 .chart-3 { grid-column: 4/7; grid-row: 5/7 }

.layout_4-5 .chart-0 { grid-column: 1/4; grid-row: 1/3 }
.layout_4-5 .chart-1 { grid-column: 1/4; grid-row: 3/5 }
.layout_4-5 .chart-2 { grid-column: 1/4; grid-row: 5/7 }
.layout_4-5 .chart-3 { grid-column: 4/7; grid-row: 1/7 }

@media (max-width: 999px){
.chart-container {
    font-size: .7rem;
    }
}

</style>
