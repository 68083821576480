<template>
    <div class="symbols">
        <BaseIconButton
            :button-style="'tonal'"
            @click.native="showAddSymbolModal()"
        >
            <template #icon>
                <i class="material-symbols-outlined">add</i>
            </template>
        </BaseIconButton>
        <SymbolsChipsList :symbols="charts"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import SymbolsChipsList from "@/new-design/entities/Symbols/ui/SymbolsChipsList.vue"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue"
import {MODAL, MODAL_SYMBOLS} from "@/store/mutations.type"

export default {
    name: "SymbolsPanel",
    components: {BaseIconButton, SymbolsChipsList},
    computed: {
        ...mapGetters(['charts'])
    },
    methods: {
        showAddSymbolModal() {
            this.$store.commit(MODAL, {
                symbols: true
            })
            this.$store.commit(MODAL_SYMBOLS, {
                action: 'add',
                symbolActive: null
            })
        }
    }
}
</script>

<style scoped>
.symbols {
    padding: 0 8px;
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
    display: grid;
    grid-template-columns: max-content 1fr;
    place-items: center;
    gap: 8px;
}

button {
    height: 36px;
}
</style>
