<template>
    <div class="table-list-item">
        <div class="item-left">
            <div class="document-category">
                <span class="label__medium">{{ getCategoryAndText(document.type).category }}</span>
                <span class="label__mediumprominent">{{ document.type === 1 ? '' :
                    getCategoryAndText(document.type).text }}</span>
            </div>
            <div class="document-time-upload body__extrasmall">
                {{ tokens.fullScreenVerification.timeUploaded.toLowerCase() }}
                {{ new Date(document.time_created * 1000).toLocaleString() }}
            </div>
        </div>
        <div class="item-right">
            <BaseChip :text="getChipData(document.status).text" :mode="getChipData(document.status).mode" />
            <div class="document-time-processed body__extrasmall">
                {{ document.time_processed ? new Date(document.time_processed * 1000).toLocaleString() : '-' }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseChip from "@/new-design/shared/ui/chips/BaseChip"

export default {
    name: 'UploadedDocumentItem',
    components: { BaseChip },
    props: {
        document: {
            type: Object,
        }
    },
    computed: {
        ...mapGetters(['tokens']),
    },
    methods: {
        getCategoryAndText(type) {
            let category, text;

            switch (type) {
                case 0:
                    category = 'Identify'
                    text = 'Front Side'
                    break;
                case 4:
                    category = 'Identify'
                    text = 'Back Side'
                    break;
                case 1:
                    category = 'Address'
                    text = 'Front side of the document'
                    break;
                case 2:
                    category = 'Credit Card'
                    text = 'Front Side'
                    break;
                case 3:
                    category = 'Credit Card'
                    text = 'Back Side'
                    break;
                case 5:
                    category = 'Selfie'
                    text = 'Front Side'
                    break;
            }

            return { category, text }
        },
        getChipData(status) {
            let text, mode

            switch (status) {
                case 0:
                    text = 'Pending';
                    break;
                case 1:
                    text = 'Approved';
                    mode = 'success'
                    break;
                case 2:
                    text = 'Declined';
                    mode = "danger"
                    break;
            }

            return { text, mode }
        }
    },
}
</script>

<style scoped>
.table-list-item {
    border-bottom: 1px solid var(--System-OutlineVariant);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}

.table-list-item:first-child {
    padding-top: 0;
}

.table-list-item:last-child {
    padding-bottom: 0;
    border: none
}

.item-left,
.item-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.item-left {
    align-items: flex-start;
}

.item-right {
    align-items: flex-end;
}

.document-category {
    display: flex;
    align-items: center;
    gap: 5px;
}

.document-category span:first-child {
    color: var(--System-OnSurface);
}
</style>