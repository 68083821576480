<template>
    <div class="menu">
        <div v-if="title" class="title body__medium">{{ title }}</div>
        <slot name="placeholder">
            <div class="placeholder">
                <p class="active-item body__large">{{ placeholder }}</p>
                <span v-if="!hideArrow" class="material-symbols-outlined">arrow_right</span>
            </div>
        </slot>
        <BaseMenuList v-if="state" class="ElevationLight__2" :class="placement">
            <template #content>
                <BaseMenuListItem
                    v-for="(option, i) in options"
                    :key="i"
                    :tag="'li'"
                    class="option"
                    :class="{active: value === option.key}"
                    @click.native="select(option)"
                >
                    <template #content>
                        <slot :option="option" name="option">{{ option.title }}</slot>
                    </template>
                </BaseMenuListItem>
            </template>
        </BaseMenuList>
    </div>
</template>

<script>
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"

export default {
    name: "BaseSelectBox",
    components: {BaseMenuList, BaseMenuListItem},
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        state: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'top'
        },
        hideArrow: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        }
    },
    methods: {
        select(option) {
            this.$emit('update', option.key)
        }
    }
}
</script>

<style scoped>
.placeholder {
    display: flex;
    align-items: center;
    gap: 4px;
}

.active-item {
    color: var(--System-OnSurface);
}

.title {
    display: flex;
    padding-bottom: 4px;
}
</style>
