<template>
    <div class="modal-wrapper" :class="{hide: !modals.withdrawalCancel}" v-on:click.stop="hideModal">>
        <div class="modal" v-on:click.stop>
            <div class="title">
                <span>Confirm Withdrawal Cancel?</span>
                <i class="material-icons pointer" @click="hideModal">close</i>
            </div>
            <div class="row">
                <div class="col">
                    <span class="button red-button" @click="cancelWithdrawal(modalWithdrawalCancel.id)">Confirm</span>
                </div>
                <div class="col">
                    <span class="button bg-light-button" @click="hideModal">Close</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from '@/store/mutations.type'
import {CANCEL_WITHDRAWAL} from '@/store/actions.type'

export default {
    name: "WithdrawalCancelModal",
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                withdrawalCancel: false
            })
        },
        cancelWithdrawal(id) {
            this.$store.commit(MODAL, {
                withdrawalCancel: false
            })

            this.$store.dispatch(CANCEL_WITHDRAWAL, id)
        },
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'modalWithdrawalCancel']),
        activeOrder() {
            return this.activeAccount.orders[this.modalClosePosition.activeOrderId] || {
                alias: '',
                type: 0,
                volume: 0
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.red-button {
    color: var(--white);
    background-color: #ac4343;
}

.bg-light-button {
    color: var(--white);
    background-color: var(--background-light);
}

</style>