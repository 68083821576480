import { render, staticRenderFns } from "./SideMenuBurger.vue?vue&type=template&id=3ec1bd48&scoped=true"
import script from "./SideMenuBurger.vue?vue&type=script&lang=js"
export * from "./SideMenuBurger.vue?vue&type=script&lang=js"
import style0 from "./SideMenuBurger.vue?vue&type=style&index=0&id=3ec1bd48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec1bd48",
  null
  
)

export default component.exports