<template>
    <div class="personal-information">
        <div class="personal-information-list">
            <div class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.firstName }}</label>
                <BaseInput v-model="personal.firstname" :disabled="disableEditPersonalInfo" />
            </div>
            <div class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.lastName }}</label>
                <BaseInput v-model="personal.lastname" :disabled="disableEditPersonalInfo" />
            </div>
            <div class="personal-information-item" v-if="user.hide_email_platform && !hideEmail">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.email }}</label>
                <BaseInput v-model="personal.email" :disabled="disableEditPersonalInfo" />
            </div>
            <div class="personal-information-item" v-if="user.hide_email_platform && !hideEmail">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.email }} 2</label>
                <BaseInput v-model="personal.email2" :disabled="disableEditPersonalInfo" />
            </div>
            <div class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.country }}</label>
                <SearchCountrySelect :value="personal.country" @update="personal.country = $event" />
            </div>
            <div v-if="brand === 'stage'" class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.state }}</label>
                <BaseInput v-model="personal.state" :disabled="disableEditPersonalInfo" />
            </div>
            <div class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.address }}</label>
                <BaseInput v-model="personal.address" />
            </div>
            <div v-if="brand === 'stage'" class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.postcode }}</label>
                <BaseInput v-model="personal.postcode" :disabled="disableEditPersonalInfo" />
            </div>
            <div v-if="user.hide_phone_platform" class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.phone }}</label>
                <BaseInput v-model="personal.phone" disabled />
            </div>
            <div v-if="user.hide_phone_platform" class="personal-information-item">
                <label class="item-name label__largeprominent">{{ tokens.fullScreenPersonal.phone2 }}</label>
                <BaseInput v-model="personal.phone2" disabled />
            </div>

            <BaseButton class="personal-information-button" :buttonText="'Save Changes'" :buttonStyle="'filled-primary'"
                @click.native="savePersonal" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PERSONAL_UPDATE } from "@/store/actions.type"
import { BRAND, HIDE_EMAIL } from "@/common/config"
import { NOTIFICATION_ERROR } from "@/store/mutations.type"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput";
import SearchCountrySelect from "@/new-design/shared/ui/selects/SearchCountrySelect";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton";

export default {
    name: "UpdatePersonalInfo",
    components: { BaseInput, SearchCountrySelect, BaseButton },
    data() {
        return {
            personal: {
                firstname: '',
                lastname: '',
                address: '',
                country: '',
                state: '',
                postcode: '',
                phone: '',
                phone2: '',
                email: '',
                email2: ''
            },
            brand: BRAND,
            hideEmail: HIDE_EMAIL,
        }
    },
    methods: {
        savePersonal() {
            if (this.disableEditPersonalInfo) {
                this.$store.commit(NOTIFICATION_ERROR, 'This feature is disabled')
                return
            }

            let personalObj = {
                phone2: this.personal.phone2,
                email2: this.personal.email2,
                lastname: this.personal.lastname,
                firstname: this.personal.firstname,
                address: this.personal.address,
                country: this.personal.country,
            }

            if (this.brand === 'stage') {
                personalObj.state = this.personal.state;
                personalObj.postcode = this.personal.postcode;
            }

            this.$store.dispatch(PERSONAL_UPDATE, personalObj)
        },
        hideLastSymbols(value, hideSymbolsCounter) {
            let start = value.substring(0, value.length - hideSymbolsCounter), end = ''
            for (let i = 0; i < hideSymbolsCounter; i++) {
                end += "*"
            }
            return start + end
        }
    },

    computed: {
        ...mapGetters(['user', 'tokens', 'variables']),
        disableEditPersonalInfo() {
            return parseInt(this.variables.CFD_DISABLE_EDIT_USER_INFO) ? true : false
        },
    },
    watch: {
        user: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    this.personal[n] = val[n]
                    if (['swissiam', 'profitrisecg', 'grandcapitalcg', 'fulbrightsl'].indexOf(BRAND) !== -1 && n === 'phone' && val[n]) {
                        this.personal[n] = this.hideLastSymbols(val[n], 3)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

.title {
    margin-bottom: 16px;
    color: var(--System-OnSurface)
}

.personal-information-list {
    display: flex;
    flex-direction: column;
}

.personal-information-item {
    display: grid;
    grid-template-columns: 100px 1fr;
    padding: 16px 0;
    align-items: center;
    gap: 16px;
}

.personal-information-button {
    margin: 8px 0 8px auto;
}
</style>
