<template>
    <ul class="menu-list">
        <slot name="content"/>
    </ul>
</template>

<script>
export default {
    name: "BaseMenuList",
}
</script>

<style scoped>
.menu-list {
    position: absolute;
    background: var(--System-Surface3);
    padding: 8px 0;
    max-height: 375px;
    width: 200px;
    margin-top: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: var(--Corner-Extra-small);
    z-index: 10;
}

.menu-list.top {
    bottom: 53px;
    right: -24px;
}

.menu-list.full {
    width: 100%;
    margin-top: 4px;
}
</style>
