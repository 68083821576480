<template>
    <div class="specification">
        <div class="specification-item">
            <div class="specification-header">
                <span class="label__largeprominent title"> {{ tokens.assetInfoModal.schedule }}</span>
                <span class="body__extrasmall"> {{ 'trading time' }}</span>
            </div>
            <div class="specification-list label__mediumprominent">
                <div v-for="(session, n) in tradingSessions" 
                    :key="n" 
                    class="list-item">
                        <div class="item-title">{{ dayOfWeek[n] }}</div>
                        <div class="item-value">{{ session }}</div>
                </div>
            </div>
        </div>
        <div class="specification-item">
            <div class="specification-header">
                <span class="label__largeprominent title">{{ tokens.assetInfoModal.contractSpecification }}</span>
            </div>
            <ul class="specification-list label__mediumprominent">
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.symbol }}</div>
                    <div class="item-value">{{ symbols[symbolActive].symbol }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.name }}</div>
                    <div class="item-value">{{ symbols[symbolActive].alias }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.market }}</div>
                    <div class="item-value">
                        {{ symbols[symbolActive].group.charAt(0).toUpperCase() + symbols[symbolActive].group.slice(1) }}
                    </div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.baseCurrency }}</div>
                    <div class="item-value">{{ symbols[symbolActive].base_currency }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.digits }}</div>
                    <div class="item-value">{{ symbols[symbolActive].precision }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.lotSize }}</div>
                    <div class="item-value">{{ symbols[symbolActive].lot.toLocaleString(undefined) }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.lotStep }}</div>
                    <div class="item-value">{{ symbols[symbolActive].lot_step }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.minVolume }}</div>
                    <div class="item-value">{{ symbols[symbolActive].volume_min.toLocaleString(undefined) }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.maxVolume }}</div>
                    <div class="item-value">{{ symbols[symbolActive].volume_max.toLocaleString(undefined) }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.leverage }}</div>
                    <div class="item-value">
                        {{symbols[symbolActive].leverage_default ? '1:' + symbols[symbolActive].leverage_default : 'account'}}
                    </div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.commission }}</div>
                    <div class="item-value">{{ symbols[symbolActive].commission.toFixed(1) + '%' }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.swapLong }}</div>
                    <div class="item-value">{{ symbols[symbolActive].swap_long.toFixed(1) + '%' }}</div>
                </li>
                <li class="list-item">
                    <div class="item-title">{{ tokens.assetInfoModal.swapShort }}</div>
                    <div class="item-value">{{ symbols[symbolActive].swap_short.toFixed(1) + '%' }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "TradingConditions",
    props: ['symbolActive', 'chart'],
    computed: {
        ...mapGetters(['symbols', 'modalAssetsInfo', 'sessions', 'tokens']),
        tradingSessions() {
            const glueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r = r.concat(session[i])
                }

                return r
            }

            const unglueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r.push(session.slice(i * 24, (i + 1) * 24))
                }

                return r
            };

            let timezone = Math.floor(new Date().getTimezoneOffset() / 60),
                session = this.sessions[this.modalAssetsInfo.symbol]

            session = glueSession(session)

            while (timezone !== 0) {
                if (timezone > 0) {
                    session.push(session.shift())
                    timezone--
                } else {
                    session.unshift(session.pop())
                    timezone++
                }
            }

            session = unglueSession(session)

            const makeSession = (start, stop) => {
                const formatTime = (time) => {
                    let hours = Math.floor(time)
                    let minutes = Math.round((time - hours) * 60)
                    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
                }

                return formatTime(start) + ' - ' + formatTime(stop)
            }

            let sessions = []
            for (let d = 0; d < 7; d++) {
                let daySessions = []
                let s = session[d],
                    start = null,
                    stop = null,
                    started = false

                for (let i = 0; i < 24; i++) {
                    let cur = s[i]
                    let next = s[i + 1] !== undefined ? s[i + 1] : 0

                    if (cur > 0 && !started) {
                        start = i + (cur < 1 ? cur : 0);
                        started = true;
                    }

                    if (started) {
                        stop = i + (cur < 1 ? cur : 1);
                    }

                    if ((cur > 0 && next === 0) || (i === 23 && started)) {
                        daySessions.push(makeSession(start, stop));
                        started = false;
                    }
                }

                sessions.push(daySessions.length ? daySessions.join(', ') : this.tokens.assetInfoModal.closed)
            }

            return sessions
        },
        dayOfWeek() {
            return [
                this.tokens.assetInfoModal.sunday,
                this.tokens.assetInfoModal.monday,
                this.tokens.assetInfoModal.tuesday,
                this.tokens.assetInfoModal.wednesday,
                this.tokens.assetInfoModal.thursday,
                this.tokens.assetInfoModal.friday,
                this.tokens.assetInfoModal.saturday,
            ]
        }
    },
}
</script>

<style scoped>
.specification {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px;
}

.specification-item {
    border-radius: 4px;
    background: var(--Statelayers-secondarycontainer-opacity16);
    padding-inline: 12px;
    color: var(--System-Outline)
}

.specification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--System-Surface5);
    padding: 12px 0 9px;
}

.title {
    color: var(--System-OnSurface)
}

.specification-list {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-item .item-value {
    color: var(--System-OnSurfaceVariant)
}
</style>
