<template>
  <div
    class="modal-wrapper"
    :class="{
      hide:
        loggedIn ||
        modals.register ||
        modals.newPassword ||
        modals.forgotPassword,
    }"
  >
    <div class="modal" :class="{ hide: modals.otp }">
      <div class="modal-box">
        <div class="wrap-login">
          <div class="login-form">
            <span class="login-form-title"> Welcome </span>
            <span class="login-form-logo">
              <img
                src="https://p-cdn.co/tradingviewonline/brands/tradingviewonline/favicon.png"
                alt="Logo"
              />
            </span>

            <div class="wrap-input">
              <input
                class="input"
                type="text"
                name="email"
                :class="email ? 'has-val' : ''"
                v-model="email"
              />
              <span class="focus-input" data-placeholder="Email"></span>
            </div>

            <div class="wrap-input" data-validate="Enter password">
              <span class="btn-show-pass">
                <i
                  class="material-icons"
                  @click.prevent="() => (passwordVisible = !passwordVisible)"
                  >{{ passwordVisible ? "visibility" : "visibility_off" }}</i
                >
              </span>
              <input
                class="input"
                :type="passwordVisible ? 'text' : 'password'"
                name="pass"
                :class="password ? 'has-val' : ''"
                v-model="password"
              />
              <span class="focus-input" data-placeholder="Password"></span>
            </div>

            <div class="container-login-form-btn">
              <div class="wrap-login-form-btn">
                <div class="login-form-bg"></div>
                <button @click="login" class="login-form-btn">Login</button>
              </div>
            </div>

            <div class="row-singup">
              <span class="txt1"> Don’t have an account? </span>

              <a class="txt2" href="/register"> Sign Up </a>
            </div>
            <div class="row-forgot">
              <span class="txt1"> Forgot password? </span>

              <a class="txt2" @click="showForgot"> Restore </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TwoFactorAuthenticationModal
      :class="{ hide: !modals.otp }"
      :email="this.email"
      :password="this.password"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGIN, PRE_LOGIN } from "@/store/actions.type";
import { MODAL } from "@/store/mutations.type";
import TwoFactorAuthenticationModal from "@/components/Modals/TwoFactorAuthenticationModal";
export default {
  name: "TradingViewLoginModal",
  components: {
    TwoFactorAuthenticationModal,
  },
  data() {
    return {
      email: "",
      password: "",
      passwordVisible: false,
      otp: "",
    };
  },
  computed: {
    ...mapGetters(["loggedIn", "modals", "tokens"]),
  },
  methods: {
    login() {
      this.newDesign
        ? this.$store.dispatch(PRE_LOGIN, {
            email: this.email.trim(),
            password: this.password.trim(),
            otp: this.otp,
          })
        : this.$store.dispatch(LOGIN, {
            email: this.email.trim(),
            password: this.password.trim(),
            otp: this.otp,
          });
    },
    showForgot() {
      this.$store.commit(MODAL, {
        forgotPassword: true,
      });
    },
  },
  watch: {
    loggedIn(val) {
      if (val) {
        this.email = "";
        this.password = "";
        this.otp = "";
      }
    },
  },
};
</script>

<style scoped>
a {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

input {
  outline: none;
  border: none;
  caret-color: black;
}

input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

.txt1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  line-height: 1.5;
}

.txt2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;
}

.modal-wrapper,
.modal-otp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: auto;
}

.modal-box {
  width: max-content;
  margin: 0 auto;
}

.container-login {
  width: max-content;
  min-height: max-content;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 100%;
}

.login-form-title {
  display: block;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login-form-logo {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  justify-content: center;
}
.login-form-logo img {
  width: 80%;
}

.wrap-input {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 37px;
}

.input {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}

.focus-input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #6a7dfe;
  background: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  background: -o-linear-gradient(to right, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  background: linear-gradient(
    90deg,
    rgba(33, 212, 253, 1) 10%,
    rgba(183, 33, 255, 1) 100%
  );
}

.focus-input::after {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input:focus + .focus-input::after {
  top: -15px;
}

.input:focus + .focus-input::before {
  width: 100%;
}

.has-val.input + .focus-input::after {
  top: -15px;
}

.has-val.input + .focus-input::before {
  width: 100%;
}

.btn-show-pass {
  font-size: 15px;
  color: #999999;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: 5px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-show-pass:hover {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

.btn-show-pass.active {
  color: #6a7dfe;
  color: -webkit-linear-gradient(to right, #21d4fd, #b721ff);
  color: -o-linear-gradient(to right, #21d4fd, #b721ff);
  color: -moz-linear-gradient(to right, #21d4fd, #b721ff);
  color: linear-gradient(to right, #21d4fd, #b721ff);
}

.container-login-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
}

.login-form-bg {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: -webkit-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: -o-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: -moz-linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  background: linear-gradient(to left, #21d4fd, #b721ff, #21d4fd, #b721ff);
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login-form-btn {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-login-form-btn:hover .login-form-bg {
  left: 0;
}

.row-singup {
  text-align: center;
  padding-top: 55px;
}

.row-forgot {
  text-align: center;
  padding-top: 5px;
}

@media (max-width: 576px) {
  .wrap-login {
    width: 300px;
    padding: 45px 20px 33px;
  }
  .row-singup {
    padding-top: 35px;
  }
}


</style>
