<script>
import {orderTypeString} from "@/common/helpers"

export default {
    name: "BasePositionDirection",
    props: {
        type: {
            type: [String, Number],
        }
    },
    methods: {orderTypeString},
}
</script>

<template>
    <div class="position-direction" :class="type % 2 ? 'red' : 'green'">
        {{ orderTypeString(type) }}
    </div>
</template>

<style scoped>
.position-direction {
    padding: 4px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
}


.position-direction.green {
    color: var(--Custom-GreenFixed);
    background: var(--Statelayers-greenfixed-opacity12);
}

.position-direction.red {
    color: var(--Custom-RedFixed);
    background: var(--Statelayers-redfixed-opacity12);
}
</style>
