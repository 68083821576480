<template>
    <div class="modal-wrapper" :class="{hide: !modals.qrCode}" v-on:click.stop="hideModal">
        <div class="modal" @click.stop>
            <QrcodeVue v-if="value" :value="value" :size="size" />
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import QrcodeVue from 'qrcode.vue'
import {MODAL,MODAL_QR_CODE} from '@/store/mutations.type'

export default {
    name: "QrCodeModal",
    components: {QrcodeVue},
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                qrCode: false
            })
            this.$store.commit(MODAL_QR_CODE, null)

        },
    },
    computed: {
        ...mapGetters(['modals','modalQrCode']),
        value(){
            return this.modalQrCode.value
        },
        size(){
            return window.innerWidth > 1000 ? 500 : 250
        }
    },
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.9);
}

.modal {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--white);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
    line-height: 1.5;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.red-button {
    color: var(--white);
    background-color: #ac4343;
}

.bg-light-button {
    color: var(--white);
    background-color: var(--background-light);
}

</style>
