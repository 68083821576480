<template>
  <TradingViewLoginModal
    v-if="['tradingviewonline', 'stage'].includes(brand)"
  />
</template>

<script>
import TradingViewLoginModal from "@/components/CustomLoginModals/TradingViewLoginModal";
import { BRAND } from "@/common/config";

export default {
  name: "CustomLoginModals",
  components: { TradingViewLoginModal },
  data() {
    return {
      brand: BRAND,
    };
  },
};
</script>
