<template>
    <div class="modal-wrapper" v-if="isMobile" ref="landscape">
        <div class="modal">
            <div class="title">
                <span>The system is optimized to work<br>in a portrait mode on mobile.<br><br>Please hold your phone vertically.</span>
            </div>
            <i class="material-icons landscape">screen_lock_landscape</i>
        </div>
    </div>
</template>

<script>

import {isMobile} from "@/common/helpers";

export default {
    name: "NoLandscape",
    mounted() {
        window.addEventListener(
            "orientationchange",
            this.handleOrientationChange
        )
    },
    data() {
        return {
            isMobile: isMobile()
        }
    },
    methods: {
        handleOrientationChange() {
            const orientation = window?.screen?.orientation?.type
            if (orientation && this.$refs?.landscape) {
                if (orientation === "landscape-primary") {
                    this.$refs.landscape.style.display = "flex"
                } else if (orientation === "portrait-primary") {
                    this.$refs.landscape.style.display = "none"
                }
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7)
}

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.title {
    color: var(--white);
    font-weight: 400;
    font-size: 1.4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    text-align: center;
}

.landscape {
    font-size: 4.5em;
}

/*@media screen and (orientation: landscape) and (min-width: 500px){*/
/*    .modal-wrapper {*/
/*        display: flex;*/
/*    }*/
/*}*/
</style>