var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.tokens.widgetMarketWatch.marketWatch)+" "),_c('i',{staticClass:"material-icons close",on:{"click":function($event){return _vm.hideModal('market')}}},[_vm._v("close")])]),_c('div',{staticClass:"content scroll"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control search",attrs:{"type":"text","name":"my-search-market-watch","placeholder":_vm.tokens.widgetMarketWatch.search},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._v(" "),_c('i',{staticClass:"material-icons search-ico"},[_vm._v("search")]),_c('div',{staticClass:"col"},_vm._l((_vm.marketCats),function(cat,i){return _c('div',{key:i},[_c('div',{staticClass:"cat-title",class:{hide: !cat.cnt},on:{"click":function($event){_vm.collapsed[i] = _vm.search === '' ? !_vm.collapsed[i] : _vm.collapsed[i]}}},[_c('div',{staticClass:"row"},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(cat.ico))]),_c('span',[_vm._v(_vm._s(cat.name))])]),_c('div',{staticClass:"row-2"},[_c('div',{staticClass:"cnt"},[_vm._v(_vm._s(cat.cnt))]),_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.collapsed[i] && _vm.search === '' ? 'expand_more' : 'expand_less'))])])]),(!((_vm.marketFilter && _vm.symbols[_vm.symbol.symbol].market !== _vm.marketFilter) || (_vm.search === '' && _vm.collapsed[i])))?_vm._l((cat.symbols),function(symbol){return _c('div',{key:symbol.symbol,staticClass:"market-item",class:{
                     hide: (_vm.marketFilter && _vm.symbols[symbol.symbol].market !== _vm.marketFilter) || (_vm.search === '' && _vm.collapsed[i]),
                     active: _vm.charts[_vm.chartActive] && _vm.charts[_vm.chartActive].symbol === symbol.symbol
                 },on:{"click":function($event){return _vm.changeSymbol(symbol.symbol)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"row",class:_vm.sessionsState[symbol.symbol] ? '' : 'close'},[_c('div',{staticClass:"symbol",class:_vm.assetSymbol(symbol.symbol)}),_c('span',[_vm._v(_vm._s(_vm.symbols[symbol.symbol] ? _vm.symbols[symbol.symbol].alias : symbol.symbol))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"price",class:_vm.sessionsState[symbol.symbol] ? (symbol.quote ? symbol.quote.dirBid : '') : 'close'},[_vm._v(" "+_vm._s(symbol.quote && symbol.quote.bid ? symbol.quote.bid.toFixed(symbol.precision) : '-')+" ")]),_c('div',{staticClass:"smaller"},[_c('div',{staticClass:"pct",class:{
                                                red: symbol.quote && symbol.quote.changePct < 0,
                                                green: symbol.quote && 0 < symbol.quote.changePct,
                                                hide: !_vm.sessionsState[symbol.symbol]
                                             }},[_vm._v(" "+_vm._s((symbol.quote && symbol.quote.changePct > 0 ? '+' : '') + (symbol.quote && symbol.quote.changePct ? symbol.quote.changePct : 0).toFixed(2) + '%')+" ")]),_c('div',{staticClass:"pct close",class:{hide: _vm.sessionsState[symbol.symbol]}},[_vm._v(" Closed ")])]),_c('i',{staticClass:"material-icons add",on:{"click":function($event){$event.stopPropagation();return _vm.addChart(symbol.symbol)}}},[_vm._v("add")]),_c('i',{staticClass:"material-icons info",on:{"click":function($event){$event.stopPropagation();return _vm.showAssetsInfoModal(symbol.symbol)}}},[_vm._v("info_outlined")])])])])])])}):_vm._e()],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }